import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	Renderer2,
	inject
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { MarkerIconStyle } from '@yuno/api/interface';

import { DefaultComponent } from '../default/default.component';

@Component({
	selector: 'yuno-marker-icon',
	template: `
		@if (data) {
			<div
				class="icon"
				[class]="alignment || 'center'"
				(click)="onClick($event)"
				(mouseenter)="onMove($event, true)"
				(mouseleave)="onMove($event, false)">
				<img class="max-h-64 max-w-none" [src]="getIcon" attr="marker-icon" />

				<!-- embed cluster count -->
				<ng-content></ng-content>
			</div>
			<!-- draggable marker -->
			@if (draggable) {
				<yuno-marker-crosshair></yuno-marker-crosshair>
			}
		}
	`,
	styleUrls: ['./icon.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false
})
export class IconComponent extends DefaultComponent {
	private readonly sanitizer = inject(DomSanitizer);
	private el = inject(ElementRef);
	private renderer = inject(Renderer2);

	private _style: MarkerIconStyle;
	private _alignment: MarkerIconStyle['alignment'];

	@Input() markerRotation: number;

	@Input() set style(style: MarkerIconStyle) {
		this._style = style;
		this.alignment = style.alignment;
		this.renderer.setStyle(
			this.el.nativeElement,
			'--icon-rotation',
			`${style?.rotation || this.markerRotation || 0}deg`,
			2
		);
		this.renderer.setStyle(this.el.nativeElement, '--icon-scale', style?.scale || 1, 2);
	}

	get style(): MarkerIconStyle {
		return this._style;
	}

	@Input() selectEnabled = false;
	@Input() selected: string;

	@Input() set alignment(alignment: MarkerIconStyle['alignment']) {
		this._alignment = alignment;
	}

	get alignment(): MarkerIconStyle['alignment'] {
		return this._alignment;
	}

	get getIcon(): SafeResourceUrl | undefined {
		if (this.selectEnabled && this.selected === this.data?.id) {
			const selectIcon = this.style.iconSelect || this.style.icon;
			return selectIcon
				? this.sanitizer.bypassSecurityTrustResourceUrl(selectIcon)
				: undefined;
		}

		const icon = this.style.icon;
		return icon ? this.sanitizer.bypassSecurityTrustResourceUrl(icon) : undefined;
	}
}
