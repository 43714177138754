import { GLOBAL_ENV } from './types';

const { version } = require('./../package.json');

declare let require: any;

const stage: GLOBAL_ENV['yuno-stage'] = 'dev';
const mainUrl = `projectatlas.tech`;

export const globalEnvironment: GLOBAL_ENV = {
	'yuno-production': false,
	'yuno-version': version,
	'yuno-stage': stage,
	'yuno-main': mainUrl,
	'yuno-main-url': `https://${mainUrl}`,
	'yuno-api': `https://${mainUrl}/api`,
	'yuno-graphql': `https://${mainUrl}/api/graphql`,
	'yuno-cdn': `https://cdn.projectatlas.app`,
	'yuno-cdn-api': `https://cdn.projectatlas.app/api`,
	'yuno-cdn-api-storage': `https://cdn.projectatlas.app/api/storage`,
	'yuno-matomo': `https://matomo.${mainUrl}`,
	'yuno-tileserver-old': `https://tiles.projectatlas.app`,
	'yuno-tileserver': `https://tiles.${mainUrl}`,
	'yuno-tilegenerator': `https://tiles.${mainUrl}/generator`,
	'yuno-tools-url': `https://yunotools.projectatlas.app`,
	'yuno-arcgis-api-token':
		'AAPKa23e49b69b4e4fac80c0dd1d5795fb279N_0qTKAO1IRJ1q0xjDIekYQ3qBQ0A3iXNPn9tRxbYlTnLw8emd9bUIpVtgex85X'
};
