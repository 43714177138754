<ng-container *transloco="let t">
	@if (data$ | async; as data) {
		<yuno-admin-navbar-buttons-container>
			<button yuno-admin-save-button (click)="onSave()" [disabled]="!$isChanged()"></button>
			<button yuno-admin-close-button (click)="onClose()"></button>
		</yuno-admin-navbar-buttons-container>
		@if (data.legend) {
			<div
				class="!pointer-events-none fixed z-[250] flex h-full flex-col justify-end"
				[ngStyle]="{ 'bottom.px': 36, 'right.px': 36 }">
				<yuno-legend [data]="data.legend" [language]="data.language">
					{{ t('buttons.legend') }}
				</yuno-legend>
			</div>
		}
		<div class="absolute left-0 right-[300px] top-0">
			@if (service.editContent) {
				<yuno-admin-legend-item-container />
			}
			@if (service.editList) {
				<yuno-admin-legend-list-item-container />
			}
		</div>
		@if (data.legend) {
			<yuno-admin-legend-main-container />
		}
	}
	<router-outlet />
</ng-container>
