@if (data$ | async; as data) {
	<yuno-edit-container [col]="4" [pos]="3" [span]="2">
		<ng-container buttons>
			<button yuno-admin-save-button (click)="onSave()" [disabled]="!$isChanged()"></button>
			<button yuno-admin-close-button (click)="onClose()"></button>
		</ng-container>
		<div edit-container-content>
			@if (service.form) {
				<form [formGroup]="service.form">
					@if (!minimalAppRole(userRoles.EDITOR)) {
						<yuno-user-rights-message></yuno-user-rights-message>
					}
					<div class="flex flex-col gap-8">
						<div class="flex flex-col gap-2">
							<yuno-forms-text
								formControlName="state"
								label="State"
								placeholder="state-name">
								Use only lowercase letters. Do not use spaces, diacritics (such as
								'ä', 'ë'), or special characters, except for '-' and '_'.
							</yuno-forms-text>
						</div>
						<section formGroupName="display">
							@for (lang of languages; track lang) {
								@if (lang === data.language) {
									<yuno-forms-text
										[language]="lang"
										[formControlName]="lang"
										label="Display name"></yuno-forms-text>
								}
							}
						</section>
					</div>
				</form>
			}
		</div>
	</yuno-edit-container>
}
