import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	OnDestroy,
	OnInit,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslocoDirective, TranslocoModule } from '@ngneat/transloco';
import { combineLatest, filter, startWith, take, tap } from 'rxjs';

import { FormStartingService } from '@yuno/admin/core';
import { AppFacade } from '@yuno/admin/features/apps';
import { NavbarFacade } from '@yuno/admin/features/navbar';
import {
	YunoAdminButtonsModule,
	YunoAdminNavbarButtonsComponent,
	YunoEditContainerModule
} from '@yuno/admin/ui';
import { AppDataComponent, redirectTo } from '@yuno/admin/utils';
import { AngularPipesModule } from '@yuno/angular/pipes';
import { AngularSvgLoaderModule } from '@yuno/angular/svg-loader';
import { LanguageAll, Legend } from '@yuno/api/interface';
import { LegendComponent } from '@yuno/project-atlas/ui';

import { LegendFacade } from '../../data-access';
import { LegendItemEditorContainerComponent } from './containers/item-editor.component';
import { LegendListItemEditorContainerComponent } from './containers/list-item-editor.component';
import { LegendMainEditorContainerComponent } from './containers/main-editor.component';
import { LegendEditorService } from './legend-editor.service';

@Component({
	selector: 'yuno-admin-legend-editor',
	imports: [
		CommonModule,
		RouterModule,
		TranslocoModule,
		AngularPipesModule,
		ReactiveFormsModule,
		DragDropModule,
		YunoAdminButtonsModule,
		YunoEditContainerModule,
		AngularSvgLoaderModule,
		LegendComponent,
		YunoAdminNavbarButtonsComponent,
		TranslocoDirective,
		LegendMainEditorContainerComponent,
		LegendItemEditorContainerComponent,
		LegendListItemEditorContainerComponent
	],
	providers: [LegendEditorService, FormStartingService],
	templateUrl: './legend-editor.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegendEditorComponent extends AppDataComponent implements OnInit, OnDestroy {
	protected readonly languages = LanguageAll;

	private readonly appFacade = inject(AppFacade);
	private readonly navbarFacade = inject(NavbarFacade);
	private readonly legendFacade = inject(LegendFacade);
	private readonly destroyRef = inject(DestroyRef);
	readonly service = inject(LegendEditorService);
	private targetRoute: string[] | undefined;

	private readonly formService = inject(FormStartingService);
	$isChanged = this.formService.$isChanged;

	originalData: Partial<Legend>;
	originalContent: Legend['content'];

	data$ = combineLatest({
		legend: this.legendFacade.selectedLegends$.pipe(
			filter(() => !this.originalData),
			tap(data => {
				this.formService.startup(
					this.service.form,
					data,
					this.originalData,
					this.route,
					this.router,
					async () => {
						if (data) {
							this.originalData = data;
							this.originalContent = data?.content;
							this.service.addContents(data?.content);

							if (data?.id) {
								this.navbarFacade.addRoute(
									{ display: data?.id, index: 3 },
									this.route.snapshot
								);
							}

							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							this.service.form.patchValue(this.originalData as any);
						}
					}
				);
			})
		),
		appId: this.appFacade.appId$.pipe(startWith(null)),
		language: this.appFacade.language$
	});

	ngOnInit(): void {
		this.service.createFormGroup(!this.minimalAppRole(this.userRoles.EDITOR));
		this.onChanges();

		this.route.paramMap.pipe(take(1)).subscribe(data => {
			const id = data.get('id');

			const targetRouteArray = this.route?.parent?.snapshot.pathFromRoot
				.map(s => s.url)
				.reduce((a, e) => {
					return a.concat(e);
				})
				.map(s => s.path);

			if (!id) {
				this.targetRoute = targetRouteArray?.slice(0, -1);
				this.legendFacade.updateSelect(this.service.form.getRawValue() as Partial<Legend>);
				return;
			}

			this.targetRoute = targetRouteArray?.slice(0, -2);
			this.legendFacade.select(id);
		});

		this.languageSelector();
	}

	onChanges(): void {
		this.service.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
			this.legendFacade.updateSelect(this.service.form.getRawValue() as Partial<Legend>);
		});
	}

	onSave() {
		this.legendFacade.save();
	}

	/* 	navigates back to the Dataset page */
	onClose(): void {
		redirectTo(this.route, this.router, [''], this.targetRoute);
		this.legendFacade.clearSelect();
	}

	ngOnDestroy() {
		this.legendFacade.clearSelect();
		this.languageService.destroyLanguageSelector();
	}
}
