import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AppDataComponent } from '@yuno/admin/utils';
import {
	YunoFormsColorComponent,
	YunoFormsNumberComponent,
	YunoFormsSelectComponent,
	YunoFormsTitleComponent
} from '@yuno/angular/forms/components';

import { InternalViewService } from '../../internal-view/internal-view.service';

@Component({
	imports: [
		ReactiveFormsModule,
		YunoFormsTitleComponent,
		YunoFormsColorComponent,
		YunoFormsNumberComponent,
		YunoFormsSelectComponent
	],
	selector: 'yuno-admin-atlas-text-heading-settings-form',
	template: `
		<section class="flex justify-between">
			<yuno-forms-title class="col-span-full capitalize">
				{{ headingName }}
			</yuno-forms-title>
			<div
				(click)="service.textHeading.get(headingName)?.reset()"
				class="text-yuno-slate-500 hover:cursor-pointer hover:text-yuno-blue-gray-600">
				<svg viewBox="0 0 24 24" fill="none" id="restart" height="30" width="30">
					<g id="restart-alt">
						<path
							id="Vector"
							d="M11.275 20.94995c-2.05 -0.16665 -3.775 -1.00835 -5.175 -2.525 -1.4 -1.51665 -2.1 -3.31665 -2.1 -5.4 0 -1.28335 0.295835 -2.49165 0.8875 -3.625 0.59165 -1.13335 1.42085 -2.05835 2.4875 -2.775l1.075 1.075c-0.93335 0.55 -1.65835 1.30415 -2.175 2.2625 -0.51665 0.95835 -0.775 1.97915 -0.775 3.0625 0 1.66665 0.55 3.10835 1.65 4.325 1.1 1.21665 2.475 1.91665 4.125 2.1v1.5Zm1.5 0v-1.5c1.66665 -0.2 3.04165 -0.90415 4.125 -2.1125 1.08335 -1.20835 1.625 -2.64585 1.625 -4.3125 0 -1.81665 -0.62915 -3.35415 -1.8875 -4.6125 -1.25835 -1.25835 -2.79585 -1.8875 -4.6125 -1.8875h-0.5l1.5 1.5 -1.075 1.075 -3.325 -3.325 3.325 -3.325 1.075 1.075 -1.5 1.5h0.5c2.23335 0 4.125 0.77915 5.675 2.3375 1.55 1.55835 2.325 3.44585 2.325 5.6625 0 2.08335 -0.69585 3.88335 -2.0875 5.4 -1.39165 1.51665 -3.1125 2.35835 -5.1625 2.525Z"
							fill="currentColor"
							stroke-width="0.75"></path>
					</g>
				</svg>
			</div>
		</section>

		<form
			[formGroup]="service.textHeading"
			class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
			<ng-container [formGroupName]="headingName">
				<yuno-forms-color formControlName="color" label="Color" />
				<yuno-forms-number
					formControlName="size"
					label="Size (in Pixels)"
					[convert]="true"
					[convertValue]="16" />
				<yuno-forms-select
					formControlName="weight"
					label="Weight"
					[isNumber]="true"
					[display]="service.weightDisplay"
					[selectValuesNumber]="service.weightSelect" />
				<yuno-forms-select
					formControlName="font"
					label="Font"
					[display]="service.fontDisplay"
					[selectValues]="service.fontSelect" />
			</ng-container>
		</form>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextHeadingSettingsFormComponent extends AppDataComponent {
	readonly service = inject(InternalViewService);
	@Input() headingName = 'h1';
}
