import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import {
	catchError,
	exhaustMap,
	lastValueFrom,
	map,
	of,
	switchMap,
	take,
	tap,
	withLatestFrom
} from 'rxjs';

import { ENVIRONMENT } from '@yuno/admin/core/tokens';
import { appFeature } from '@yuno/admin/features/apps';
import { sourcesCustomFeature } from '@yuno/admin/features/sources/data-access';
import {
	DELETE_CUSTOMSOURCE,
	DeleteCustomSourceMutation
} from '@yuno/admin/features/sources/utils/graphql/sources-custom/deleteCustomSources';
import {
	CustomSourcesQuery,
	GET_CUSTOMSOURCES_BY_APPID,
	GET_CUSTOMSOURCE_BY_ID,
	SelectCustomSourceQuery
} from '@yuno/admin/features/sources/utils/graphql/sources-custom/getCustomSources';
import {
	DUPLICATE_CUSTOMSOURCE,
	DuplicateCustomSourceMutation,
	SAVE_CUSTOMSOURCE,
	SaveCustomSourceMutation
} from '@yuno/admin/features/sources/utils/graphql/sources-custom/saveCustomSources';
import { GraphQLService } from '@yuno/angular-graphql';
import { MessageService } from '@yuno/angular/notifications';

import { sourcesCustomActions } from './sources-custom.actions';

export const loadCustomSources = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(sourcesCustomActions.load),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<CustomSourcesQuery>({
						query: GET_CUSTOMSOURCES_BY_APPID,
						variables: {
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.customSources) {
								throw new Error('no Sources found');
							}
							return sourcesCustomActions.loadSuccess({
								data: data.data.customSources
							});
						}),
						take(1),
						catchError(error => of(sourcesCustomActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const selectCustomSource = createEffect(
	(actions$ = inject(Actions), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(sourcesCustomActions.select),
			exhaustMap(data =>
				graphql
					.query<SelectCustomSourceQuery>({
						query: GET_CUSTOMSOURCE_BY_ID,
						variables: {
							_id: data._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data.selectedCustomSource) {
								throw new Error('no Source with that id found');
							}

							return sourcesCustomActions.selectSuccess({
								data: data.data.selectedCustomSource
							});
						}),
						take(1),
						catchError(error => of(sourcesCustomActions.selectFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const updateCustomSelected = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(sourcesCustomActions.updateSelect),
			map(data => {
				if (!data.data) {
					message.sendToast(`Error updating source!`, 'error');
					throw new Error('no Source found');
				}
				return sourcesCustomActions.updateSelectSuccess({
					data: data.data
				});
			}),
			catchError(error => of(sourcesCustomActions.updateSelectFailure({ error })))
		),
	{ functional: true }
);

export const saveCustomSource = createEffect(
	(
		actions$ = inject(Actions),
		store = inject(Store),
		graphql = inject(GraphQLService),
		toast = inject(HotToastService)
	) =>
		actions$.pipe(
			ofType(sourcesCustomActions.save),
			withLatestFrom(
				store.pipe(select(sourcesCustomFeature.selectSelected)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([, selected, appId]) => {
				const customSource = { ...selected };
				delete customSource?.hasExcel;
				delete customSource?.hasGeoJSON;

				return graphql
					.mutate<SaveCustomSourceMutation>({
						mutation: SAVE_CUSTOMSOURCE,
						variables: {
							customSource,
							appId: appId
						}
					})
					.pipe(
						toast.observe({
							loading: 'Saving...',
							success: `The Custom Source is saved! <br><br> But the server is still combining the data. Please check your tileset later! `,
							error: 'Error'
						}),
						map(data => {
							if (!data.data?.saveCustomSource) {
								throw new Error('error saving Custom Source to the database');
							}
							store.dispatch(
								sourcesCustomActions.updateSelect({
									data: data.data.saveCustomSource
								})
							);
							const _id = data.data?.saveCustomSource?._id || '';
							return sourcesCustomActions.saveSuccess({ _id });
						}),
						take(1),
						catchError(error => of(sourcesCustomActions.saveFailure({ error })))
					);
			})
		),
	{ functional: true }
);

export const duplicateCustomSource = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(sourcesCustomActions.duplicate),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([customSource, appId]) =>
				graphql
					.mutate<DuplicateCustomSourceMutation>({
						mutation: DUPLICATE_CUSTOMSOURCE,
						variables: {
							_id: customSource._id,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.duplicateCustomSource) {
								throw new Error('error duplicating CustomSource to the database');
							}

							return sourcesCustomActions.duplicateSuccess({
								data: data.data.duplicateCustomSource
							});
						}),
						take(1),
						catchError(error => of(sourcesCustomActions.duplicateFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const deleteCustomSource = createEffect(
	(actions$ = inject(Actions), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(sourcesCustomActions.delete),
			switchMap(customSource =>
				graphql
					.mutate<DeleteCustomSourceMutation>({
						mutation: DELETE_CUSTOMSOURCE,
						variables: {
							_id: customSource._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.deleteCustomSource) {
								throw new Error('error deleting CustomSource from the database');
							}

							return sourcesCustomActions.deleteSuccess({
								_id: data.data.deleteCustomSource
							});
						}),
						take(1),
						catchError(error => of(sourcesCustomActions.deleteFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const saveCustomSuccess = createEffect(
	(
		store = inject(Store),
		actions$ = inject(Actions),
		message = inject(MessageService),
		http = inject(HttpClient),
		environment = inject(ENVIRONMENT)
	) =>
		actions$.pipe(
			ofType(sourcesCustomActions.saveSuccess),
			withLatestFrom(store.pipe(select(sourcesCustomFeature.selectSelected))),
			switchMap(([, selected]) =>
				lastValueFrom(
					http
						.patch<{
							success: boolean | 'unknown';
							message?: string;
						}>(
							`${environment['yuno-tileserver']}/excel-source/${selected?._id}/generate`,
							null
						)
						.pipe(
							catchError(error => {
								let errorMessage = error.error.message;
								if (error.error.statusCode === 404) {
									errorMessage = 'One of the files is not found!';
								}

								if (!errorMessage) {
									errorMessage =
										'The tileserver stopped responding! Possibly a missing file!';
								}

								message.sendToast(
									`Custom Source failed to generate! Error: ${errorMessage}`,
									'error'
								);

								return of(error);
							})
						)
				)
			)
		),
	{ functional: true, dispatch: false }
);

export const saveCustomFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(sourcesCustomActions.saveFailure),
			tap(() => {
				message.sendToast(`Error saving custom source!`, 'error');
			})
		),
	{ functional: true, dispatch: false }
);

export const duplicateCustomSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(sourcesCustomActions.duplicateSuccess),
			tap(() => {
				message.sendToast(`Custom Source successfully duplicated!`, 'success');
			})
		),
	{ functional: true, dispatch: false }
);
export const duplicateCustomFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(sourcesCustomActions.duplicateFailure),
			tap(() => {
				message.sendToast(`Error duplicating Custom Source!`, 'error');
			})
		),
	{ functional: true, dispatch: false }
);

export const deleteCustomSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(sourcesCustomActions.deleteSuccess),
			tap(() => {
				message.sendToast(`Custom Source successfully deleted!`, 'success');
			})
		),
	{ functional: true, dispatch: false }
);
export const deleteCustomFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(sourcesCustomActions.deleteFailure),
			tap(() => {
				message.sendToast(`Error deleting Custom Source!`, 'error');
			})
		),
	{ functional: true, dispatch: false }
);
