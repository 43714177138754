import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnInit,
	inject
} from '@angular/core';
import { combineLatest, startWith, tap } from 'rxjs';

import { UploadMbTilesContainerComponent } from '@yuno/admin/features/tilesets/feature/upload-mbtiles/upload-mbtiles.component';
import {
	YunoAdminButtonsModule,
	YunoAdminNavbarButtonsComponent,
	YunoAdminTableComponent,
	YunoCardModule
} from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { MessageService, ToastItem } from '@yuno/angular/notifications';
import { ProjectTilesets, TileData } from '@yuno/api/interface';

import { TilesetsCustomFacade } from '../../data-access';
import { TilesetsSocketsService } from '../../data-access/socket.service';
import { CurrentlyTilingComponent } from './currently-tiling.component';
import { TilesetsRoutingModule } from './tilesets-routing.module';
import { TilesetsService } from './tilesets.service';

@Component({
	selector: 'yuno-admin-tilesets',
	standalone: true,
	imports: [
		TilesetsRoutingModule,
		YunoCardModule,
		YunoAdminNavbarButtonsComponent,
		YunoAdminTableComponent,
		YunoAdminButtonsModule,
		CurrentlyTilingComponent,
		AsyncPipe
	],
	templateUrl: './tilesets.component.html',
	styleUrls: ['./tilesets.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TilesetsComponent extends AppDataComponent implements OnInit {
	private readonly tilesetsService = inject(TilesetsService);
	private readonly tilesetsCustomFacade = inject(TilesetsCustomFacade);
	private readonly messageService = inject(MessageService);
	private readonly cdr = inject(ChangeDetectorRef);
	private readonly sockets = inject(TilesetsSocketsService);

	private overlay = inject(Overlay);
	private overlayRef?: OverlayRef;
	uploadMbtiles = false;
	selected: TileData | undefined;

	tilesets: ProjectTilesets = {
		tiles: [],
		custom: []
	};

	data$ = combineLatest({
		saved: this.tilesetsCustomFacade.status$.pipe(tap(() => this.getTilesets())),
		customTilesets: this.tilesetsCustomFacade.customTilesets$,
		close: this.tilesetsService.close$.pipe(
			startWith(null),
			tap(() => {
				if (this.uploadMbtiles) {
					this.closeOverlay();
				}
			})
		)
	});

	ngOnInit() {
		this.tilesetsCustomFacade.get();
	}

	async getTilesets() {
		if (!this.appId || !this.clientId) {
			return;
		}

		this.tilesetsService.appId = this.appId;
		this.tilesets = await this.tilesetsService.get();
		this.sockets.joinCustomTilesets(this.tilesets.custom);

		this.cdr.detectChanges();
	}

	onCopySourceUrl(row: Partial<TileData>): void {
		row?.pmtile && this.copyToClipboard(row.pmtile);
	}

	onCopyTileJSON(row: Partial<TileData>): void {
		row?.tileJSON && this.copyToClipboard(row.tileJSON);
	}

	onCopyTileUrl(row: Partial<TileData>): void {
		row?.tileUrl && this.copyToClipboard(row.tileUrl);
	}

	onSelect(row: Partial<TileData>): void {
		row?.id &&
			this.router.navigate(['custom/edit', row.id], {
				relativeTo: this.route
			});
	}

	onDelete(row: Partial<TileData>): void {
		row?.id && this.tilesetsCustomFacade.delete(row.id);
		this.getTilesets();
	}

	openUrl(url?: string) {
		url && window.open(url, '_blank');
	}

	copyToClipboard(str: string) {
		if (!str) {
			const message = `error copying to clipboard`;
			const data: ToastItem = {
				message: message,
				duration: 3,
				dismiss: false,
				unique: false
			};
			this.messageService.showToast(data, 'warning');
		}

		if (str) {
			navigator.clipboard.writeText(str);
			const message = `${str} is copied to your clipboard`;
			const data: ToastItem = {
				message: message,
				duration: 3,
				dismiss: false,
				unique: false
			};
			this.messageService.showToast(data, 'success');
		}
	}

	onCreate() {
		this.tilesetsCustomFacade.clearSelect();
		this.selected = undefined;
		this.router.navigate(['./custom/create'], {
			relativeTo: this.route
		});
	}

	openUploadMbtiles(): void {
		this.overlayRef = this.overlay.create({
			positionStrategy: this.overlay.position().global(),
			scrollStrategy: this.overlay.scrollStrategies.reposition(),
			hasBackdrop: false,
			// tinymce uses high z-indexes
			panelClass: 'z-10000'
		});

		const uploadEditor = new ComponentPortal(UploadMbTilesContainerComponent);
		const t = this.overlayRef?.attach(uploadEditor);
		t?.changeDetectorRef?.detectChanges();
		this.uploadMbtiles = true;
	}

	closeOverlay(): void {
		this.overlayRef?.dispose();
		this.overlayRef = undefined;
		this.uploadMbtiles = false;
	}
}
