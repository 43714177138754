import { Injectable, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { EventData } from '@maplibre/ngx-maplibre-gl';
import bbox from '@turf/bbox';
import { lineString } from '@turf/helpers';
import { Feature, FeatureCollection, Polygon, Position } from 'geojson';
import { DrawMapService } from 'libs/angular/draw-map/src/lib/services/draw-map.service';
import {
	FitBoundsOptions,
	LngLat,
	LngLatBoundsLike,
	LngLatLike,
	Map,
	MapLibreEvent,
	PaddingOptions
} from 'maplibre-gl';
import {
	AsyncSubject,
	BehaviorSubject,
	Subject,
	combineLatest,
	debounceTime,
	lastValueFrom,
	map,
	startWith,
	tap,
	withLatestFrom
} from 'rxjs';

import { AppFacade } from '@yuno/admin/features/apps';
import { MarkerCategoriesFacade } from '@yuno/admin/features/place-markers/data-access';
import {
	Fence,
	MapMarker,
	MarkerCategory,
	MarkerCategoryStyles,
	Marker as YunoMarker
} from '@yuno/api/interface';
import { waitFor } from '@yuno/libs/shared/helpers';
import { createCategoryMarkers } from '@yuno/shared/place-markers-style';

export type PlaceMarkerFilter = 'all' | 'public' | 'non-public';

const DefaultMapStyle = '5e42a2df5e53be02b5f37b00';

export interface SideMenuOptionsForm {
	zoom: FormControl<boolean>;
	fences: FormControl<boolean>;
}

@Injectable({
	providedIn: 'root'
})
export class MapViewerService {
	private readonly categoryFacade = inject(MarkerCategoriesFacade);
	private readonly router = inject(Router);

	private _mapCreated = new AsyncSubject<void>();
	mapCreated$ = this._mapCreated.asObservable();
	private _map = new Subject<Map>();
	map$ = this._map.asObservable();
	map: Map;

	basedOnRoute = false;

	zoom: number;
	center: [number, number];
	pitch: number;
	bearing: number;

	zoomLevel: number;

	private bounds?: LngLatBoundsLike;
	private _bounds = new BehaviorSubject<LngLatBoundsLike | undefined>(undefined);
	bounds$ = this._bounds.asObservable();

	get hasBounds(): boolean {
		return !!this.bounds;
	}

	private _boundsOptions = new BehaviorSubject<FitBoundsOptions>({
		padding: 60,
		animate: false
	});
	boundsOptions$ = this._boundsOptions.asObservable();

	styles: MarkerCategoryStyles[];

	private _draggable = new BehaviorSubject<boolean>(false);
	draggable$ = this._draggable.asObservable();

	private _filter = new BehaviorSubject<PlaceMarkerFilter>('all');
	filter$ = this._filter.asObservable();
	$filter = toSignal(this.filter$);

	sideOptionsForm = new FormGroup<SideMenuOptionsForm>({
		zoom: new FormControl(false, { nonNullable: true }),
		fences: new FormControl(false, { nonNullable: true })
	});

	private _fences = new BehaviorSubject<Fence[] | null>(null);
	fences$ = this._fences.asObservable();

	_dragEnd = new Subject<LngLat>();
	dragEnd$ = this._dragEnd.asObservable();

	private _polygon = new BehaviorSubject<Position[] | null>(null);
	polygon$ = this._polygon.asObservable();
	private _newPolygon = new BehaviorSubject<Position[] | null>(null);
	newPolygon$ = this._newPolygon.asObservable();

	language$ = this.appFacade.language$.pipe(
		startWith('nl'),
		map(val => val || 'nl')
	);

	data$ = combineLatest({
		marker: this.categoryFacade.selectedMarker$.pipe(
			startWith(null),
			withLatestFrom(this.categoryFacade.selectedMarkerCategory$),
			debounceTime(500),
			map(([data, category]) => {
				if (data && category) {
					const marker = createCategoryMarkers(category, [data as YunoMarker]);
					if (marker.features[0]) {
						return {
							geometry: marker.features[0].geometry,
							data: marker.features[0].properties as MapMarker
						};
					}
				}

				return null;
			})
		),
		markers: combineLatest({
			data: this.categoryFacade.placeMarkers$.pipe(startWith(null)),
			filter: this.filter$
		}).pipe(
			withLatestFrom(this.categoryFacade.selectedMarkerCategory$),
			map(([{ data, filter }, category]) => {
				if (data?.markers && data.markers.length >= 1 && category) {
					let markerData = data.markers as YunoMarker[];

					if (filter === 'non-public') {
						markerData = markerData.filter(marker => !marker.public);
					}

					if (filter === 'public') {
						markerData = markerData.filter(marker => marker.public);
					}

					if (data.fences && data.fences.length >= 1) {
						this._fences.next(data.fences as Fence[]);
					}

					const markers = createCategoryMarkers(category, markerData);
					return markers.features.map(marker => {
						return {
							geometry: marker.geometry,
							data: marker.properties as MapMarker
						};
					});
				}

				return [];
			})
		),
		fences: this.fences$.pipe(
			map(data => {
				const source: FeatureCollection = {
					type: 'FeatureCollection',
					features: []
				};
				const fences = data || [];
				for (const fence of fences) {
					source.features.push(this.createPolygonFeature(fence));
				}

				return {
					source: source,
					data: fences
				};
			})
		),
		category: this.categoryFacade.selectedMarkerCategory$.pipe(
			tap(data => {
				if (!data) {
					return;
				}

				if (data._id && data.styles) {
					this.styles = data?.styles;
					this.categoryFacade.getMarkers(data._id);
				}

				if (data.fenceData?.polygon) {
					const line = lineString(data.fenceData.polygon);
					const bounds = bbox(line);

					this.bounds = [
						[bounds[0], bounds[1]],
						[bounds[2], bounds[3]]
					];
					!this.basedOnRoute && this._bounds.next(this.bounds);
				}

				this.getMapStyle(data);
			})
		),
		drawCreate: this.drawService.drawCreate$.pipe(
			startWith(null),
			tap(data => {
				if (data?.features && data.features.length === 1) {
					const polygon = data.features[0].geometry as Polygon;
					this._newPolygon.next(polygon.coordinates[0]);
				}
			})
		),
		drawUpdate: this.drawService.drawUpdate$.pipe(
			startWith(null),
			tap(data => {
				if (data?.features && data.features.length === 1) {
					const polygon = data.features[0].geometry as Polygon;
					this._polygon.next(polygon.coordinates[0]);
					return;
				}

				this._polygon.next(null);
			})
		),
		drawDelete: this.drawService.drawDelete$.pipe(
			startWith(null),
			tap(data => {
				if (data?.features && data.features.length === 1) {
					this._polygon.next([]);
				}
			})
		)
	});

	constructor(
		private drawService: DrawMapService,
		private appFacade: AppFacade
	) {}

	getZoomSettings() {
		return this.sideOptionsForm.get('zoom');
	}

	getFenceSettings() {
		return this.sideOptionsForm.get('fences');
	}

	createPolygonFeature(fence: Fence): Feature {
		return {
			type: 'Feature',
			properties: {
				id: fence.id,
				color: fence.style?.color,
				opacity: fence.style?.opacity
			},
			geometry: {
				type: 'Polygon',
				coordinates: [fence.polygon]
			}
		};
	}

	animateBounds(bool = true): void {
		this._boundsOptions.next({ padding: 60, animate: bool });
	}

	getMapStyle(data: Partial<MarkerCategory>): void {
		if (data.theme) {
			this.categoryFacade.loadMapStyle(
				`theme/${data?.appId}/${data?.theme}/style.json?intern=true`
			);
			return;
		}

		if (data.mapStyle) {
			let styleUrl = `mapstyle/${data.mapStyle}/app/${data.appId}/style.json?intern=true`;
			if (data.layers && data.layers.length) {
				const layers = data.layers.map(layer => layer._id);
				styleUrl += `&layers=${layers.join(',')}`;
			}

			this.categoryFacade.loadMapStyle(styleUrl);
			return;
		}

		let styleUrl = `mapstyle/${DefaultMapStyle}/app/${data.appId}/style.json?intern=true`;
		if (data.layers && data.layers.length) {
			const layers = data.layers.map(layer => layer._id);
			styleUrl += `&layers=${layers.join(',')}`;
		}

		this.categoryFacade.loadMapStyle(styleUrl);
	}

	/* Update the router with coordinates */
	setMapUrl(event: MapLibreEvent<MouseEvent | TouchEvent | WheelEvent | undefined> & EventData) {
		const center = event.target.getCenter();
		const zoom = event.target.getZoom();
		const pitch = event.target.getPitch();
		const bearing = event.target.getBearing();

		this.router.navigate([], {
			queryParams: {
				map: `${center.lat},${center.lng},${zoom},${bearing},${pitch}`
			},
			queryParamsHandling: 'merge'
		});
	}

	setMarkerFilter(filter: PlaceMarkerFilter): void {
		this._filter.next(filter);
	}

	async updateBounds(): Promise<void> {
		this._bounds.next(undefined);

		await waitFor(0);
		this._bounds.next(this.bounds);
	}

	setBounds(bounds: LngLatBoundsLike): void {
		this.bounds = bounds;
		this._bounds.next(bounds);
	}

	setMap(map: Map): void {
		this._mapCreated.next(undefined);
		this._mapCreated.complete();

		this._map.next(map);
		this.map = map;
	}

	setDraggable(bool: boolean): void {
		this._draggable.next(bool);
	}

	setDragend(lngLat: LngLat): void {
		this._dragEnd.next(lngLat);
	}

	async zoomTo(center?: LngLatLike): Promise<void> {
		if (!center) {
			return;
		}
		await lastValueFrom(this.mapCreated$);
		this.map.flyTo({ center, zoom: this.map.getZoom() });
	}

	async getCenter(): Promise<[number, number]> {
		await lastValueFrom(this.mapCreated$);
		const center = this.map?.getCenter();
		return [center.lng, center.lat];
	}

	async setPadding(padding: PaddingOptions): Promise<void> {
		await lastValueFrom(this.mapCreated$);
		this.map.setPadding(padding);
	}

	async setDrawFeatures(drawMode: string) {
		await lastValueFrom(this.mapCreated$);
		this.drawService.addDrawFeatures(this.map);
		setTimeout(() => {
			this.drawService.draw?.changeMode(drawMode);
		}, 250);
	}

	async addPolygon(id: string, polygon: Position[]) {
		await lastValueFrom(this.mapCreated$);
		this.drawService.addPolygon(id, polygon);
	}

	removeDrawFeatures() {
		this.drawService.removeDrawFeatures();
	}

	deleteDrawFunctions() {
		this.drawService.deleteDrawFunctions();
	}

	async zoomToFence(bounds?: LngLatBoundsLike): Promise<void> {
		if (!bounds) {
			return;
		}
		await lastValueFrom(this.mapCreated$);
		this.map.fitBounds(bounds, { padding: 40 });
	}
}
