@if (data$ | async; as data) {
	<yuno-edit-container [col]="12" [pos]="3" [span]="8">
		<ng-container buttons>
			<button yuno-admin-save-button (click)="onSave()"></button>
			<button yuno-admin-close-button (click)="onClose()"></button>
		</ng-container>
		<div edit-container-content>
			<div class="mb-8">
				@if (service.form) {
					<form [formGroup]="service.form">
						@if (!minimalAppRole(userRoles.EDITOR)) {
							<yuno-user-rights-message></yuno-user-rights-message>
						}
						<div class="flex flex-col gap-8">
							<div class="flex flex-col gap-2">
								@if (service.id) {
									<div class="flex gap-4">
										<yuno-forms-text
											class="flex-1"
											formControlName="id"
											label="Custom-Tileset name"
											placeholder="custom-tileset name">
											Use only lowercase letters. Do not use spaces,
											diacritics (such as 'ä', 'ë', 'ß'), or special
											characters, except for '-' and '_'.
										</yuno-forms-text>
										@if (service.id.disabled) {
											<button
												class="mt-6"
												yuno-admin-button
												[ngStyle]="{ opacity: 0.93 }"
												color="secondary-dark"
												(click)="service.id.enable()">
												Rename
											</button>
										}
									</div>
								}
							</div>
							<div formArrayName="geojson">
								<h4 class="my-4">GeoJSONS</h4>
								@for (
									geojson of service.geojsons.value;
									track geojson;
									let i = $index
								) {
									<div class="mb-2 flex">
										<yuno-forms-text
											[formControlName]="i"
											class="flex-1"
											placeholder="custom-tileset name"></yuno-forms-text>
										@if (service.form.get('_id')?.value; as id) {
											<div class="m-2">
												<button
													yuno-admin-delete-admin-button
													(click)="
														onRemoveJson(
															geojson,
															i,
															clientId,
															appId,
															id
														)
													"></button>
											</div>
										}
									</div>
								}
								@if (service.files.length) {
									<h4 class="my-4">GeoJSONS to upload</h4>
									@for (file of service.files; track file; let i = $index) {
										<div>
											<div class="mb-4 flex">
												<label class="yuno-form flex-1">
													<input
														[name]="'file-' + i"
														[type]="'text'"
														[value]="file.name"
														disabled />
												</label>
											</div>
										</div>
									}
								}
								@if ({ complete: progressComplete$ | async }; as completed) {
									@if (data.progress !== null) {
										<div class="mb-2 flex h-8 gap-6">
											<h4 class="mt-1">Progress:</h4>
											<mat-progress-bar
												class="my-auto"
												mode="determinate"
												[value]="data.progress"
												[color]="
													data.progress === 100
														? 'primary'
														: data.progress === 0
															? 'warn'
															: 'primary'
												"></mat-progress-bar>
											<div class="my-auto w-32 pr-8 text-right">
												<h4>{{ data.progress }}%</h4>
											</div>
										</div>
									}
								}
								<input
									id="inputfile"
									class="hidden"
									type="file"
									accept=".geojson"
									(change)="service.onFileChange($event)"
									[multiple]="true" />
								<div class="flex gap-2">
									<button
										yuno-admin-button
										for="inputfile"
										(click)="onFile()"
										[ngStyle]="{ opacity: 0.93 }"
										color="primary"
										[add]="true">
										{{ service.files.length ? 'Replace' : 'Add / Overwrite' }}
									</button>
									@if (service.form.get('_id')?.value; as id) {
										@if (service.files.length) {
											<button
												yuno-admin-button
												(click)="
													service.openUploadDialog(clientId, appId, id)
												"
												[ngStyle]="{ opacity: 0.93 }"
												color="success">
												Upload
											</button>
										}
										<div class="flex-1"></div>
										<button
											yuno-admin-button
											[ngStyle]="{ opacity: 0.93 }"
											color="secondary"
											(click)="service.downloadZip(appId, clientId, id)">
											Download
										</button>
									}
								</div>
							</div>
							<div formGroupName="tippecanoe" class="grid-col grid grid-cols-1">
								<yuno-forms-select
									label="Preset"
									formControlName="preset"
									[selectValues]="service.presetValues"
									[display]="service.presetDisplay"
									(changes)="updateEditor($event)"></yuno-forms-select>
							</div>
							<div class="grid grid-cols-1 gap-2">
								<yuno-code-editor
									language="plaintext"
									[height]="40"
									[format]="false"
									[readOnly]="service.preset.value !== 'custom'"
									[control]="service.command"></yuno-code-editor>
								@if (service.preset.value === 'custom') {
									<yuno-admin-annotation type="info" class="text-sm">
										<span
											>The filename should be written as
											<span>'&#123;&#123;</span>FILENAME<span
												>&#125;&#125;'</span
											>
											and the GeoJSONS should not be added to the string.
											<br />
											This will be done on the backend!</span
										>
									</yuno-admin-annotation>
									<a
										class="justify-self-end text-sm"
										href="https://github.com/felt/tippecanoe#readme">
										Tippecanoe options
									</a>
								}
							</div>
						</div>
					</form>
				}
				@if (service.form.get('_id')?.value; as id) {
					<div class="my-4 flex justify-center gap-4">
						<button
							yuno-admin-add-button
							(click)="service.generateTileset(id, service.form.get('id')?.value)">
							Update Maptiles
						</button>
						<a
							[href]="
								environment['yuno-tileserver'] +
								'/custom-tileset/' +
								appId +
								'/' +
								id +
								'/log'
							"
							target="_blank"
							rel="noopener">
							<button yuno-admin-button color="secondary">View Log</button>
						</a>
					</div>
				}
			</div>
		</div>
	</yuno-edit-container>
}
