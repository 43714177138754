import { createFeature, createReducer, on } from '@ngrx/store';

import { Status } from '@yuno/admin/core/state/helpers';
import { appActions } from '@yuno/admin/features/apps';
import { CustomSource, Layer, Source } from '@yuno/api/interface';

import { layersActions } from './layers.actions';

export interface LayersState {
	loaded: boolean;
	layers: Partial<Layer>[];
	selectedLoaded: boolean;
	selected: Partial<Layer> | undefined;
	selectedSource: Partial<Source | CustomSource> | undefined;
	status: Status;
}

export const initialState: LayersState = {
	loaded: false,
	layers: [],
	selectedLoaded: false,
	selected: undefined,
	selectedSource: undefined,
	status: Status.PENDING
};

const reducer = createReducer(
	initialState,
	on(appActions.reset, () => initialState),
	on(layersActions.load, () => ({
		...initialState,
		status: Status.VALIDATING
	})),
	on(layersActions.loadSuccess, (state, { data }) => ({
		...state,
		loaded: true,
		layers: data,
		status: Status.SUCCESS
	})),
	on(layersActions.loadFailure, () => ({
		...initialState,
		status: Status.FAILED
	})),

	on(layersActions.clearSelect, state => ({
		...state,
		selected: undefined,
		selectedSource: undefined,
		selectedLoaded: false
	})),
	on(layersActions.selectSuccess, (state, { data }) => ({
		...state,
		selected: data,
		selectedLoaded: true
	})),
	on(layersActions.selectFailure, (state, { error }) => ({ ...state, error })),

	on(layersActions.selectSourceSuccess, (state, { data }) => ({
		...state,
		selectedSource: data
	})),
	on(layersActions.clearSource, state => ({
		...state,
		selectedSource: undefined
	})),

	on(layersActions.updateSelectSuccess, (state, { data }) => ({
		...state,
		selected: { ...state.selected, ...data },
		selectedLoaded: true
	})),
	on(layersActions.updateSelectFailure, (state, { error }) => ({ ...state, error })),

	// Save Update
	on(layersActions.saveSuccess, state => {
		const index = state.layers.findIndex(
			x => x['_id'] === (state.selected && state.selected['_id'])
		);
		const layers = [...state.layers];

		if (index >= 0) {
			layers[index] = { ...layers[index], ...state.selected };
		} else if (state.selected) {
			layers.push({ ...state.selected });
		}

		return {
			...state,
			layers: layers
		};
	})
);

export const layersFeature = createFeature({
	name: 'layers',
	reducer
});
