import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	OnDestroy,
	OnInit,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, combineLatest, debounceTime, take, tap } from 'rxjs';

import { FormStartingService } from '@yuno/admin/core';
import { ObjectsFacade } from '@yuno/admin/features/objects/data-access';
import { AppDataComponent, redirectTo } from '@yuno/admin/utils';
import {
	DataObjects,
	PointcloudRef,
	Shapefile,
	ShapefileRef,
	ThreejsRef
} from '@yuno/api/interface';

import { ObjectEditorService } from './object-editor.service';

@Component({
	selector: 'yuno-admin-object-editor',
	templateUrl: './object-editor.component.html',
	styleUrls: ['./object-editor.component.scss'],
	providers: [FormStartingService],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: false
})
export class ObjectEditorComponent extends AppDataComponent implements OnInit, OnDestroy {
	private readonly objectFacade = inject(ObjectsFacade);
	private readonly destroyRef = inject(DestroyRef);
	readonly service = inject(ObjectEditorService);

	private readonly formService = inject(FormStartingService);
	$isChanged = this.formService.$isChanged;

	pointcloudRef = PointcloudRef;
	shapefileRef = ShapefileRef;
	threejsRef = ThreejsRef;

	originalData: Partial<DataObjects>;
	activeData: Partial<DataObjects>;
	readonly = !this.minimalAppRole(this.userRoles.EDITOR);

	layoutControl: UntypedFormControl;
	paintControl: UntypedFormControl;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private jsonData = new BehaviorSubject<{ json: any; key?: 'layout' | 'paint' }>({
		json: {},
		key: undefined
	});

	data$ = combineLatest({
		object: this.objectFacade.selectedObjects$.pipe(
			tap(async data => {
				this.formService.startup(
					this.service.form,
					data,
					this.originalData,
					this.route,
					this.router,
					async () => {
						if (data && !this.originalData) {
							this.originalData = data as DataObjects;

							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							// this.service.form.patchValue(this.originalData as any);

							if (data?.type === ShapefileRef) {
								const shapefile = this.originalData?.data as Shapefile;
								this.layoutControl = new UntypedFormControl(
									JSON.stringify(shapefile?.styling?.layout || {})
								);
								this.paintControl = new UntypedFormControl(
									JSON.stringify(shapefile?.styling?.paint || {})
								);

								if (shapefile.styling?.layout) {
									this.service.updateFormControls(
										'layout',
										shapefile.styling?.layout
									);
								}

								if (shapefile.styling?.paint) {
									this.service.updateFormControls(
										'paint',
										shapefile.styling?.paint
									);
								}
							}

							if (data?.data?._id && this.router.url.includes('create')) {
								redirectTo(this.route, this.router, ['edit', `${data?.data?._id}`]);
							}
						}
					}
				);
			})
		),
		jsons: this.jsonData.asObservable().pipe(
			debounceTime(500),
			tap(data => {
				if (data.key) {
					this.service.updateFormControls(data.key, data.json);
				}
			})
		)
	});

	ngOnInit(): void {
		this.service.createFormGroup();
		this.onChanges();

		/* 	Detects current url and select dataset from url	*/
		this.route.paramMap.pipe(take(1)).subscribe(data => {
			const id = data.get('id');
			this.objectFacade.select(id);
		});
	}

	onChanges(): void {
		this.service.form.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500))
			.subscribe(() => {
				this.objectFacade.updateSelect(this.service.form.getRawValue() as DataObjects);
			});
	}

	onSave() {
		this.objectFacade.save();
	}

	/* 	navigates back to the Dataset page */
	onClose(): void {
		redirectTo(this.route, this.router);
		this.objectFacade.clearSelect();
	}

	updateControlForm(event: string, key: 'layout' | 'paint'): void {
		const json = JSON.parse(event);
		this.jsonData.next({ json, key });
	}

	ngOnDestroy() {
		this.objectFacade.clearSelect();
	}
}
