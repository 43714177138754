import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { HotToastService, ToastOptions } from '@ngneat/hot-toast';
import { ReplaySubject } from 'rxjs';

import { MessageDialogComponent } from '../../feature/message-dialog/message-dialog.component';
import { MessageSnackbarComponent } from '../../feature/message-snackbar/message-snackbar.component';
import { DialogItem, SnackBarQueueItem, ToastItem, ToastType } from '../../utils';

@Injectable({
	providedIn: 'root'
})
export class MessageService {
	private dialogRef: MatDialogRef<MessageDialogComponent>;
	private snackBarRef: MatSnackBarRef<MessageSnackbarComponent>;
	private msgQueue: SnackBarQueueItem[] = [];
	private isInstanceVisible = false;

	private _dialog = new ReplaySubject<boolean>(1);
	dialog$ = this._dialog.asObservable();

	private _message = new ReplaySubject<boolean>(1);
	message$ = this._message.asObservable();

	private config: MatSnackBarConfig = {
		horizontalPosition: 'right',
		verticalPosition: 'bottom',
		duration: 3 * 1000
	};

	private toastConfig: ToastOptions<string> = { position: 'bottom-right' };

	constructor(
		private dialog: MatDialog,
		private snackBar: MatSnackBar,
		private toastService: HotToastService
	) {}

	/**
	 * Returns the set MatSnackBarConfig or Undefined when using Default Settings
	 */
	getConfig(): MatSnackBarConfig | undefined {
		return this.config;
	}

	/**
	 * Sets the MatSnackBarConfig when not wanting to use the Default Settings
	 * @param config
	 */
	setConfig(config: MatSnackBarConfig): void {
		this.config = config;
	}

	/**
	 * Returns the set ToastOptions or Undefined when using Default Settings
	 */
	getToastConfig(): ToastOptions<string> | undefined {
		return this.toastConfig;
	}

	/**
	 * Sets the ToastOptions, when not wanting to use the Default Settings
	 * @param config
	 */
	setToastConfig(config: ToastOptions<string>): void {
		this.toastConfig = config;
	}

	openDialog(data: DialogItem): void {
		this.dialogRef = this.dialog.open(MessageDialogComponent, {
			data: data
		});
		this.dialogRef.afterClosed().subscribe((confirmed: boolean) => {
			if (confirmed) {
				// this.openSnackBar({ message: data.confirm || 'Success!', icon: 'success' });
				this._dialog.next(true);
			} else {
				this._dialog.next(false);
			}
		});
	}

	openSnackBar(data: SnackBarQueueItem): void {
		// const durationInSec = dur || 3;
		const sbMessage = new SnackBarQueueItem();
		sbMessage.message = data.message;
		sbMessage.icon = data.icon;
		sbMessage.duration = data.duration || 3;

		this.msgQueue.push(sbMessage);

		if (!this.isInstanceVisible) {
			this.showSnackbar();
		}
	}

	showSnackbar(): void {
		if (this.msgQueue.length === 0) {
			return;
		}

		const message: SnackBarQueueItem = this.msgQueue.shift() as SnackBarQueueItem;
		this.isInstanceVisible = true;
		this.snackBarRef = this.snackBar.openFromComponent(MessageSnackbarComponent, {
			...this.config,
			data: {
				message: message.message,
				icon: message.icon,
				duration: message.duration
			},
			duration: (message.duration || 3) * 1000
		});

		this.snackBarRef.afterDismissed().subscribe(() => {
			this.isInstanceVisible = false;
			this.showSnackbar();
		});
	}

	/**
	 * simpler api to the ShowToass method
	 * only lets you send a message and type
	 * when you want to change position or other options
	 * use the showToast method
	 *
	 * @param message
	 * @param type "error" | "success" | "warning" | "info" | "loading"
	 * @param duration in seconds
	 */
	sendToast(message: string, type: ToastType, duration?: number): void {
		const item: ToastItem = {
			message: message,
			duration: duration
		};
		this.showToast(item, type);
	}

	/**
	 * More configurable api to the ShowToass method
	 * lets you adjust all options inside the ToastItem
	 * message, duration, position, unique, dismiss, icon
	 *
	 * Applies the Default Settings, custom ToastOptions set OR
	 * the options set inside the data
	 *
	 * @param data
	 * @param type
	 */
	showToast(data: ToastItem, type: ToastType): void {
		let attr: ToastOptions<string> = this.toastConfig;

		if (this.toastConfig) {
			attr = { ...attr, ...this.toastConfig };
		}

		data.position && (attr['position'] = data.position);
		data.unique && (attr = { ...attr, id: 'pause' });
		data.dismiss && (attr = { ...attr, dismissible: true });
		data.duration && (attr = { ...attr, duration: data.duration * 1000 });
		data.icon && (attr = { ...attr, icon: data.icon });

		type === 'success' && this.toastService.success(data.message, attr);
		type === 'warning' && this.toastService.warning(data.message, attr);
		type === 'error' && this.toastService.error(data.message, attr);
		type === 'info' && this.toastService.info(data.message, attr);
		type === 'loading' && this.toastService.loading(data.message, attr);
	}
}
