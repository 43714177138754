import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import { FormArray, FormControl, FormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { ReplaySubject, combineLatest, map, tap } from 'rxjs';

import { FormStartingService } from '@yuno/admin/core';
import { AppFacade } from '@yuno/admin/features/apps';
import { DatasetsFacade } from '@yuno/admin/features/datasets';
import { TextfieldFacade } from '@yuno/admin/features/textfield-pages';
import { ThemeFacade } from '@yuno/admin/features/themes';
import { AppDataComponent } from '@yuno/admin/utils';
import { ControlsOf, LanguageFormType, newLanguageFormGroup } from '@yuno/angular/forms';
import {
	AppUser,
	DateOptionsArray,
	LanguagesArray,
	LanguagesDisplayArray,
	MarkerClassKeys,
	MarkerEventDisplayKeys,
	MarkerEventKeys,
	MarkerIconStyle,
	MarkerLabelStyle,
	MarkerPhotoStyle,
	MarkerType,
	OperatorArray,
	ParticipationCMS,
	ParticipationLayout,
	ParticipationModel,
	ParticipationPages,
	ParticipationStyles,
	SafeUser,
	Textfield,
	VisibilityEnum
} from '@yuno/api/interface';

import { ParticipationModelFacade, ParticipationNotificationFacade } from '../../../data-access';

export interface ParticipationSettingsSelectItems {
	type: string;
	label: string;
	formType: string;
}

export type ParticipationStylesForm = ControlsOf<Omit<Partial<ParticipationStyles>, 'style'>> & {
	style?: FormGroup<ControlsOf<MarkerIconStyle | MarkerLabelStyle>>;
};

export type ParticipationLayoutForm = ControlsOf<Omit<Partial<ParticipationLayout>, 'options'>> & {
	// We need Untyped FormArray because the use of multiple types:
	// https://angular.io/guide/typed-forms
	//DateLayoutOption | StringLayoutOption | NumberLayoutOption
	options?: UntypedFormArray;
};

export interface ParticipationModelForm {
	id: FormControl<string>;
	_id?: FormControl<string | undefined>;
	theme?: FormControl<string | undefined>;
	notification?: FormControl<string | undefined>;
	notificationLanguage?: FormControl<string | undefined>;
	doubleOptIn?: FormControl<boolean>;
	style?: FormGroup<{
		sprite: FormControl<string | undefined>;
		spriteSelect: FormControl<string | undefined>;
	}>;
	styles?: FormArray<FormGroup<ParticipationStylesForm>>;
	layout: FormGroup<ParticipationLayoutForm>;
	lastpage?: FormGroup<{
		header: FormGroup<LanguageFormType>;
		text: FormGroup<LanguageFormType>;
	}>;
	active: FormControl<boolean>;
	liveUpdate: FormControl<boolean>;
	cms: FormGroup<ParticipationModelCMS>;
	reaction: FormGroup<{
		answerTitle: FormGroup<LanguageFormType>;
		reactionTitle: FormGroup<LanguageFormType>;
	}>;
	pages: FormArray<FormGroup<ParticipationModelPages>>;
	crm: FormGroup<{
		dialog: FormGroup<{
			active: FormControl<boolean>;
			project: FormControl<string>;
			client: FormControl<string>;
			user: FormControl<string>;
		}>;
	}>;
	users?: FormArray<FormGroup<ParticipationModelUser>>;
	textfield?: FormControl<string | undefined>;
}

export interface ParticipationModelCMS {
	listKeys: FormArray<FormControl<string>>;
	reaction: FormGroup<{
		left: FormArray<FormControl<string>>;
		right: FormArray<FormControl<string>>;
	}>;
}

export interface ParticipationModelUser {
	displayName: FormControl<string>;
	email: FormControl<string>;
}

export interface ParticipationModelPages {
	type: FormControl<'text' | 'textfield' | 'location' | 'form' | 'formsend'>;
	data: FormGroup<{
		header: FormGroup<LanguageFormType>;
		text: FormGroup<LanguageFormType>;
		content: FormGroup<LanguageFormType>;
		textMobile: FormGroup<LanguageFormType>;
		required: FormControl<boolean>;
		validationMessage: FormGroup<LanguageFormType>;
	}>;
	privacyStatement: FormGroup<{
		active: FormControl<boolean>;
		content: FormControl<string>;
		color: FormControl<string>;
	}>;
	textfield: FormGroup<{
		content: FormGroup<LanguageFormType>;
	}>;
	form: FormArray<FormGroup<ParticipationForm>>;
}

export interface ParticipationForm {
	controlType: FormControl<string>;
	type: FormControl<string>;
	key: FormControl<string>;
	label: FormGroup<LanguageFormType>;
	selectValues: FormArray<FormGroup<LanguageFormType>>;
	placeholder: FormGroup<LanguageFormType>;
	required: FormControl<boolean>;
	validationMessage: FormGroup<LanguageFormType>;
}

const tabList = [
	'Settings',
	'Reaction',
	'Style',
	'Steps',
	'Viewer',
	'Notifications',
	'Users',
	'CRM'
] as const;
export type TabOptions = (typeof tabList)[number];
const tabs: TabOptions[] = [...tabList];

@Injectable({
	providedIn: 'root'
})
export class ParticipationSettingsEditorService extends AppDataComponent {
	private readonly facade = inject(ParticipationModelFacade);
	private readonly textfieldFacade = inject(TextfieldFacade);
	private readonly notificationFacade = inject(ParticipationNotificationFacade);
	private readonly themeFacade = inject(ThemeFacade);
	private readonly appFacade = inject(AppFacade);
	private readonly datasetFacade = inject(DatasetsFacade);
	private readonly formService = inject(FormStartingService);

	submit = false;

	tabs = tabs;
	tabValue: TabOptions = 'Settings';

	originalData?: Partial<ParticipationModel>;

	form: FormGroup<ParticipationModelForm>;

	pageTypesSelect = ['textfield', 'location', 'form', 'formsend'];
	pageTypesDisplay = ['Textfield', 'Location', 'Form', 'Send'];

	themeSelect: string[] = [];
	themeDisplay: string[] = [];

	$textfieldValues: WritableSignal<{ [key: string]: string }> = signal({});

	notificationSelect: string[] = [];
	notificationDisplay: string[] = [];

	languageSelect = LanguagesArray;
	languageDisplay = LanguagesDisplayArray;

	eventSelect = MarkerEventKeys;
	eventDisplay = MarkerEventDisplayKeys;
	classSelect = MarkerClassKeys;

	optionsOperator = OperatorArray;
	optionsDateOptions = DateOptionsArray;

	visibilityType = [VisibilityEnum.none, VisibilityEnum.visible];
	styleType = ['icon', 'label'];

	inputFields: ParticipationSettingsSelectItems[] = [
		{ type: 'name', label: 'Name', formType: 'text' },
		{ type: 'email', label: 'Email', formType: 'email' },
		{ type: 'postalcode', label: 'Postalcode', formType: 'text' },
		{ type: 'involvement', label: 'Involvement', formType: 'select' },
		{ type: 'about', label: 'About', formType: 'select' },
		{ type: 'theme', label: 'Theme', formType: 'select' },
		{ type: 'content', label: 'Content', formType: 'textarea' }
	];
	customFields: ParticipationSettingsSelectItems[] = [
		{ type: 'paragraph', label: 'Paragraph', formType: 'paragraph' }
	];
	customInputFields: ParticipationSettingsSelectItems[] = [
		{ type: 'textarea', label: 'Text area', formType: 'textarea' },
		{ type: 'textfield', label: 'Text field', formType: 'text' },
		{ type: 'select', label: 'Select', formType: 'select' },
		{ type: 'checkbox', label: 'Checkbox', formType: 'checkbox' },
		{ type: 'toggle', label: 'Toggle', formType: 'toggle' }
	];
	protectedInputFieldKeys = this.inputFields.map(val => val.type);

	rawForm$ = new ReplaySubject<Partial<ParticipationModel>>(1);

	currentStyle$ = new ReplaySubject<Partial<ParticipationStyles>>(1);
	private styleIndex: number;

	get pages(): FormArray<FormGroup<ParticipationModelPages>> {
		return this.form.get('pages') as FormArray<FormGroup<ParticipationModelPages>>;
	}

	get getListKeys(): FormArray<FormControl<string>> {
		return this.form.controls.cms.controls.listKeys as FormArray<FormControl<string>>;
	}

	get getLeftKeys() {
		return this.form.controls.cms.controls.reaction.controls.left as FormArray<
			FormControl<string>
		>;
	}

	get getRightKeys() {
		return this.form.controls.cms.controls.reaction.controls.right as FormArray<
			FormControl<string>
		>;
	}

	get getSprite(): FormControl<string | undefined> {
		return this.form.controls.style?.get('sprite') as FormControl<string | undefined>;
	}

	get getSpriteSelected(): FormControl<string | undefined> {
		return this.form.controls.style?.get('spriteSelect') as FormControl<string | undefined>;
	}

	get getUsers(): FormArray<FormGroup<ParticipationModelUser>> {
		return this.form.get('users') as FormArray<FormGroup<ParticipationModelUser>>;
	}

	/**
		Returns an array of string keys corresponding to the form fields of all Pages inside this Participation.

		This getter method uses the flatmap() method to create a flattend array of all the pages
		It then uses the reduce() function to iterate through the array of form data objects and extract the "key" property
		from each object if it exists. The extracted "key" values are added to an accumulator array and returned as the final output.

		@returns An array of string keys corresponding to the form fields of the all participation pages.
	 */
	get listOfKeys(): string[] {
		const objArray = this.pages.getRawValue();
		const keys = objArray
			.flatMap(obj => obj.form)
			.reduce((acc: string[], form) => {
				if (form && form.key) {
					acc.push(form.key);
				}
				return acc;
			}, []);

		return keys;
	}

	get styles() {
		return this.form.get('styles') as FormArray<FormGroup<ControlsOf<ParticipationStyles>>>;
	}

	get layoutOptions() {
		return this.form.controls.layout.get('options') as UntypedFormArray;
	}

	get currentStyleIndex() {
		return this.styleIndex || 0;
	}

	get type(): FormControl<MarkerType> {
		const style = this.styles.controls[0]?.get('style');
		return style?.get('type') as FormControl<MarkerType>;
	}

	/**
		@returns An array of string keys corresponding to the Styles inside the form.
	 */
	get stylesKeys(): string[] {
		const objArray = this.styles.value;

		return objArray
			.flatMap(obj => obj.id)
			.reduce((acc: string[], form) => {
				if (form) {
					acc.push(form);
				}
				return acc;
			}, []);
	}

	// All data needed as Observable
	data$ = combineLatest({
		data: this.facade.selected$.pipe(
			tap(data => {
				this.formService.startup(
					this.form,
					data,
					this.originalData,
					this.route,
					this.router,
					async () => {
						if (data) {
							this.originalData = data;

							// Creates all the Pages with all controls
							this.createPages(this.originalData?.pages);

							// Adds all Controls to the CMS object and it's Array's
							this.createCms(this.originalData?.cms);

							// Adds all users assigned to this Participation
							this.createUsers(this.originalData?.users);

							// Create Participation styles form
							this.createStyles(this.originalData?.styles);

							// createLayoutOptions is used after patching the original formData
							// so we can get the type of the options needed to populate the array
							this.createLayoutOptions(this.originalData?.layout?.options);
						}
					}
				);
			})
		),
		textfields: this.textfieldFacade.allTextfields$.pipe(
			tap(data => {
				if (data) {
					this.$textfieldValues.set({
						none: '',
						...this.convertTextfieldArrayToObject(data)
					});
				}
			})
		),
		users: this.appFacade.users$.pipe(
			map(users => {
				return (users ?? [])
					.filter(
						user =>
							user.role &&
							!['ATLAS', 'ATLASINTERNAL'].includes(user?.role) &&
							user?.mailing?.participation
					)
					.map((user: Partial<AppUser>) => {
						return {
							...user.user
						} as Partial<SafeUser>;
					});
			})
		),
		themes: this.themeFacade.themes$.pipe(
			tap(data => {
				if (!data.length) {
					return;
				}

				this.themeSelect = data
					.filter(theme => theme._id)
					.map(theme => theme._id) as string[];
				this.themeDisplay = data
					.filter(theme => theme.id)
					.map(theme => theme.id) as string[];
			})
		),
		notification: this.notificationFacade.data$.pipe(
			tap(data => {
				if (!data.length) {
					return;
				}

				this.notificationSelect = data.filter(d => d._id).map(d => d._id) as string[];
				this.notificationDisplay = data.filter(d => d.id).map(d => d.id) as string[];
			})
		)
	});

	init(): void {
		this.textfieldFacade.get();
		this.notificationFacade.get();
		this.themeFacade.get();
		this.appFacade.getAppUsers();

		this.createFormGroup();
	}

	createFormGroup(): void {
		this.form = new FormGroup<ParticipationModelForm>({
			_id: new FormControl({ value: undefined, disabled: true }, { nonNullable: true }),
			id: new FormControl('', { nonNullable: true, validators: Validators.required }),
			theme: new FormControl(undefined, { nonNullable: true }),
			notification: new FormControl(undefined, { nonNullable: true }),
			notificationLanguage: new FormControl(this.language, { nonNullable: true }),
			doubleOptIn: new FormControl(false, { nonNullable: true }),
			style: new FormGroup({
				sprite: new FormControl<string | undefined>(
					`${this.environment['yuno-cdn']}/sprites/public/participation-complete.svg`,
					{ nonNullable: true }
				),
				spriteSelect: new FormControl<string | undefined>(
					`${this.environment['yuno-cdn']}/sprites/public/participation-complete-active.svg`,
					{ nonNullable: true }
				)
			}),
			styles: new FormArray<FormGroup<ParticipationStylesForm>>([]),
			layout: new FormGroup<ParticipationLayoutForm>({
				fallback: new FormControl<string | undefined>('', {
					nonNullable: true
				}),
				filter: new FormControl<string | undefined>('', {
					nonNullable: true
				}),
				options: this.createLayoutOptionsForm()
			}),
			lastpage: new FormGroup({
				header: newLanguageFormGroup(),
				text: newLanguageFormGroup()
			}),
			active: new FormControl(true, { nonNullable: true }),
			liveUpdate: new FormControl(false, { nonNullable: true }),
			cms: new FormGroup<ParticipationModelCMS>({
				listKeys: new FormArray<FormControl<string>>([]),
				reaction: new FormGroup({
					left: new FormArray<FormControl<string>>([]),
					right: new FormArray<FormControl<string>>([])
				})
			}),
			reaction: new FormGroup({
				answerTitle: newLanguageFormGroup(),
				reactionTitle: newLanguageFormGroup()
			}),
			pages: new FormArray<FormGroup<ParticipationModelPages>>([]),
			crm: new FormGroup({
				dialog: new FormGroup({
					active: new FormControl(false, { nonNullable: true }),
					project: new FormControl('', { nonNullable: true }),
					client: new FormControl('', { nonNullable: true }),
					user: new FormControl('', { nonNullable: true })
				})
			}),
			users: new FormArray<FormGroup<ParticipationModelUser>>([]),
			textfield: new FormControl(undefined, { nonNullable: true })
		});
	}

	// Adds all Controls to the CMS object and it's Array's
	createCms(cms?: ParticipationCMS): void {
		if (!cms) {
			return;
		}

		for (const key of cms.listKeys || []) {
			const newControl = new FormControl(key, { nonNullable: true });
			this.getListKeys.push(newControl);
		}

		for (const key of cms.reaction?.left || []) {
			const newControl = new FormControl(key, { nonNullable: true });
			this.getLeftKeys.push(newControl);
		}

		for (const key of cms.reaction?.right || []) {
			const newControl = new FormControl(key, { nonNullable: true });
			this.getRightKeys.push(newControl);
		}
	}

	// Creates all the Pages with all controls
	createPages(pages?: ParticipationPages[]): void {
		if (!pages) {
			return;
		}

		for (const page of pages) {
			const newPage = new FormGroup<ParticipationModelPages>({
				type: new FormControl('text', { nonNullable: true }),
				data: new FormGroup({
					header: newLanguageFormGroup(),
					text: newLanguageFormGroup(),
					content: newLanguageFormGroup(),
					textMobile: newLanguageFormGroup(),
					required: new FormControl(true, { nonNullable: true }),
					validationMessage: newLanguageFormGroup()
				}),
				privacyStatement: new FormGroup({
					active: new FormControl(false, { nonNullable: true }),
					content: new FormControl('', { nonNullable: true }),
					color: new FormControl('#1775c7', { nonNullable: true })
				}),
				textfield: new FormGroup({
					content: newLanguageFormGroup()
				}),
				form: new FormArray<FormGroup<ParticipationForm>>(this.createPageFormdata(page))
			});

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			newPage.patchValue(page as any);
			this.pages.push(newPage);
		}
	}

	addNewPage(): void {
		const newPage = new FormGroup<ParticipationModelPages>({
			type: new FormControl('text', { nonNullable: true }),
			data: new FormGroup({
				header: newLanguageFormGroup(),
				text: newLanguageFormGroup(),
				content: newLanguageFormGroup(),
				textMobile: newLanguageFormGroup(),
				required: new FormControl(true, { nonNullable: true }),
				validationMessage: newLanguageFormGroup()
			}),
			privacyStatement: new FormGroup({
				active: new FormControl(false, { nonNullable: true }),
				content: new FormControl('', { nonNullable: true }),
				color: new FormControl('#1775c7', { nonNullable: true })
			}),
			textfield: new FormGroup({
				content: newLanguageFormGroup()
			}),
			form: new FormArray<FormGroup<ParticipationForm>>([])
		});

		this.pages.push(newPage);
	}

	// Creates a array with all FormGroups required
	// to populate the existing data
	createPageFormdata(page: ParticipationPages): FormGroup<ParticipationForm>[] {
		if (!page.form) {
			return [];
		}

		const arr: FormGroup<ParticipationForm>[] = [];
		for (const data of page.form) {
			const form = new FormGroup<ParticipationForm>({
				controlType: new FormControl('control', { nonNullable: true }),
				type: new FormControl('', { nonNullable: true }),
				key: new FormControl(
					{
						value: data.key,
						disabled: this.protectedInputFieldKeys.includes(data.key)
					},
					{ nonNullable: true }
				),
				label: newLanguageFormGroup(),
				selectValues: new FormArray<FormGroup<LanguageFormType>>([]),
				placeholder: newLanguageFormGroup(),
				required: new FormControl(false, { nonNullable: true }),
				validationMessage: newLanguageFormGroup()
			});

			// First Patch The Value
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			form.patchValue(data as any);

			// Force Paragraph to be required false
			if (data.type === 'paragraph') {
				form.patchValue({
					required: false
				});
			}

			// Now we can create the option keys
			if (data.selectValues) {
				for (const option of data.selectValues) {
					// const newControl = new FormControl(option, { nonNullable: true });
					const newControl = newLanguageFormGroup();
					newControl.patchValue(option as any);
					(form.get('selectValues') as FormArray).push(newControl);
				}
			}

			arr.push(form);
		}

		return arr;
	}

	// Add Item to PageArray
	addItemToPageArray(
		page: ParticipationModelPages,
		type: string,
		key: string,
		label: string
	): void {
		if (!page.form) {
			return;
		}

		const form = new FormGroup<ParticipationForm>({
			controlType: new FormControl('control', { nonNullable: true }),
			type: new FormControl(type, { nonNullable: true }),
			key: new FormControl(
				{ value: key, disabled: this.protectedInputFieldKeys.includes(key) },
				{ nonNullable: true }
			),
			label: newLanguageFormGroup(),
			selectValues: new FormArray<FormGroup<LanguageFormType>>([]),
			placeholder: newLanguageFormGroup(),
			required: new FormControl(false, { nonNullable: true }),
			validationMessage: newLanguageFormGroup()
		});

		// Create empty Select
		if (type === 'select') {
			// const newControl = new FormControl('', { nonNullable: true });
			const newControl = newLanguageFormGroup();
			(form.get('selectValues') as FormArray).push(newControl);
		}

		page.form.push(form);
	}

	getActivateParticipate(index: number): boolean {
		const page = this.pages.controls[index] as FormGroup<ParticipationModelPages>;
		if (!page) {
			return false;
		}

		return !!page.value?.privacyStatement?.active;
	}

	// Add Item to PageArray
	removeItemFromPageArra(page: ParticipationModelPages, index: number): void {
		page.form.removeAt(index);
	}

	// Adds all users assigned to this Participation
	createUsers(users?: SafeUser[]) {
		if (!users) {
			return;
		}

		for (const user of users) {
			const formGroup = new FormGroup<ParticipationModelUser>({
				displayName: new FormControl<string>(user.displayName || '', {
					nonNullable: true
				}),
				email: new FormControl<string>(user.email || '', {
					nonNullable: true
				})
			});
			this.getUsers.push(formGroup);
		}
	}

	selectUser(user: Partial<SafeUser>, checked: boolean) {
		if (checked) {
			// Check if the user already exists in the FormArray to prevent duplicates
			const existingUserIndex = this.getUsers.controls.findIndex(
				(control: FormGroup) => control.value.email === user.email
			);

			if (existingUserIndex === -1) {
				// Add new user as FormGroup
				const newUser = new FormGroup<ParticipationModelUser>({
					displayName: new FormControl<string>(user.displayName || '', {
						nonNullable: true
					}),
					email: new FormControl<string>(user.email || '', {
						nonNullable: true
					})
				});
				this.getUsers.push(newUser);
			}
		} else {
			// Remove the user from the FormArray based on email
			const userIndex = this.getUsers.controls.findIndex(
				(control: FormGroup) => control.value.email === user.email
			);

			if (userIndex !== -1) {
				this.getUsers.removeAt(userIndex);
			}
		}
	}

	// Example function to perform the conversion
	convertTextfieldArrayToObject(textfields: Partial<Textfield>[]): Record<string, string> {
		return textfields.reduce(
			(acc, textfield) => {
				if (textfield._id) {
					acc[textfield._id] = textfield.id as string;
				}
				return acc;
			},
			{} as Record<string, string>
		);
	}

	getParentRoute(ids: string[]): void {
		this.datasetFacade.getParentRoute(ids);
	}

	setStyleIndex(index: number) {
		const style = this.styles.controls[index]?.get('style')?.getRawValue();
		this.styleIndex = index;
		this.currentStyle$.next(style);
	}

	// Create Participation styles form
	createStyles(styles?: ParticipationStyles[]): void {
		if (!styles) {
			return;
		}

		this.form.controls.styles?.clear();

		styles.forEach(() => {
			const formGroup = new FormGroup<ParticipationStylesForm>({
				id: new FormControl<string>('', { nonNullable: true }),
				overwriteZoom: new FormControl<boolean>(false, { nonNullable: true })
			});

			const formStyle = this.createFormStyle();
			formGroup.setControl('style', formStyle);

			// Add Input to the Main form
			this.form.controls.styles?.push(formGroup);
		});

		this.form.controls.styles?.patchValue(styles);
	}

	addStyle(): void {
		const formGroup = new FormGroup<ParticipationStylesForm>({
			id: new FormControl<string>('new style', { nonNullable: true }),
			overwriteZoom: new FormControl<boolean>(false, { nonNullable: true })
		});

		const formStyle = this.createFormStyle();
		formGroup.setControl('style', formStyle);

		// Add Input to the Main form
		this.form.controls.styles?.push(formGroup);
	}

	addDefaultStyle(): void {
		const formGroup = new FormGroup<ParticipationStylesForm>({
			id: new FormControl<string>('Default', { nonNullable: true }),
			overwriteZoom: new FormControl<boolean>(false, { nonNullable: true })
		});
		const formStyle = this.createFormStyle();
		formGroup.setControl('style', formStyle);
		this.form.controls.styles?.push(formGroup);
		this.form.controls.layout.setControl('fallback', new FormControl('Default'));
	}

	private createFormStyle(): FormGroup<
		ControlsOf<MarkerIconStyle | MarkerPhotoStyle | MarkerLabelStyle>
	> {
		return new FormGroup<ControlsOf<MarkerIconStyle | MarkerPhotoStyle | MarkerLabelStyle>>({
			type: new FormControl('icon', { nonNullable: true }),
			visibility: new FormControl<VisibilityEnum>(VisibilityEnum.visible, {
				nonNullable: true
			}),
			minZoom: new FormControl<number>(1, { nonNullable: true }),
			maxZoom: new FormControl<number>(24, { nonNullable: true }),
			minFence: new FormControl<number>(1, { nonNullable: true }),
			maxFence: new FormControl<number>(24, { nonNullable: true }),
			areaColor: new FormControl<string>('#fff', { nonNullable: true }),
			areaOpacity: new FormControl<number>(0.75, { nonNullable: true }),
			zIndex: new FormControl<number>(1, { nonNullable: true }),
			alignment: new FormControl<string>('bottom', { nonNullable: true }),
			eventStyle: new FormControl<string>('chevron-event', { nonNullable: true }),
			icon: new FormControl<string>(
				`${this.environment['yuno-cdn']}/sprites/public/participation-complete.svg`,
				{ nonNullable: true }
			),
			iconSelect: new FormControl<string>(
				`${this.environment['yuno-cdn']}/sprites/public/participation-complete-active.svg`,
				{ nonNullable: true }
			),
			rotation: new FormControl<number>(0, { nonNullable: true }),
			scale: new FormControl<number>(1, { nonNullable: true }),
			class: new FormControl<string>('label pointer', { nonNullable: true }),
			color: new FormControl<string>('#394551', { nonNullable: true }),
			backgroundColor: new FormControl<string>('rgba(255,255,255,0.85)', {
				nonNullable: true
			})
		});
	}

	getStyleAlignment(index: number): string {
		const styles = this.form.get('styles') as FormArray<
			FormGroup<ControlsOf<ParticipationStyles>>
		>;
		return styles.controls[index].controls.style?.get('alignment')?.getRawValue() || 'center';
	}

	setStyleAlignment(index: number, value: string): void {
		const styles = this.form.get('styles') as FormArray<
			FormGroup<ControlsOf<ParticipationStyles>>
		>;
		styles.controls[index].controls.style?.get('alignment')?.patchValue(value);
		styles.controls[index].controls.style?.get('alignment')?.markAsDirty();
	}

	getStyleRuleType(): 'date' | 'number' | 'string' | undefined {
		const filter = this.form.controls?.layout?.get('filter')?.value;

		if (!filter) {
			return;
		}

		if (['endDate', 'startDate'].includes(filter)) {
			return 'date';
		}

		if (['maxZoom', 'minZoom', 'number', 'rotation'].includes(filter)) {
			return 'number';
		}

		return 'string';
	}

	addRuleToLayoutOptions(): void {
		const type = this.getStyleRuleType();
		let optionForm: UntypedFormArray;
		if (type === 'date') {
			optionForm = this.createOptionsFormDate();
		} else if (type === 'number') {
			optionForm = this.createOptionsFormNumber();
		} else {
			optionForm = this.createOptionsFormString();
		}

		this.form.controls.layout.controls.options?.push(optionForm);
	}

	removeRuleFromLayoutOptions(index: number): void {
		this.form.controls.layout.controls.options?.removeAt(index);
	}

	createLayoutOptions(options: ParticipationLayout['options']): void {
		this.form.controls?.layout.controls?.options?.clear();

		if (!options) {
			return;
		}

		const type = this.getStyleRuleType();
		for (const option of options) {
			let optionForm: UntypedFormArray;
			if (type === 'date') {
				optionForm = this.createOptionsFormDate();
			} else if (type === 'number') {
				optionForm = this.createOptionsFormNumber();
			} else {
				optionForm = this.createOptionsFormString();
			}

			optionForm.patchValue(option);
			this.form.controls.layout.controls.options?.push(optionForm);
		}
	}

	private createOptionsFormDate(): UntypedFormArray {
		return new UntypedFormArray([
			new FormArray([
				new FormControl<string>('<', { nonNullable: true }),
				new FormControl<number>(1, { nonNullable: true }),
				new FormControl<string>('years', { nonNullable: true })
			]),
			new FormControl<string>(this.stylesKeys[0] || '', { nonNullable: true })
		]);
	}

	private createOptionsFormNumber(): UntypedFormArray {
		return new UntypedFormArray([
			new FormArray([
				new FormControl<string>('<', { nonNullable: true }),
				new FormControl<number>(1, { nonNullable: true })
			]),
			new FormControl<string>(this.stylesKeys[0] || '', { nonNullable: true })
		]);
	}

	private createOptionsFormString(): UntypedFormArray {
		return new UntypedFormArray([
			new FormControl<string>('', {
				nonNullable: true,
				validators: [Validators.required, Validators.minLength(3)]
			}),
			new FormControl<string>(this.stylesKeys[0] || '', { nonNullable: true })
		]);
	}

	resetLayoutOptions(): void {
		this.form.controls?.layout.controls?.options?.clear();
		this.addRuleToLayoutOptions();
	}

	/**
	 * Returns a FormArray that should be one of these Layout Options
	 * DateLayoutOption = [[Operator, number, DateOptions], string];
	 * NumberLayoutOption = [[Operator, number], string];
	 * StringLayoutOption = [string, string];
	 *
	 * @private
	 * @return {*}  {(FormArray<FormGroup<ControlsOf<DateLayoutOption | StringLayoutOption | NumberLayoutOption>>>)}
	 * @memberof ParticipationSettingsEditorService
	 */
	private createLayoutOptionsForm(): UntypedFormArray {
		return new UntypedFormArray([]);
	}
}
