import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { combineLatest, map } from 'rxjs';

import { ChooseTemplateComponent } from '@yuno/admin/features/templates';
import {
	YunoAdminButtonsModule,
	YunoAdminCardComponent,
	YunoAdminTableComponent
} from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { Dataset } from '@yuno/api/interface';

import { DatasetsFacade } from '../../data-access';
import { DatasetTemplateViewComponent } from '../templates/template-view/template-view.component';

type DatasetTable = {
	_id: string;
	id: string;
	fences: number;
	layers: number;
	markers: number;
	panoramas: number;
	objects: number;
	categories: string;
	states: number;
};

@Component({
	imports: [
		AsyncPipe,
		YunoAdminTableComponent,
		RouterModule,
		YunoAdminButtonsModule,
		ChooseTemplateComponent,
		YunoAdminCardComponent,
		DatasetTemplateViewComponent
	],
	selector: 'yuno-admin-datasets',
	templateUrl: './datasets.component.html',
	styleUrls: ['./datasets.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatasetsComponent extends AppDataComponent implements OnInit {
	private datasetFacade = inject(DatasetsFacade);
	toggleTemplates = false;
	tabValue = 'Editor';

	data$ = combineLatest({
		datasets: this.datasetFacade.datasets$.pipe(
			map(data => {
				const datasets: DatasetTable[] = data.map(ds => ({
					_id: ds?._id || '',
					id: ds?.id || '',
					fences: ds.data?.fences?.length || 0,
					layers: ds.data?.layers?.length || 0,
					markers: ds.data?.markers?.length || 0,
					panoramas: ds.data?.panoramas?.length || 0,
					objects: ds.data?.objects?.length || 0,
					categories: ` ${ds.data?.markerCategories?.length || 0} /
					  ${ds.data?.photoCategories?.length || 0} / ${ds.data?.participates?.length || 0} `,
					states: ds.states?.length || 0
				}));
				return datasets as { [key: string]: unknown }[];
			})
		),
		selected: this.datasetFacade.selectedDataset$
	});

	ngOnInit(): void {
		this.datasetFacade.get();
	}

	onCreate(id: string | undefined) {
		this.datasetFacade.clearSelect();
		if (!id) {
			this.router.navigate(['create'], {
				relativeTo: this.route
			});
		} else {
			this.datasetFacade.saveFromTemplate(id);
		}
		this.toggleTemplates = false;
	}

	onSelectTemplate(bool: boolean) {
		if (this.templateApp) {
			this.onCreate(undefined);
		} else {
			this.toggleTemplates = bool;
		}
	}

	onSelect(row: Partial<Dataset>): void {
		this.router.navigate(['edit', row._id], {
			relativeTo: this.route
		});
	}

	onDuplicate(row: Partial<Dataset>): void {
		row._id && this.datasetFacade.duplicate(row._id);
	}

	onDelete(row: Partial<Dataset>): void {
		row._id && this.datasetFacade.delete(row._id);
	}
}
