import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	OnDestroy,
	OnInit,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterOutlet } from '@angular/router';
import { combineLatest, debounceTime, startWith, take, tap } from 'rxjs';

import { FormStartingService } from '@yuno/admin/core';
import { MapViewerComponent } from '@yuno/admin/features/themes/feature/editor/map-viewer/map-viewer.component';
import {
	EditContainerContentDirective,
	UserRightsMessageComponent,
	YunoAdminButtonsModule,
	YunoEditContainerModule
} from '@yuno/admin/ui';
import { AppDataComponent, redirectTo } from '@yuno/admin/utils';

import {
	ThemeDatasetsComponent,
	ThemeMapOptionsComponent,
	ThemeMapUiComponent,
	ThemePanoramaComponent,
	ThemeParticipateComponent,
	ThemeSettingsComponent
} from './form-editors';
import { ThemeEditorComponentService } from './theme-editor.component.service';
import { ThemeEditorFormService } from './theme-editor.form.service';

export type tabOptions =
	| 'Settings'
	| 'Map Options'
	| 'Map UI'
	| 'Datasets'
	| 'Participate'
	| 'Panorama';

@Component({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		YunoEditContainerModule,
		YunoAdminButtonsModule,
		ThemeMapOptionsComponent,
		ThemeMapUiComponent,
		ThemePanoramaComponent,
		ThemeParticipateComponent,
		ThemeDatasetsComponent,
		ThemeSettingsComponent,
		MapViewerComponent,
		UserRightsMessageComponent,
		EditContainerContentDirective,
		RouterOutlet
	],
	providers: [ThemeEditorFormService, ThemeEditorComponentService, FormStartingService],
	selector: 'yuno-admin-theme-editor',
	templateUrl: './theme-editor.component.html',
	styleUrls: ['./theme-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThemeEditorComponent extends AppDataComponent implements OnInit, OnDestroy {
	private readonly destroyRef = inject(DestroyRef);
	private readonly cdr = inject(ChangeDetectorRef);
	protected readonly redirectTo = redirectTo;

	readonly service = inject(ThemeEditorComponentService);

	private readonly formService = inject(FormStartingService);
	$isChanged = this.formService.$isChanged;

	targetRoute: string[] | undefined;

	data$ = combineLatest({
		language: this.service.language$.pipe(startWith('nl')),
		theme: this.service.theme$.pipe(
			tap(data => {
				this.cdr.markForCheck();

				if (data?.id) {
					this.editContainerBreadcrumbsService.addRoute('theme', data.id);
				}

				if (data?._id && this.router.url.includes('create')) {
					redirectTo(this.route, this.router, ['edit', `${data._id}`]);
				}
			})
		),
		sidemenusCreated: this.service.sidemenusCreated$,
		data: this.service.data$
	});

	ngOnInit(): void {
		this.service.onInit();
		this.onChanges();

		/* 	Detects current url and select dataset from url	*/
		this.route.paramMap.pipe(take(1)).subscribe(data => {
			const id = data.get('id');
			const targetRouteArray = this.route?.parent?.snapshot.pathFromRoot
				.map(s => s.url)
				.reduce((a, e) => {
					return a.concat(e);
				})
				.map(s => s.path);
			if (!id) {
				this.targetRoute = targetRouteArray;
				return;
			}
			this.targetRoute = targetRouteArray?.slice(0, -2);
			this.service.onSelect(id);
		});

		!this.minimalAppRole(this.userRoles.EDITOR) && this.service.form.disable();

		this.languageSelector();
	}

	onChanges(): void {
		this.service.form.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500))
			.subscribe(() => {
				this.service.onUpdate();
			});
	}

	ngOnDestroy() {
		this.editContainerBreadcrumbsService.removeRouteByType('theme');

		this.service.onClear();
		this.destroyLanguageSelector();
	}
}
