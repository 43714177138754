<div class="flex flex-col gap-2 p-4 sm:flex-row">
	@if (!router.url.includes('list')) {
		<yuno-admin-ui-selector-button (click)="backToBounds()">
			Back to overview
		</yuno-admin-ui-selector-button>

		<yuno-admin-ui-selector-button>
			{{ getZoomLevel() }}
		</yuno-admin-ui-selector-button>

		@if (showAddMarker && minimalAppRole(userRoles.EDITOR)) {
			<yuno-admin-ui-selector-button
				color="primary"
				[add]="true"
				(click)="openEditor(['marker', 'create'])">
				Add Marker
			</yuno-admin-ui-selector-button>
		}
	}
</div>

<ng-template #sideOptions>
	<div class="flex flex-col gap-8">
		<section>
			<h4>Filter</h4>
			<yuno-admin-side-options-button
				(click)="filterMarkers('all')"
				[active]="$filter() === 'all'">
				<yuno-admin-ui-selector-dropdown-item-counter
					[style.background-color]="'#000'"
					class="text-white">
					{{ $allCount() }}
				</yuno-admin-ui-selector-dropdown-item-counter>
				All
			</yuno-admin-side-options-button>
			<yuno-admin-side-options-button
				(click)="filterMarkers('public')"
				[active]="$filter() === 'public'">
				<yuno-admin-ui-selector-dropdown-item-counter
					[style.background-color]="'#27AE60'"
					class="text-white">
					{{ $publicCount() }}
				</yuno-admin-ui-selector-dropdown-item-counter>
				Public
			</yuno-admin-side-options-button>
			<yuno-admin-side-options-button
				(click)="filterMarkers('non-public')"
				[active]="$filter() === 'non-public'">
				<yuno-admin-ui-selector-dropdown-item-counter
					[style.background-color]="'#C0392B'"
					class="text-white">
					{{ $nonPublicCount() }}
				</yuno-admin-ui-selector-dropdown-item-counter>
				Non-public
			</yuno-admin-side-options-button>
		</section>
		<section>
			<h4>Display</h4>
			@for (display of selector; track display.key) {
				<yuno-admin-side-options-button
					[extraPadding]="true"
					#rla="routerLinkActive"
					[routerLink]="['../', display.url]"
					[routerLinkActive]="[]"
					[active]="rla.isActive">
					{{ display.key }}
				</yuno-admin-side-options-button>
			}
		</section>
		@if (form) {
			<section>
				<h4>Map View Options</h4>
				<form [formGroup]="form">
					<yuno-forms-toggle
						[labelPos]="'side'"
						formControlName="zoom"
						label="Respect Zoom settings"></yuno-forms-toggle>
					<yuno-forms-toggle
						[labelPos]="'side'"
						formControlName="fences"
						label="Show fences"></yuno-forms-toggle>
				</form>
			</section>
		}

		@if (minimalAppRole(userRoles.EDITOR)) {
			<section>
				<h4>Actions</h4>
				<div class="flex items-center gap-4 text-sm">
					<span>Markers:</span>
					<yuno-admin-ui-selector-button (click)="download()">
						<svg
							viewBox="0 0 24 24"
							class="mr-1 h-5 w-5"
							stroke-width="1.5"
							stroke="currentColor">
							<use href="#download-icon" />
						</svg>
						Download
					</yuno-admin-ui-selector-button>
				</div>
			</section>
		}
	</div>
</ng-template>
