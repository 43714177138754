import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject, combineLatest, debounceTime, startWith, tap } from 'rxjs';

import { AppDataComponent } from '@yuno/admin/utils';
import {
	YunoFormsColorComponent,
	YunoFormsColorTemplateComponent,
	YunoFormsSelectComponent,
	YunoFormsSelectTemplateComponent,
	YunoFormsTitleComponent
} from '@yuno/angular/forms/components';

import { InternalViewService } from '../../internal-view/internal-view.service';
import { TextHeadingSettingsFormComponent } from './text-heading-settings-form.component';

@Component({
	imports: [
		AsyncPipe,
		FormsModule,
		ReactiveFormsModule,
		TextHeadingSettingsFormComponent,
		YunoFormsTitleComponent,
		YunoFormsColorTemplateComponent,
		YunoFormsSelectTemplateComponent
	],
	selector: 'yuno-admin-atlas-text-heading-settings',
	templateUrl: './atlas-text-heading-settings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppAtlasTextHeadingSettingsComponent extends AppDataComponent {
	readonly service = inject(InternalViewService);

	private _globalColor = new BehaviorSubject<string>('');
	globalColor$ = this._globalColor.asObservable();

	private _globalFont = new BehaviorSubject<string>('');
	globalFont$ = this._globalFont.asObservable();

	data$ = combineLatest({
		color: this.globalColor$.pipe(
			startWith(''),
			debounceTime(500),
			tap(color => {
				if (color) {
					this.onUpdateColor(color);
				}
			})
		),
		font: this.globalFont$.pipe(
			startWith(null),
			tap(font => {
				if (font) {
					this.onUpdateFont(font);
				}
			})
		)
	});

	onGlobalColor(color: string) {
		this._globalColor.next(color);
	}

	onUpdateColor(color: string) {
		this.service.onUpdateTextHeading(color, 'color');
	}

	onGlobalFont(font: string) {
		this._globalFont.next(font);
	}

	onUpdateFont(font: string) {
		this.service.onUpdateTextHeading(font, 'font');
	}
}
