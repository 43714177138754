import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	TemplateRef,
	ViewChild,
	effect,
	inject,
	signal
} from '@angular/core';
import {
	MatDialog,
	MatDialogActions,
	MatDialogClose,
	MatDialogContent,
	MatDialogTitle
} from '@angular/material/dialog';

import { YunoAdminButtonComponent } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';

import { TilesetsSocketsService } from '../../data-access/socket.service';

@Component({
	selector: 'yuno-tilesets-currently-tiling',
	standalone: true,
	imports: [
		CommonModule,
		YunoAdminButtonComponent,
		OverlayModule,
		MatDialogActions,
		MatDialogClose,
		MatDialogTitle,
		MatDialogContent
	],
	template: `
		@if (!$isDataEmpty()) {
			<button yuno-admin-button color="secondary-dark" (click)="openOverlay()">
				<div class="-mx-2 flex flex-row gap-2 text-yuno-blue-gray-400">
					<div class="loading-container">
						<div class="loading-grid">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</div>
					<div class="loading-ellipsis">Tiling</div>
				</div>
			</button>
		}
		<!-- Dropdown menu -->
		<ng-template #overlayRef>
			<span mat-dialog-title>Tiling log</span>
			<mat-dialog-content>
				<div class="mb-2 pt-4">
					Below you can track the progress of the tiling of the tilesets you have updated.
				</div>
				<div class="flex flex-col gap-1 font-light">
					@if ($tilesetData(); as tilesetData) {
						@for (tileset of tilesetData | keyvalue; track tileset.value.id) {
							<div
								class="grid w-[768px] max-w-full grid-cols-3 rounded-md border border-yuno-blue-gray-400 bg-yuno-blue-gray-200 px-6 py-4 text-yuno-blue-gray-600">
								<div class="font-semibold">{{ tileset.value.title }}</div>
								<div class="">
									@if (tileset.value.step === 0) {
										Error
									} @else if (tileset.value.step === 4) {
										Done!
									} @else {
										Step {{ tileset.value.step }} of 3
									}
								</div>
								<div class="loading-ellipsis">{{ tileset.value.status }}</div>
							</div>
						}
					}
				</div>
			</mat-dialog-content>
			<mat-dialog-actions>
				<div class="flex w-full justify-center">
					<button yuno-admin-button color="muted" mat-dialog-close>Close</button>
				</div>
			</mat-dialog-actions>
		</ng-template>
	`,
	styleUrls: ['./currently-tiling.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CurrentlyTilingComponent extends AppDataComponent {
	@ViewChild('overlayRef') overlayTemplate: TemplateRef<unknown>;

	private readonly dialog = inject(MatDialog);
	private readonly sockets = inject(TilesetsSocketsService);

	$isDataEmpty = signal<boolean>(false);
	$tilesetData = this.sockets.$tilesetData;

	constructor() {
		super();

		effect(
			() => {
				const tilesets = this.$tilesetData();
				if (!tilesets) {
					return;
				}

				this.$isDataEmpty.set(Object.keys(tilesets).length === 0);
			},
			{ allowSignalWrites: true }
		);

		effect(() => {
			const empty = this.$isDataEmpty();
			if (!empty) {
				return;
			}

			this.closeOverlay();
		});
	}

	openOverlay() {
		this.dialog.open(this.overlayTemplate);
	}

	closeOverlay() {
		this.dialog.closeAll();
	}
}
