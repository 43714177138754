import {
	NgClass,
	NgFor,
	NgIf,
	NgStyle,
	NgSwitch,
	NgSwitchCase,
	NgSwitchDefault
} from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	Input,
	OnInit,
	inject
} from '@angular/core';

import { LanguagePipe, SafeHtmlIframePipe } from '@yuno/angular/pipes';
import { TextfieldInjectionToken, TextfieldToken } from '@yuno/angular/textfield';
import {
	CdnFile,
	LanguageStringsModel,
	MediaVideo,
	PageItemLink,
	TextNewsItem
} from '@yuno/api/interface';

import { TextImageComponent } from '../text-image/text-image.component';
import { TextLinkComponent } from '../text-link/text-link.component';
import { TextVideoComponent } from '../text-video/text-video.component';

@Component({
	selector: 'yuno-textfields-newsitem',
	templateUrl: './text-news-item.component.html',
	styleUrls: ['./text-news-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		NgIf,
		NgStyle,
		NgClass,
		NgFor,
		NgSwitch,
		NgSwitchCase,
		TextLinkComponent,
		NgSwitchDefault,
		TextImageComponent,
		TextVideoComponent,
		SafeHtmlIframePipe,
		LanguagePipe
	]
})
export class TextNewsItemComponent implements OnInit {
	@HostBinding('class.yuno__textfield__styling') private textStyling = true;
	@HostBinding('class') classNames = 'mt-8 first:mt-0 last:mb-8';

	private readonly injectValue = inject(TextfieldToken);
	private _imageFile?: CdnFile;
	private _secondaryImageFile?: CdnFile;

	// Color Inputs
	@Input({ required: false }) @HostBinding('style.backgroundColor') bgColor?: string;
	@Input({ required: false }) textColor?: string;
	@Input({ required: false }) textHeadingColor?: string;
	@Input({ required: false }) textLinkColor?: string;
	@Input({ required: false }) language?: string;
	@Input() disableInjection = false;

	// Alignment and Sizes
	@Input() align: TextNewsItem['align'] = 'bottom';
	@Input() size: TextNewsItem['size'] = '1/3';

	// Content
	@Input() title: LanguageStringsModel;
	@Input() subTitle: LanguageStringsModel;
	@Input() content: LanguageStringsModel;

	// Sources
	// First Image
	@Input() imageSrc?: string;

	@Input() set imageFile(file: CdnFile | undefined) {
		this._imageFile = file;
		this.imageSrc = file?.data?.url;
	}

	get imageFile(): CdnFile | undefined {
		return this._imageFile;
	}

	@Input() imageLink?: string;
	@Input() imageZoom?: boolean;

	// Secondary Image
	@Input() secondaryImageSrc?: string;

	@Input() set secondaryImageFile(file: CdnFile | undefined) {
		this._secondaryImageFile = file;
		this.secondaryImageSrc = file?.data?.url;
	}

	get secondaryImageFile(): CdnFile | undefined {
		return this._secondaryImageFile;
	}

	@Input() secondaryImageLink?: string;
	@Input() secondaryImageZoom?: boolean;

	// Image Ordering
	@Input() imageOrder?: 'row' | 'column' = 'row';

	@Input() videoSrc?: string;
	@Input() videoType?: MediaVideo['type'];
	@Input() description?: LanguageStringsModel;

	// Links
	@Input() links: PageItemLink[] = [];

	ngOnInit() {
		!this.disableInjection &&
			this.injectValue?.colors &&
			this.setInjectionColors(this.injectValue.colors);

		!this.disableInjection &&
			this.injectValue?.data &&
			this.handleInjectionData(
				this.injectValue.data as TextNewsItem,
				this.injectValue.language
			);
	}

	handleInjectionData(data: TextNewsItem, language?: string) {
		this.language = language;

		this.title = data.title;
		this.subTitle = data.subTitle;
		this.content = data.content;

		// colors
		this.bgColor = data.bgColor;
		this.textColor = data.textColor;
		this.textHeadingColor = data.textHeadingColor;
		this.textLinkColor = data.textLinkColor;
		// Alignment and Sizes
		this.align = data.align;
		this.size = data.size;
		// Contents
		this.title = data.title;
		this.subTitle = data.subTitle;
		this.content = data.content;
		// Sources
		this.imageSrc = data.imageSrc;
		this.imageFile = data.imageFile;
		this.imageLink = data.imageLink;
		this.imageZoom = data.imageZoom;

		this.secondaryImageSrc = data.secondaryImageSrc;
		this.secondaryImageFile = data.secondaryImageFile;
		this.secondaryImageLink = data.secondaryImageLink;
		this.secondaryImageZoom = data.secondaryImageZoom;

		this.imageOrder = data.imageOrder;

		this.videoSrc = data.videoSrc;
		this.videoType = data.videoType;

		this.description = data.description;
		this.links = data.links;
	}

	setInjectionColors(colors: TextfieldInjectionToken['colors']) {
		this.textLinkColor = colors?.textLinkColor || 'inherit';
		this.textHeadingColor = colors?.textHeadingColor || 'inherit';
	}

	getColDirection(): string {
		switch (this.align) {
			case 'left':
				return 'flex-col md:flex-row-reverse';
			case 'top':
				return 'flex-col-reverse gap-8';
			case 'bottom':
				return 'flex-col gap-8';
			default:
			case 'right':
				return 'flex-col md:flex-row';
		}
	}
}
