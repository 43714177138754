<router-outlet name="popup" />
<router-outlet />

@if (toggleTemplates) {
	<yuno-admin-templates
		[selector]="'dataset'"
		(bgClicked)="toggleTemplates = false"
		(datasetTemplate)="onCreate($event)"></yuno-admin-templates>
}

<yuno-card
	[noPadding]="true"
	[transparent]="true"
	[tabAccess]="minimalRole(roles.SUPERADMIN) && templateApp"
	[tabs]="['Editor', 'Templates']"
	(outputTab)="tabValue = $event.key">
	<div content class="mb-8 flex flex-col gap-8">
		@if (tabValue === 'Editor') {
			@if (data$ | async; as data) {
				<yuno-admin-table
					[selectable]="false"
					[draggable]="false"
					[filterable]="true"
					[sortable]="false"
					[pagination]="false"
					[pageOptions]="{
						pageSize: 25,
						pageSizeOptions: [5, 10, 25, 100],
						hidePageSize: false
					}"
					[data]="data.datasets"
					[buttons]="tableButtons"
					[columns]="[
						{ key: 'id', label: 'Dataset' },
						{ key: 'fences', label: 'Fences' },
						{ key: 'layers', label: 'Layers' },
						{ key: 'markers', label: 'Markers' },
						{ key: 'panoramas', label: 'Panoramas' },
						{ key: 'objects', label: 'Objects' },
						{ key: 'categories', label: 'Categories' },
						{ key: 'states', label: 'States' }
					]"
					(clicked)="onSelect($event)">
					<button yuno-admin-add-button (click)="onSelectTemplate(true)">
						New Dataset
					</button>
				</yuno-admin-table>
			}
		}
		@if (tabValue === 'Templates') {
			<yuno-admin-dataset-template-view></yuno-admin-dataset-template-view>
		}
	</div>
</yuno-card>


<ng-template #tableButtons let-row>
	<button yuno-admin-duplicate-button (click)="onDuplicate(row)"></button>
	<button yuno-admin-edit-button (click)="onSelect(row)"></button>
	<button yuno-admin-delete-admin-button (click)="onDelete(row)"></button>
</ng-template>
