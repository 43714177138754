<router-outlet></router-outlet>

<yuno-admin-navbar-buttons-container>
	<yuno-tilesets-currently-tiling></yuno-tilesets-currently-tiling>
</yuno-admin-navbar-buttons-container>

@if (data$ | async; as data) {
	<section class="grid grid-cols-1">
		<yuno-admin-table
			[selectable]="false"
			[draggable]="false"
			[filterable]="true"
			[sortable]="false"
			[pagination]="false"
			[pageOptions]="{
				pageSize: 100,
				pageSizeOptions: [5, 10, 25, 100],
				hidePageSize: false
			}"
			[data]="tilesets.tiles"
			[buttons]="tableButtons"
			[buttonEllipsis]="true"
			[defaultColumn]="'name'"
			[columns]="[
				{ key: 'name', label: 'tileset' },
				{
					key: 'date',
					label: 'lastest update',
					type: 'date',
					stickyEnd: true,
					width: 11.875
				}
			]">
			<div class="flex gap-4">
				<button yuno-admin-add-button (click)="openUploadMbtiles()">Upload Tilesets</button>
				<button yuno-admin-add-button (click)="onCreate()">Create Custom Tileset</button>
			</div>
		</yuno-admin-table>
		@if (data.customTilesets) {
			<yuno-admin-table
				[selectable]="false"
				[draggable]="false"
				[filterable]="false"
				[sortable]="false"
				[pagination]="false"
				[pageOptions]="{
					pageSize: 100,
					pageSizeOptions: [5, 10, 25, 100],
					hidePageSize: false
				}"
				[defaultColumn]="'name'"
				[data]="tilesets.custom"
				(clicked)="onSelect($event)"
				[buttons]="tableButtonsCustom"
				[buttonEllipsis]="true"
				[columns]="[
					{ key: 'name', label: 'custom tileset' },

					{
						key: 'date',
						label: 'lastest update',
						type: 'date',
						stickyEnd: true,
						width: 11.875
					}
				]">
			</yuno-admin-table>
		}
	</section>
}

<ng-template #tableButtons let-row>
	@if (row?.pmtile) {
		<button yuno-admin-button-table (click)="onCopySourceUrl(row)">Copy source URL</button>
	}
	@if (row?.tileJSON) {
		<button yuno-admin-button-table color="muted" (click)="onCopyTileJSON(row)">
			Copy TileJson Url (DEPRECATED)
		</button>
	}
	@if (row?.tileUrl) {
		<button yuno-admin-button-table color="muted" (click)="onCopyTileUrl(row)">
			{{ 'Copy {z}{x}{y}' }} (DEPRECATED)
		</button>
	}
	@if (row?.view) {
		<a [href]="row.view" target="_blank" rel="noopener">
			<button yuno-admin-button-table color="secondary">View</button>
		</a>
	}
</ng-template>

<ng-template #tableButtonsCustom let-row>
	@if (row?.path) {
		<a
			[href]="
				environment['yuno-tileserver'] + '/custom-tileset/' + appId + '/' + row.id + '/log'
			"
			target="_blank"
			rel="noopener">
			<button yuno-admin-button-table color="muted">View Log</button>
		</a>
	}
	@if (row?.pmtile) {
		<button yuno-admin-button-table (click)="onCopySourceUrl(row)">Copy source URL</button>
	}
	@if (row?.view) {
		<a [href]="row.view" target="_blank" rel="noopener">
			<button yuno-admin-button-table color="secondary">View</button>
		</a>
	}
	<button yuno-admin-edit-button (click)="onSelect(row)"></button>
	<button yuno-admin-delete-admin-button (click)="onDelete(row)"></button>
</ng-template>
