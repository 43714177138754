@if (textfields$ | async; as text) {
	<yuno-admin-navbar-buttons-container>
		<button yuno-admin-save-button (click)="onSave()"></button>
	</yuno-admin-navbar-buttons-container>
	<div class="mb-8 flex flex-col gap-8">
		<yuno-card
			[noPadding]="true"
			[transparent]="true"
			[tabs]="[
				'User Interface',
				'Navbar & Map',
				'Text Styling',
				'Help',
				'Languages',
				'Project Configuration'
			]"
			(outputTab)="service.tabValue = $event.key">
			<div content class="grid grid-cols-1 gap-4">
				@if (!minimalAppRole(userRoles.EDITOR)) {
					<yuno-user-rights-message />
				}
				@if (service.tabValue === 'User Interface') {
					<yuno-admin-atlas-ui-settings />
				}

				@if (service.tabValue === 'Languages') {
					<yuno-admin-atlas-language-settings />
				}

				@if (service.tabValue === 'Help') {
					<yuno-admin-atlas-help-settings />
				}

				@if (service.tabValue === 'Navbar & Map') {
					<yuno-admin-atlas-navbar-color-settings />
				}

				@if (service.tabValue === 'Text Styling') {
					<yuno-admin-atlas-text-heading-settings />
				}

				@if (service.tabValue === 'Project Configuration') {
					<yuno-admin-app-config-atlas-settings />
				}
			</div>
		</yuno-card>
	</div>
}
