import { Injectable, inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import bbox from '@turf/bbox';
import { lineString } from '@turf/helpers';
import { Position } from 'geojson';

import { ArrayValidators, CustomValidators } from '@yuno/angular/forms';
import { CreateAppDto } from '@yuno/api/dto';
import { round } from '@yuno/shared/helpers';

import { CreateAppFacade } from '../../data-access';

export interface CreateAppForm {
	id: FormControl<string>;
	url: FormArray<FormControl<string>>;
	client: FormControl<string>;
	language: FormControl<string>;
	coordinates: FormArray<FormControl<number>>;
	extend: FormArray<FormArray<FormControl<number>>>;
	blank: FormControl<boolean>;
}

@Injectable({
	providedIn: 'root'
})
export class CreateAppService {
	private readonly facade = inject(CreateAppFacade);

	form: FormGroup<CreateAppForm>;

	clientsSelect: string[] = [];
	clientsDisplay: string[] = [];

	get urls(): FormArray<FormControl<string>> {
		return this.form.get('url') as FormArray<FormControl<string>>;
	}

	get urlValues(): string[] {
		return this.form.get('url')?.value || [];
	}

	get coordinates(): FormArray {
		return this.form.get('coordinates') as FormArray;
	}

	get extend(): FormArray {
		return this.form.get('extend') as FormArray;
	}

	createFormGroup(): void {
		this.form = new FormGroup<CreateAppForm>({
			id: new FormControl<string>('', {
				nonNullable: true,
				validators: [Validators.required, Validators.minLength(4)]
			}),
			url: new FormArray<FormControl<string>>([], ArrayValidators.minLength(1)),
			client: new FormControl<string>('', {
				nonNullable: true,
				validators: Validators.required
			}),
			language: new FormControl<string>('nl', { nonNullable: true }),
			coordinates: new FormArray<FormControl>([
				new FormControl<number>(4.463),
				new FormControl<number>(51.912)
			]),
			extend: new FormArray<FormArray<FormControl<number>>>([
				new FormArray([
					new FormControl(3.2, { nonNullable: true }),
					new FormControl(50.75, { nonNullable: true })
				]),
				new FormArray([
					new FormControl(7.22, { nonNullable: true }),
					new FormControl(53.7, { nonNullable: true })
				])
			]),
			blank: new FormControl<boolean>(false, { nonNullable: true })
		});

		this.addUrl();
	}

	addUrl(): void {
		const control = new FormControl<string>('', {
			nonNullable: true,
			validators: [
				Validators.required,
				Validators.minLength(3),
				CustomValidators.noSpacesLowerCase()
			]
		});
		this.urls.push(control);
	}

	removeUrl(index: number): void {
		this.urls.removeAt(index);
	}

	onSubmit(): void {
		this.form.markAllAsTouched();

		if (this.form.valid) {
			const dto: CreateAppDto = this.form.value as CreateAppDto;
			this.facade.createApp(dto);
		}
	}

	onUpdateCoordinates(coordinates: Position) {
		this.coordinates.controls[0].setValue(round(coordinates[0], 4));
		this.coordinates.controls[1].setValue(round(coordinates[1], 4));
	}

	onUpdateExtend(polygon: Position[] | null) {
		if (polygon) {
			const line = lineString(polygon);
			const bb = bbox(line);
			const newBounds: [[number, number], [number, number]] = [
				[round(bb[0], 4), round(bb[1], 4)],
				[round(bb[2], 4), round(bb[3], 4)]
			];
			this.extend.patchValue(newBounds);
		}
	}
}
