<yuno-edit-container [col]="12" [pos]="4" [span]="7" style="z-index: 400">
	<ng-container buttons>
		<button yuno-admin-close-button (click)="onClose()"></button>
	</ng-container>

	<div edit-container-content>
		<div class="flex flex-col gap-4">
			<h4>PMTiles to upload</h4>

			@for (file of service.files; track file; let i = $index) {
				<div>
					<div class="flex">
						<label class="yuno-form flex-1">
							<input
								[name]="'file-' + i"
								[type]="'text'"
								[value]="file.name"
								disabled />
						</label>
					</div>
				</div>
			}
			<input
				id="inputfile"
				class="hidden"
				type="file"
				accept=".pmtiles"
				(change)="service.onFileChange($event)"
				[multiple]="true" />
			<div class="flex gap-2">
				<button
					yuno-admin-button
					for="inputfile"
					(click)="onFile()"
					[ngStyle]="{ opacity: 0.93 }"
					color="primary"
					[add]="true">
					{{ service.files.length ? 'Replace' : 'Add / Overwrite' }}
				</button>
				@if (service.files.length) {
					<button
						yuno-admin-button
						(click)="service.upload()"
						[ngStyle]="{ opacity: 0.93 }"
						color="success">
						Upload
					</button>
				}
			</div>

			<yuno-admin-annotation>
				<p>
					Only the upload of PMTiles will work. If you have an older MBTiles file, you can
					use
					<a
						class="inline-block underline"
						href="https://github.com/protomaps/go-pmtiles/releases"
						target="_blank"
						rel="noopener"
						>go-pmtiles</a
					>
					to convert it to PMTiles.
				</p>
				<p>
					When you are on the XKP network, using Windows, you can use this converter
					script, just drag-and-drop the MBtiles on this file:
					<a
						class="inline-block underline"
						href="https://yunomaptiles.blob.core.windows.net/clients/convert_mbtiles.sh"
						target="_blank"
						rel="noopener">
						convert_mbtiles.sh
					</a>
				</p>
			</yuno-admin-annotation>
		</div>
	</div>
</yuno-edit-container>
