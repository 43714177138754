@if (data$ | async; as data) {
	@if (data.toggle && service.component && service.events) {
		<yuno-admin-events-forms
			[group]="service.component"
			[array]="service.events"
			[arrayName]="service.eventType">
		</yuno-admin-events-forms>
	}

	@if (data.textfield) {
		<yuno-edit-container [col]="4" [pos]="1" [span]="4">
			<ng-container actions>
				<button yuno-admin-add-button (click)="service.onAddItem()">Content block</button>
				<button yuno-admin-add-muted-button (click)="service.onBrowseLibrary()">
					From Library
				</button>
			</ng-container>
			<ng-container buttons>
				<button yuno-admin-save-button (click)="service.onSave()"></button>
				<button
					yuno-admin-close-button
					(click)="redirectTo(route, router); service.onClose()"></button>
			</ng-container>
			<div class="flex flex-col gap-2 md:h-full md:flex-row">
				<div
					edit-container-content
					class="max-w-[500px]"
					cdkDropList
					[cdkDropListData]="service.components"
					(cdkDropListDropped)="service.drop($event)">
					@if (service.form) {
						<form [formGroup]="service.form">
							@if (!minimalAppRole(userRoles.EDITOR)) {
								<yuno-user-rights-message class="mb-4" />
							}
							
							<div class="mb-4 flex w-full gap-4">
								<yuno-forms-text
									formControlName="id"
									label="Textfield component name"
									placeholder="Add textfield name"
									class="flex-1" />

								@if (xkpMember) {
									<yuno-forms-toggle formControlName="public" label="Public" />
								}
							</div>

							<h4 class="pointer-events-none mb-4 mt-8">Textfield items</h4>
							<yuno-textfield-injector
								[render]="false"
								[data]="data.textfield"
								[language]="language"
								[buttonColor]="config.colors?.buttonFont"
								[buttonBgColor]="config.colors?.button"
								(componentsOut)="service.injectorComponents = $event">
							</yuno-textfield-injector>
							<div class="flex flex-col gap-4">
								@for (
									item of service.injectorComponents;
									track service.trackByComponentId(i, item);
									let i = $index
								) {
									<div cdkDrag>
										<yuno-admin-textfield-dynamic
											[item]="item"
											[active]="service.editor.index === i"
											(clicked)="
												service.onEditComponent(item.key, item.data, i);
												detectChanges(100)
											"
											(duplicate)="service.onDuplicateComponent($event, i)"
											(removed)="service.onRemoveComponent(i)">
										</yuno-admin-textfield-dynamic>
									</div>
								}
							</div>
							<div class="example-custom-placeholder" *cdkDragPlaceholder></div>
						</form>
					}
				</div>
				@if (minimalAppRole(userRoles.EDITOR)) {
					<div edit-container-content class="flex-1">
						@if (
							service.editor.type === undefined &&
							!service.addItems &&
							!service.library
						) {
							<span>
								Select an item to start editing or add a new or existing item using
								the buttons in the upper left corner.
							</span>
						}
						@if (service.addItems) {
							<yuno-admin-textfield-pages-add
								(selected)="service.onAddComponent($event.item.key)" />
						}
						@if (service.library) {
							<yuno-admin-browse-items
								(selected)="
									service.onAddComponent($event.item.key, $event.component)
								">
							</yuno-admin-browse-items>
						}
						@if (service.editor.type !== undefined) {
							<yuno-admin-editor-dynamic [editor]="service.editor" />
						}
					</div>
				}
			</div>
		</yuno-edit-container>
	}
}
