import { HttpClient, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, InjectionToken, inject } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';

import { GeneralSuccessDto, RenderStringSuccessDto } from '@yuno/api/dto';
import { ApiData } from '@yuno/api/interface';

export const API_URL_KEY = new InjectionToken<string>('apiUrl');
export const API_CDN_URL_KEY = new InjectionToken<string>('apiCdnUrl');

@Injectable()
export class ApiService {
	private http = inject(HttpClient);
	private api_url = inject(API_URL_KEY);

	get<T>(url: string): Promise<T> {
		return lastValueFrom(this.http.get<T>(`${this.api_url}/${url}`));
	}

	getObservable<T>(url: string): Observable<T> {
		return this.http.get<T>(`${this.api_url}/${url}`);
	}

	getData<T>(url: string): Observable<ApiData<T>> {
		return this.http.get<ApiData<T>>(`${this.api_url}/${url}`);
	}

	delete<T>(url: string): Promise<T> {
		return lastValueFrom(this.http.delete<T>(`${this.api_url}/${url}`));
	}

	deleteObservable<T>(url: string): Observable<T> {
		return this.http.delete<T>(`${this.api_url}/${url}`);
	}

	async update<T>(url: string, body?: unknown): Promise<T> {
		if (!body) {
			body = {};
		}
		return await lastValueFrom(this.http.patch<T>(`${this.api_url}/${url}`, body));
	}

	updateObservable<T, RequestBody>(url: string, body: RequestBody): Observable<T> {
		return this.http.patch<T>(`${this.api_url}/${url}`, body);
	}

	async post<T>(url: string, body: unknown): Promise<T> {
		return lastValueFrom(this.http.post<T>(`${this.api_url}/${url}`, body));
	}

	postObservable<T, RequestBody>(url: string, body: RequestBody): Observable<T> {
		return this.http.post<T>(`${this.api_url}/${url}`, body);
	}

	renderString(data: unknown, properties: object): Observable<RenderStringSuccessDto> {
		return this.http.post<GeneralSuccessDto>(`${this.api_url}/tools/render-string`, {
			data,
			properties
		});
	}
}

@Injectable({
	providedIn: 'root'
})
export class ApiObservableService {
	private http = inject(HttpClient);
	private api_url = inject(API_URL_KEY);

	get<T>(url: string, params: HttpParams = new HttpParams()): Observable<T> {
		return this.http.get<T>(`${this.api_url}/${url}`, {
			headers: this.headers,
			params
		});
	}

	post<T, D>(url: string, data?: D): Observable<T> {
		return this.http.post<T>(`${this.api_url}/${url}`, data, {
			headers: this.headers
		});
	}

	put<T, D>(url: string, data: D): Observable<T> {
		return this.http.put<T>(`${this.api_url}/${url}`, data, {
			headers: this.headers
		});
	}

	patch<T, D>(url: string, data: D): Observable<T> {
		return this.http.patch<T>(`${this.api_url}/${url}`, data, {
			headers: this.headers
		});
	}

	delete<T>(url: string): Observable<T> {
		return this.http.delete<T>(`${this.api_url}/${url}`, {
			headers: this.headers
		});
	}

	get headers(): HttpHeaders {
		const headersConfig = {
			'Content-Type': 'application/json',
			Accept: 'application/json'
		};

		return new HttpHeaders(headersConfig);
	}
}

@Injectable({
	providedIn: 'root'
})
export class ApiCDNObservableService {
	private http = inject(HttpClient);
	private api_url = inject(API_CDN_URL_KEY);

	get<T>(url: string, params: HttpParams = new HttpParams()): Observable<T> {
		return this.http.get<T>(`${this.api_url}/${url}`, {
			params,
			responseType: 'json'
		});
	}

	post<T, D>(url: string, data?: D): Observable<HttpEvent<T>> {
		return this.http.post<T>(`${this.api_url}/${url}`, data, {
			reportProgress: true,
			observe: 'events',
			responseType: 'json'
		});
	}

	put<T, D>(url: string, data: D): Observable<HttpEvent<T>> {
		return this.http.put<T>(`${this.api_url}/${url}`, data, {
			reportProgress: true,
			observe: 'events',
			responseType: 'json'
		});
	}

	patch<T, D>(url: string, data: D): Observable<HttpEvent<T>> {
		return this.http.patch<T>(`${this.api_url}/${url}`, data, {
			reportProgress: true,
			observe: 'events',
			responseType: 'json'
		});
	}

	delete<T>(url: string): Observable<T> {
		return this.http.delete<T>(`${this.api_url}/${url}`, {
			responseType: 'json'
		});
	}
}
