import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { SpritesheetModule } from '@yuno/admin/features/spritesheets';
import {
	EditContainerComponent,
	EditContainerContentDirective,
	YunoAdminCloseButtonComponent
} from '@yuno/admin/ui';
import {
	YunoFormsColorComponent,
	YunoFormsDividerComponent,
	YunoFormsNumberComponent,
	YunoFormsSelectComponent,
	YunoFormsSpanComponent,
	YunoFormsTextComponent,
	YunoFormsTitleComponent
} from '@yuno/angular/forms/components';

import { ParticipationModelEditorsDefaultComponent } from '../../default.component';
import { SvgSelectContainerComponent } from '../../style/svg-select-container/svg-select-container.component';
import { ParticipationStyleAlignmentComponent } from '../style-alignment/style-alignment.component';

@Component({
	selector: 'yuno-admin-participation-style-editor',
	standalone: true,
	imports: [
		ReactiveFormsModule,
		SpritesheetModule,
		EditContainerComponent,
		YunoAdminCloseButtonComponent,
		EditContainerContentDirective,
		ParticipationStyleAlignmentComponent,
		SvgSelectContainerComponent,
		YunoFormsTitleComponent,
		YunoFormsSpanComponent,
		YunoFormsSelectComponent,
		YunoFormsNumberComponent,
		YunoFormsDividerComponent,
		YunoFormsTextComponent,
		YunoFormsColorComponent
	],
	templateUrl: './style-editor.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParticipationStyleEditorComponent extends ParticipationModelEditorsDefaultComponent {
	@Output() closePage = new EventEmitter<void>();

	selectSprite: 'default' | 'selected' = 'default';

	getIconSelect(index: number): string {
		return this.service.styles.controls[index].controls.style?.get('iconSelect')?.value || '';
	}

	getIcon(index: number): string {
		return this.service.styles.controls[index].controls.style?.get('icon')?.value || '';
	}

	setIcon(icon: string, index: number): void {
		if (this.selectSprite === 'default') {
			this.service.styles.controls[index].controls.style?.get('icon')?.patchValue(icon);
			this.service.styles.controls[index].controls.style?.get('icon')?.markAsDirty();
		} else {
			this.service.styles.controls[index].controls.style?.get('iconSelect')?.patchValue(icon);
			this.service.styles.controls[index].controls.style?.get('iconSelect')?.markAsDirty();
		}
	}

	onClosePage(): void {
		this.closePage.emit();
	}
}
