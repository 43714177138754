import { Injectable, inject } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, startWith, tap } from 'rxjs';

import { FormStartingService } from '@yuno/admin/core';
import { EventForm, EventFormsService, EventsFacade } from '@yuno/admin/features/events';
import { CustomValidators, isFormValid } from '@yuno/angular/forms';
import { MessageService } from '@yuno/angular/notifications';
import { Event, MapUiButtons } from '@yuno/api/interface';

import { MapUiButtonsFacade } from '../../data-access';

export interface MapUiForm {
	_id?: FormControl<string>;
	id: FormControl<string>;
	icon: FormControl<string>;
	desktop: FormControl<string>;
	mobile: FormControl<string>;
	events: FormArray<FormGroup<EventForm>>;
}

@Injectable()
export class MapUiButtonsEditorService {
	private readonly route = inject(ActivatedRoute);
	private readonly router = inject(Router);

	private readonly mapUiFacade = inject(MapUiButtonsFacade);
	private readonly message = inject(MessageService);
	private readonly eventsFacade = inject(EventsFacade);
	private readonly eventsForm = inject(EventFormsService);

	private readonly formService = inject(FormStartingService);
	$isChanged = this.formService.$isChanged;

	originalData: Partial<MapUiButtons>;
	changesMade = 0;

	get events() {
		return this.form.get('events') as FormArray<FormGroup<EventForm>>;
	}

	form: FormGroup<MapUiForm>;

	iconMapButtonValues = [
		'plane',
		'rocket',
		'globe',
		'search',
		'three-dots',
		'marker',
		'info',
		'question',
		'summary',
		'links',
		'documents',
		'notification',
		'settings',
		'photo',
		'image',
		'layers',
		'book',
		'talk'
	];
	iconMapButtonDisplay = [
		'Plane',
		'Rocket',
		'Glove',
		'Search',
		'3 Dots',
		'Marker',
		'Info',
		'Question',
		'Summary',
		'Links',
		'Documents',
		'Notification',
		'Settings',
		'Photo',
		'Image',
		'Layers',
		'Book',
		'Talk'
	];

	locMapButtonValues = ['hidden', 'top-left', 'top-right', 'bottom-left', 'bottom-right'];
	locMapButtonDisplay = ['Hidden', 'Top Left', 'Top Right', 'Bottom Left', 'Bottom Right'];

	data$ = combineLatest({
		selected: this.mapUiFacade.selected$.pipe(
			tap(data => {
				this.formService.startup(
					this.form,
					data,
					this.originalData,
					this.route,
					this.router,
					async () => {
						if (data) {
							this.originalData = data as MapUiButtons;

							const events = data?.events;
							if (events && events.length >= 1) {
								const count = events.length;
								if (count > 0) {
									for (let i = 0; i < count; i++) {
										this.events.push(
											this.eventsForm.createEvent(
												events[i].type,
												events[i].options
											)
										);
									}
								}
							}
						}
					}
				);
			})
		),
		toggle: this.eventsFacade.toggle$.pipe(startWith(false))
	});

	onSelect(id: string | null) {
		this.mapUiFacade.select(id);
	}

	onToggleEvents() {
		this.eventsFacade.toggleEvents(true);
		this.eventsFacade.updateEvents(this.events.getRawValue() as Event[]);
		this.eventsFacade.setKey('events');
	}

	onSave() {
		const valid = isFormValid(this.form);
		if (!valid) {
			this.message.sendToast(
				'Some fields have errors. Please check and fix them.',
				'error',
				5
			);
			return;
		}

		this.mapUiFacade.save();
	}

	onUpdate() {
		this.mapUiFacade.update(this.form.getRawValue() as MapUiButtons);
	}

	onClose(): void {
		this.mapUiFacade.clearSelect();
	}

	createFormGroup(): void {
		this.form = new FormGroup<MapUiForm>({
			id: new FormControl('', {
				nonNullable: true,
				validators: [CustomValidators.webSlugValidator()]
			}),
			icon: new FormControl('plane', { nonNullable: true }),
			desktop: new FormControl('hidden', { nonNullable: true }),
			mobile: new FormControl('hidden', { nonNullable: true }),
			events: new FormArray<FormGroup<EventForm>>([])
		});
	}
}
