
<section  class="grid grid-cols-1 gap-12">
	@if(data$ | async; as data) {
		<div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
			<yuno-forms-title class="col-span-full">Global</yuno-forms-title>
			<yuno-forms-template-color
										[(ngModel)]="data.color"
									   (ngModelChange)="onGlobalColor($event)" label="Color" placeholder="#4D5B74" />
			<div></div>
			<div></div>
			<yuno-forms-template-select
				[(ngModel)]="data.font"
				(ngModelChange)="onGlobalFont($event)"
				label="Font"
				[display]="service.fontDisplay"
				[selectValues]="service.fontSelect" />
		</div>
	}
	<!-- TEXT HEADING -->
	<form [formGroup]="service.configForm" class="flex flex-col gap-16">
		<yuno-admin-atlas-text-heading-settings-form [headingName]="'h1'"></yuno-admin-atlas-text-heading-settings-form>
		<yuno-admin-atlas-text-heading-settings-form [headingName]="'h2'"></yuno-admin-atlas-text-heading-settings-form>
		<yuno-admin-atlas-text-heading-settings-form [headingName]="'h3'"></yuno-admin-atlas-text-heading-settings-form>
		<yuno-admin-atlas-text-heading-settings-form [headingName]="'h4'"></yuno-admin-atlas-text-heading-settings-form>
		<yuno-admin-atlas-text-heading-settings-form [headingName]="'h5'"></yuno-admin-atlas-text-heading-settings-form>
		<yuno-admin-atlas-text-heading-settings-form [headingName]="'h6'"></yuno-admin-atlas-text-heading-settings-form>
		<yuno-admin-atlas-text-heading-settings-form
			[headingName]="'paragraph'"></yuno-admin-atlas-text-heading-settings-form>
	</form>
</section>
