@if (data$ | async; as data) {
	<yuno-edit-container [col]="4" [pos]="2" [span]="2">
		<ng-container buttons>
			<button yuno-admin-save-button (click)="onSave()" [disabled]="!$isChanged()"></button>
			<button yuno-admin-close-button (click)="onClose()"></button>
		</ng-container>
		<div edit-container-content>
			@if (service.form) {
				<form [formGroup]="service.form">
					@if (!minimalAppRole(userRoles.EDITOR)) {
						<yuno-user-rights-message />
					}
					<yuno-forms-select
						class="flex-1"
						formControlName="type"
						[display]="service.typeDisplay"
						[selectValues]="service.typeValues"
						label="Type" />
					<yuno-forms-divider />
					<ng-container formGroupName="data">
						<div class="flex flex-col gap-8">
							<yuno-forms-text
								class="flex-1"
								formControlName="id"
								label="Object name"
								placeholder="object name" />
							<yuno-forms-toggle formControlName="public" label="Public" />
						</div>
						<yuno-forms-divider />
						<div class="flex flex-col gap-8">
							<yuno-forms-text
								class="flex-1"
								formControlName="url"
								label="Url"
								placeholder="{{ environment['yuno-cdn'] }}/clients/..." />
							<yuno-forms-select
								class="flex-1"
								formControlName="epsg"
								[selectValues]="service.epsgValues"
								label="EPSG Code" />
						</div>
						<yuno-forms-divider />
						@if (service.form.value.type === 'data-shapefile') {
							<ng-container formGroupName="styling">
								<yuno-forms-select
									class="mb-8 flex-1"
									formControlName="type"
									[selectValues]="service.shapefileValues"
									label="Layer type" />
								<label class="yuno-form">
									<span class="select-none">Layout Properties</span>
								</label>
								<yuno-code-editor
									[readOnly]="readonly"
									[control]="layoutControl"
									(changed)="updateControlForm($event, 'layout')" />
								<label class="yuno-form">
									<span class="select-none">Paint Properties</span>
								</label>
								<yuno-code-editor
									[readOnly]="readonly"
									[control]="paintControl"
									(changed)="updateControlForm($event, 'paint')" />
							</ng-container>
						}
						@if (service.form.value.type === 'data-threejs-model') {
							<label class="yuno-form">
								<span class="select-none">Coordinates</span>
							</label>
							<section formArrayName="coordinates" class="grid grid-cols-3 gap-4">
								@for (
									coord of service.coordinates.value;
									track coord;
									let i = $index
								) {
									<yuno-forms-number [step]="0.001" [formControlName]="i">
									</yuno-forms-number>
								}
							</section>
						}
						@if (service.form.value.type === 'data-threejs-pointcloud') {
							<section class="mb-8 grid grid-cols-3 gap-4">
								<yuno-forms-text
									class="col-span-2"
									formControlName="fileName"
									label="File name"
									placeholder="Defaults to Potree >>> cloud.js" />
								<yuno-forms-number
									class="col-span-1"
									label="Point count"
									formControlName="pointcount">
								</yuno-forms-number>
							</section>
							<label class="yuno-form">
								<span class="select-none">Coordinates</span>
							</label>
							<section formArrayName="coordinates" class="grid grid-cols-3 gap-4">
								@for (
									coord of service.coordinates.value;
									track coord;
									let i = $index
								) {
									<yuno-forms-number [step]="0.001" [formControlName]="i">
									</yuno-forms-number>
								}
							</section>
						}
					</ng-container>
				</form>
			}
		</div>
	</yuno-edit-container>
}
