<yuno-admin-navbar-buttons-container>
	<button yuno-admin-button color="success" (click)="create()">Save</button>
</yuno-admin-navbar-buttons-container>
<div class="flex flex-col gap-8">
	@if (data$ | async) {
		<yuno-forms-title>Create a new Application</yuno-forms-title>
		@if (service.form; as form) {
			<form [formGroup]="form" class="flex flex-col gap-12">
				<!--	Application		-->
				<section class="flex gap-4">
					<yuno-forms-text
						class="flex-1"
						formControlName="id"
						placeholder="Application Title"
						label="Application Title">
						@if (service.form.get('id')?.errors?.['required']) {
							<span> give the application a title! </span>
						}
						@if (service.form.get('id')?.errors?.['minlength']) {
							<span>
								the application should be atleast:
								{{ service.form.get('id')?.errors?.['minlength'].requiredLength }}
								characters long!
							</span>
						}
					</yuno-forms-text>
					<yuno-forms-select
						formControlName="language"
						placeholder="Choose Language"
						[selectValues]="['nl', 'de', 'en', 'fr', 'es', 'it', 'da']"
						[display]="[
							'Nederlands',
							'Duits',
							'Engels',
							'Frans',
							'Spaans',
							'Italiaans',
							'Deens'
						]"
						label="Language" />
				</section>

				<!--	URL		-->
				<section class="flex flex-col gap-4">
					<yuno-forms-title>Application urls</yuno-forms-title>
					<yuno-forms-span class="-mt-5"
						>the first will also be the foldername
					</yuno-forms-span>
					<yuno-forms-array-container
						[control]="service.urls"
						formArrayName="url"
						cdkDropList
						(cdkDropListDropped)="drop($event)">
						<div arrayBody class="grid grid-cols-1 gap-2">
							@for (url of service.urls.controls; track url; let i = $index) {
								<yuno-admin-drag-row
									[keepButtons]="true"
									cdkDrag
									cdkDragLockAxis="y">
									<div class="flex-1 select-none" title>
										<yuno-forms-text
											[formControlName]="i"
											placeholder="Application Url">
											@if (
												service.urls.controls[i].errors?.['minlength'] ||
												service.urls.controls[i].errors?.['required']
											) {
												<span> please provide at least 3 characters </span>
											}

											@if (
												service.urls.controls[i].errors?.[
													'noSpacesLowerCase'
												]?.['whitespace']
											) {
												<span> whitespaces are not allowed! </span>
											}

											@if (
												service.urls.controls[i].errors?.[
													'noSpacesLowerCase'
												]?.['uppercase']
											) {
												<span> only lowercase is allowed </span>
											}
										</yuno-forms-text>
									</div>
									<ng-container buttons>
										<button
											yuno-admin-button-table
											(click)="onRemove(i)"
											color="danger">
											delete
										</button>
									</ng-container>
								</yuno-admin-drag-row>
							}
						</div>
						<div arrayErrors>
							@if (!service.urls.valid && !service.urls.errors) {
								<span> one of the urls is not valid! </span>
							}
							@if (service.urls.errors?.['minlength']) {
								<span> please provide at least one url! </span>
							}
						</div>
					</yuno-forms-array-container>
					<div class="flex items-center gap-4">
						<button yuno-admin-button color="primary" [add]="true" (click)="onAdd()">
							Add Url
						</button>
					</div>
				</section>

				<!--	Client		-->
				<section class="flex flex-col gap-2">
					<yuno-forms-title>Select a Client</yuno-forms-title>
					<yuno-forms-select
						formControlName="client"
						placeholder="Choose Client..."
						[selectValues]="service.clientsSelect"
						[display]="service.clientsDisplay">
						@if (service.form.get('client')?.errors?.['required']) {
							<span> please select a client! </span>
						}
					</yuno-forms-select>
				</section>

				<!--	Options		-->
				<section class="flex flex-col gap-2">
					<yuno-forms-title>Additional options</yuno-forms-title>
					<yuno-forms-toggle formControlName="blank" label="Blank Atlas" />
				</section>

				<!--	Location		-->
				<yuno-forms-title class="-mb-6">Select a Location</yuno-forms-title>
				<section class="grid grid-cols-2 gap-4">
					<div class="flex flex-col gap-6">
						<section>
							<label class="yuno-form">
								<span class="select-none">Center Point</span>
								<div class="flex flex-row gap-4" formArrayName="coordinates">
									<yuno-forms-number [step]="0.001" [formControlName]="0" />
									<yuno-forms-number [step]="0.001" [formControlName]="1" />
								</div>
							</label>
						</section>
						<section class="mt-6 flex flex-col items-start justify-start">
							<h4>Extend</h4>
							<div formArrayName="extend" class="flex flex-col gap-2">
								<yuno-forms-span class="flex-1 self-end text-right font-semibold">
									top right
								</yuno-forms-span>
								<div [formArrayName]="1" class="flex items-center justify-center">
									<div class="flex flex-row gap-4">
										<yuno-forms-number
											class="w-full"
											[formControlName]="0"
											[step]="0.01" />
										<yuno-forms-number
											class="w-full"
											[formControlName]="1"
											[step]="0.01" />
									</div>
								</div>
								<yuno-forms-span class="col-span-3 mt-4 font-semibold">
									bottom left
								</yuno-forms-span>
								<div [formArrayName]="0" class="flex items-center justify-center">
									<div class="flex flex-row gap-4">
										<yuno-forms-number
											class="w-full"
											[formControlName]="0"
											[step]="0.01" />
										<yuno-forms-number
											class="w-full"
											[formControlName]="1"
											[step]="0.01" />
									</div>
								</div>
							</div>
						</section>
					</div>

					<div class="h-96 overflow-hidden rounded-md">
						<yuno-admin-config-map
							(pointer)="service.onUpdateCoordinates($event)"
							(polygon)="service.onUpdateExtend($event)"
							[extend]="service.extend.value"
							[coordinates]="service.coordinates.value" />
					</div>
				</section>
			</form>
		}
	}
</div>
