import { ChangeDetectionStrategy, Component, effect, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { YunoAdminButtonComponent } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import {
	YunoFormsCheckboxTemplateComponent,
	YunoFormsSelectTemplateComponent,
	YunoFormsSpanComponent,
	YunoFormsTextTemplateComponent
} from '@yuno/angular/forms/components';

import { UrlGeneratorService } from './url-generator.service';

@Component({
	selector: 'yuno-url-generator',
	imports: [
		YunoFormsSpanComponent,
		YunoFormsCheckboxTemplateComponent,
		YunoFormsSelectTemplateComponent,
		YunoFormsTextTemplateComponent,
		FormsModule,
		YunoAdminButtonComponent,
		YunoFormsSelectTemplateComponent,
		YunoFormsCheckboxTemplateComponent,
		YunoFormsTextTemplateComponent
	],
	template: `
		<div class="grid grid-cols-1 gap-4">
			<div class="flex flex-col items-end gap-2">
				<div
					class="w-full max-w-full overflow-auto break-all rounded-md border-2 border-yuno-blue-gray-900 bg-yuno-blue-gray-800 p-4 text-xs text-yuno-blue-gray-400">
					<code>
						@if (service.embedCode) {
							{{ service.iframe.trim() }}
						} @else {
							{{ service.url.trim() }}
						}
					</code>
				</div>
				<div class="flex w-full justify-between">
					<div class="flex flex-row gap-2">
						<yuno-forms-template-checkbox
							label="Use iframe Code"
							[(ngModel)]="service.embedCode" />
						<yuno-forms-template-checkbox
							label="Use Embed Settings"
							[(ngModel)]="service.embedTrue"
							(ngModelChange)="updateUrl()" />
					</div>
					<button yuno-admin-button color="secondary" (click)="service.copyToClipboard()">
						copy to clipboard
					</button>
				</div>
			</div>

			@if (service.embedCode) {
				<yuno-forms-text-template
					label="Title"
					[(ngModel)]="service.appName"
					(ngModelChange)="updateUrl()"></yuno-forms-text-template>
			}

			@if ($variants()) {
				<yuno-forms-template-select
					label="Select Variant"
					[(ngModel)]="service.variant"
					[selectValues]="service.variants"
					[display]="service.variants"
					(ngModelChange)="service.selectVariant($event); updateUrl()" />
			}

			@if ($variantRoutes(); as variantRoutes) {
				<yuno-forms-template-select
					label="Variant Route"
					[(ngModel)]="service.variantRoute"
					[selectValues]="variantRoutes"
					[display]="variantRoutes"
					(ngModelChange)="updateUrl()" />
			}
			<yuno-forms-span class="mt-8 font-semibold ">Additional Options</yuno-forms-span>
			<div class="-mt-2 flex flex-col items-end justify-end">
				<yuno-forms-template-select
					class="w-full"
					label="Referrer Policy"
					[(ngModel)]="service.noReferer"
					[display]="service.noReferrerDisplay"
					[selectValues]="service.noReferrerValues"
					(ngModelChange)="updateUrl()" />
				<a
					class="text-sm underline"
					target="_blank"
					href="https://developer.mozilla.org/en-US/docs/Web/API/HTMLIFrameElement/referrerPolicy">
					What does this do?
				</a>
			</div>
			<div class="flex flex-row gap-8">
				<yuno-forms-template-checkbox
					label="Lazy Loading"
					[(ngModel)]="service.lazyLoading"
					(ngModelChange)="updateUrl()" />
				<yuno-forms-template-checkbox
					label="Allow Fullscreen"
					[(ngModel)]="service.allowfullscreen"
					(ngModelChange)="updateUrl()" />
				<yuno-forms-template-checkbox
					label="Use Embed Settings"
					[(ngModel)]="service.embedTrue"
					(ngModelChange)="updateUrl()" />
			</div>
		</div>
	`,
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UrlGeneratorComponent extends AppDataComponent {
	readonly service = inject(UrlGeneratorService);

	$variants = this.service.$variants;
	$variantRoutes = this.service.$variantRoutes;

	constructor() {
		super();
		effect(
			() => {
				const selected = this.$variants();
				this.service.variants = selected.map(v => v.id);
				this.service.selectVariant(this.service.variant);
			},
			{ allowSignalWrites: true }
		);

		this.service.clientUrl = this.clientUrl;
		this.service.appName = this.appName;
		this.service.appUrl = this.appUrl;
	}

	updateUrl(): void {
		this.service.updateUrl();
	}
}
