import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	Input,
	forwardRef,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { debounceTime } from 'rxjs';

import { YunoFormsLabelRequired } from '../../pipes/label-required/label-required.pipe';
import {
	YunoFormsDefaultComponent,
	YunoFormsDefaultTemplateComponent
} from '../_default/default.component';
import { YunoFormsValidatorMessagesComponent } from '../_validator-messages/validator-messages.component';

@Component({
	selector: 'yuno-forms-number',
	template: `
		<label class="yuno-form">
			@if (label) {
				<span class="select-none" [innerHtml]="label | labelRequired: required"> </span>
			}
			<input
				class="truncate"
				type="number"
				[step]="step || undefined"
				[min]="min || null"
				[max]="max || null"
				[id]="ngControl.name"
				[name]="ngControl.name"
				[placeholder]="placeholder"
				[value]="convert ? sizeConverted : getFormControl().value"
				(input)="convertEvent($event.target)" />

			<yuno-validator-messages [control]="ngControl.control">
				<ng-content></ng-content>
			</yuno-validator-messages>
		</label>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		YunoFormsValidatorMessagesComponent,
		YunoFormsLabelRequired
	]
})
export class YunoFormsNumberComponent extends YunoFormsDefaultComponent implements AfterViewInit {
	@Input() step: number;
	@Input() min: number;
	@Input() max: number;

	formValue: string;
	@Input() convert = false;
	@Input() convertValue = 1;

	private readonly destroyRef = inject(DestroyRef);

	get sizeConverted(): number {
		return this.getFormControl().value * this.convertValue;
	}

	set sizeConverted(value: number | string) {
		const pxValue = Number(value);
		if (!isNaN(pxValue)) {
			this.getFormControl().setValue(Number(pxValue / this.convertValue));
		}
	}

	ngAfterViewInit(): void {
		this.onChanges();
	}

	onChanges(): void {
		this.getFormControl()
			.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.getFormControl().markAsDirty();
			});

		this.getFormControl()
			.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(200))
			.subscribe(() => {
				this.sizeConverted;
				this.cdr.markForCheck();
			});
	}

	convertEvent(target: EventTarget | null) {
		const value = (target as HTMLInputElement).value;
		if (!value) {
			return;
		}

		if (this.convert) {
			this.sizeConverted = value;
			return;
		}

		this.getFormControl().setValue(Number(value));
	}
}

@Component({
	selector: 'yuno-forms-template-number',
	template: `
		<label class="yuno-form">
			@if (label) {
				<span class="select-none" [innerHtml]="label | labelRequired: required"> </span>
			}
			<input
				class="truncate"
				type="number"
				[step]="step || undefined"
				[min]="min || null"
				[max]="max || null"
				[ngModel]="value"
				(ngModelChange)="valueChange($event)"
				[placeholder]="placeholder"
				[required]="required" />
		</label>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [FormsModule, YunoFormsLabelRequired],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => YunoFormsNumberTemplateComponent),
			multi: true
		}
	]
})
export class YunoFormsNumberTemplateComponent extends YunoFormsDefaultTemplateComponent {
	@Input() step: number;
	@Input() min: number;
	@Input() max: number;
}
