<router-outlet />

@if (toggleTemplates) {
	<yuno-admin-templates
		[selector]="'participate'"
		(bgClicked)="toggleTemplates = false"
		(categoryTemplate)="onCreate($event)"></yuno-admin-templates>
}

<yuno-card
	[noPadding]="true"
	[transparent]="true"
	[tabAccess]="minimalRole(roles.SUPERADMIN) && templateApp"
	[tabs]="['Editor', 'Templates']"
	(outputTab)="tabValue = $event.key">
	<div content class="mb-8 flex flex-col gap-8">
		@if (tabValue === 'Editor') {
			@if (data$ | async; as data) {
				<yuno-admin-table
					[selectable]="false"
					[draggable]="false"
					[filterable]="true"
					[sortable]="false"
					[pagination]="true"
					[pageOptions]="{
						pageSize: 100,
						pageSizeOptions: [5, 10, 25, 100],
						hidePageSize: true
					}"
					[data]="getData(data.list)"
					[buttons]="tableButtons"
					[columns]="[
						{
							key: 'id',
							label: 'Participation'
						}
					]"
					(clicked)="onView($event)">
					@if (xkpMember && minimalAppRole(userRoles.ADMIN)) {
						<button yuno-admin-button (click)="onSelectTemplate(true)" [add]="true">
							New Category
						</button>
					}
				</yuno-admin-table>
			}
		}
		@if (tabValue === 'Templates') {
			<yuno-admin-participate-template-view></yuno-admin-participate-template-view>
		}
	</div>
</yuno-card>

<ng-template #tableButtons let-row>
	<button yuno-admin-view-button (click)="onView(row)"></button>
	<button yuno-admin-duplicate-button (click)="onDuplicate(row)"></button>
	<button yuno-admin-edit-button (click)="onEdit(row)"></button>
	<button yuno-admin-delete-admin-button (click)="onDelete(row)"></button>
</ng-template>
