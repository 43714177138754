import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { combineLatest } from 'rxjs';

import {
	YunoFormsColorComponent,
	YunoFormsNumberComponent,
	YunoFormsSelectComponent,
	YunoFormsSpanComponent
} from '@yuno/angular/forms/components';
import { AngularSvgLoaderModule } from '@yuno/angular/svg-loader';
import { SVGItemModel } from '@yuno/api/interface';

import { ContentForm, LegendEditorService, legendList } from '../legend-editor.service';

@Component({
	selector: 'yuno-admin-legend-icon-editor',
	imports: [
		CommonModule,
		ReactiveFormsModule,
		AngularSvgLoaderModule,
		YunoFormsColorComponent,
		YunoFormsNumberComponent,
		YunoFormsSelectComponent,
		YunoFormsSpanComponent
	],
	templateUrl: './legend-icon-editor.component.html',
	styleUrls: ['./legend-icon-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegendIconEditorComponent {
	private _form: FormGroup<ContentForm> | FormGroup<legendList>;

	selectedIcon: SVGItemModel;
	frequentIcons: SVGItemModel[];

	data$ = combineLatest({
		icons: this.service
			.get<SVGItemModel[]>('svg-item/type/legend')
			.then((data: SVGItemModel[]) => {
				this.frequentIcons = data.filter(
					item => item.id === 'fill' || item.id === 'line' || item.id === 'circle'
				);
				this.service.iconValues = data.map(icon => icon.id) as string[];
				this.service.iconDisplay = data.map(icon => icon.id) as string[];
				return data;
			})
	});

	@Input() set form(content: FormGroup<ContentForm> | FormGroup<legendList>) {
		this._form = content;
	}

	get form(): FormGroup<ContentForm> | FormGroup<legendList> {
		return this._form;
	}

	constructor(public service: LegendEditorService) {}

	setIcon(icon: string) {
		if (this._form.controls['class']) {
			this._form.controls['class'].setValue(icon);
			this._form.controls['class']?.markAsDirty();
		}
	}

	getIconUrl(icons: SVGItemModel[], selection: SVGItemModel['id']): string {
		const index = icons.findIndex(obj => obj.id === selection);
		return icons[index].url;
	}
}
