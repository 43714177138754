import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { ParticipationModel, ParticipationPages } from '@yuno/api/interface';

import { modelsActions } from './models.actions';
import { modelsFeature } from './models.state';

@Injectable()
export class ParticipationModelFacade {
	private readonly store = inject(Store);

	data$ = this.store.pipe(select(modelsFeature.selectData));
	selected$ = this.store.pipe(select(modelsFeature.selectSelected));

	get(): void {
		this.store.dispatch(modelsActions.load());
	}

	createNew(): void {
		this.store.dispatch(modelsActions.create());
	}

	createFromTemplate(_id: string): void {
		this.store.dispatch(modelsActions.createTemplate({ _id }));
	}

	select(id: string): void {
		this.store.dispatch(modelsActions.select({ _id: id }));
	}

	restoreOriginal(): void {
		this.store.dispatch(modelsActions.restoreOriginal());
	}

	updateSelected(data: ParticipationModel): void {
		this.store.dispatch(modelsActions.updateSelect({ data }));
	}

	duplicate(id: string): void {
		this.store.dispatch(modelsActions.duplicate({ _id: id }));
	}

	delete(id: string): void {
		this.store.dispatch(modelsActions.delete({ _id: id }));
	}

	save(): void {
		this.store.dispatch(modelsActions.save());
	}

	clearSelected(): void {
		this.store.dispatch(modelsActions.clearSelect());
	}

	// Customs
	changesSelectedModelPagesOrder(
		data: ParticipationPages[],
		previousIndex: number,
		currentIndex: number
	): void {
		this.store.dispatch(
			modelsActions.changesSelectedModelPagesOrder({
				data,
				previousIndex,
				currentIndex
			})
		);
	}

	removeSelectedModelPage(index: number): void {
		this.store.dispatch(
			modelsActions.removeSelectedModelPage({
				index
			})
		);
	}

	reset(): void {
		this.store.dispatch(modelsActions.reset());
	}
}
