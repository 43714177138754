@if (data$ | async; as data) {
	<yuno-edit-container [popup]="isPopup" [col]="4" [pos]="1" [span]="4">
		<ng-container buttons>
			<button yuno-admin-save-button (click)="onSave()" [disabled]="!$isChanged()"></button>
			<button yuno-admin-close-button (click)="onClose()"></button>
		</ng-container>

		<div class="flex flex-col gap-2 md:grid md:h-full md:grid-cols-2">
			<div edit-container-content>
				@if (service.form) {
					<form [formGroup]="service.form">
						@if (!minimalAppRole(userRoles.EDITOR)) {
							<yuno-user-rights-message />
						}
						<div class="flex flex-col gap-8">
							<yuno-forms-text
								class="flex-1"
								formControlName="id"
								label="Fence name"
								placeholder="fence name" />
							<section class="grid grid-cols-2 gap-4">
								<yuno-forms-number
									formControlName="minZoom"
									placeholder="1"
									label="Min Zoom" />
								<yuno-forms-number
									formControlName="maxZoom"
									placeholder="22"
									label="Max Zoom" />

								<yuno-forms-number
									formControlName="bearing"
									placeholder="0"
									label="Bearing"
									[min]="-360"
									[max]="360"
									[step]="1" />
								<yuno-forms-number
									formControlName="pitch"
									placeholder="0"
									label="Pitch"
									[min]="0"
									[max]="60"
									[step]="1" />
							</section>
						</div>
						<yuno-forms-divider />
						<section formGroupName="style" class="grid grid-cols-2 gap-4">
							<yuno-forms-color
								formControlName="color"
								placeholder="#3bb2d0"
								label="Color" />
							<yuno-forms-number
								formControlName="opacity"
								placeholder="0,1"
								[step]="0.1"
								[min]="0"
								[max]="1"
								label="Opacity" />
						</section>
						<yuno-forms-divider />
						<ng-container>
							<h4>Fence</h4>
							<yuno-forms-code-editor
								label="Polygon"
								language="json"
								[height]="420"
								[codeValue]="service.polygonValue"
								(codeValueChange)="polyChanges($event)" />
							<yuno-admin-annotation class="mt-2">
								<div class="flex flex-col">
									<p>
										A valid polygon structure is defined as follows:
										<br />
										[ [ lng, lat ], [ lng , lat ] ]
									</p>
									<p>
										However, in certain cases, polygons exported by QGIS may
										have an additional layer of brackets:
										<br />[ [ [ lng, lat ], [ lng, lat ] ] ]
									</p>
									<p>
										Simply remove the outer brackets, ensuring compatibility
										within the Yuno framework.
									</p>
								</div>
							</yuno-admin-annotation>
						</ng-container>
					</form>
				}
			</div>
			<div edit-container-content>
				<yuno-admin-fence-map />
			</div>
		</div>
	</yuno-edit-container>
}
