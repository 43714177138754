import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	OnDestroy,
	OnInit,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { combineLatest, debounceTime, filter, take, tap } from 'rxjs';

import { FormStartingService } from '@yuno/admin/core';
import { AppFacade } from '@yuno/admin/features/apps';
import { StatesFacade } from '@yuno/admin/features/states/data-access';
import {
	EditContainerContentDirective,
	UserRightsMessageComponent,
	YunoAdminCloseButtonComponent,
	YunoAdminSaveButtonComponent,
	YunoEditContainerModule
} from '@yuno/admin/ui';
import { AppDataComponent, redirectTo } from '@yuno/admin/utils';
import { isFormValid } from '@yuno/angular/forms';
import { YunoFormsTextComponent } from '@yuno/angular/forms/components';
import { MessageService } from '@yuno/angular/notifications';
import { LanguageAll, State } from '@yuno/api/interface';

import { StateEditorService } from './state-editor.service';

@Component({
	imports: [
		ReactiveFormsModule,
		YunoEditContainerModule,
		YunoFormsTextComponent,
		UserRightsMessageComponent,
		AsyncPipe,
		EditContainerContentDirective,
		YunoAdminCloseButtonComponent,
		YunoAdminSaveButtonComponent
	],
	providers: [FormStartingService],
	selector: 'yuno-admin-state-editor',
	templateUrl: './state-editor.component.html',
	styleUrls: ['./state-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class StateEditorComponent extends AppDataComponent implements OnInit, OnDestroy {
	private readonly stateFacade = inject(StatesFacade);
	private readonly message = inject(MessageService);
	private readonly appFacade = inject(AppFacade);
	private readonly destroyRef = inject(DestroyRef);

	readonly service = inject(StateEditorService);

	private readonly formService = inject(FormStartingService);
	$isChanged = this.formService.$isChanged;

	readonly = !this.minimalAppRole(this.userRoles.EDITOR);
	languages = LanguageAll;

	originalData: Partial<State>;
	url: string;

	data$ = combineLatest({
		state: this.stateFacade.selectedState$.pipe(
			filter(() => !this.originalData),
			tap(data => {
				this.formService.startup(
					this.service.form,
					data,
					this.originalData,
					this.route,
					this.router,
					async () => {
						if (data) {
							this.originalData = data;
						}
					}
				);
			})
		),
		language: this.appFacade.language$
	});

	ngOnInit(): void {
		this.service.createFormGroup();
		this.onChanges();

		/* 	Detects current url and select dataset from url	*/
		this.route.paramMap.pipe(take(1)).subscribe(data => {
			const id = data.get('id');
			this.stateFacade.select(id);
		});

		this.languageSelector();
	}

	onChanges(): void {
		this.service.form.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500))
			.subscribe(() => {
				this.stateFacade.updateSelect(this.service.form.getRawValue() as Partial<State>);
			});
	}

	onSave() {
		const valid = isFormValid(this.service.form);
		if (!valid) {
			this.message.sendToast(
				'Some fields have errors. Please check and fix them.',
				'error',
				5
			);
			return;
		}
		this.stateFacade.save();
	}

	/* 	navigates back to the state page */
	onClose() {
		redirectTo(this.route, this.router);
		this.stateFacade.clearSelect();
	}

	ngOnDestroy() {
		this.stateFacade.clearSelect();
		this.languageService.destroyLanguageSelector();
	}
}
