<ng-container *ngIf="service.data$ | async as data">
	<yuno-admin-events-forms
		*ngIf="data.toggle"
		[group]="service.form"
		[array]="service.events"
		[arrayName]="'events'">
	</yuno-admin-events-forms>
	<yuno-edit-container [col]="4" [pos]="3" [span]="2">
		<ng-container buttons>
			<button
				yuno-admin-save-button
				(click)="service.onSave()"
				[disabled]="!$isChanged()"></button>
			<button
				yuno-admin-close-button
				(click)="redirectTo(route, router); service.onClose()"></button>
		</ng-container>
		<div edit-container-content>
			@if (service.form) {
				<form [formGroup]="service.form">
					<div class="flex flex-col gap-8">
						<div class="flex flex-col gap-2">
							<yuno-forms-text
								formControlName="id"
								label="ID"
								placeholder="map-ui-button-name">
								Use only lowercase letters. Do not use spaces, diacritics (such as
								'ä', 'ë'), or special characters, except for '-' and '_'.
							</yuno-forms-text>
						</div>
						<div class="flex flex-row flex-wrap items-start justify-start">
							@for (icon of service.iconMapButtonValues; track icon) {
								<ng-container
									[ngTemplateOutlet]="iconTemplate"
									[ngTemplateOutletContext]="{ id: icon }">
								</ng-container>
							}
						</div>

						<div class="flex flex-row items-center gap-4">
							<yuno-forms-select
								label="Desktop"
								formControlName="desktop"
								[selectValues]="service.locMapButtonValues"
								[display]="service.locMapButtonDisplay"></yuno-forms-select>
							<yuno-forms-select
								label="Mobile"
								formControlName="mobile"
								[selectValues]="service.locMapButtonValues"
								[display]="service.locMapButtonDisplay"></yuno-forms-select>
						</div>
						<button yuno-admin-button (click)="service.onToggleEvents()">
							Events ({{ service.events.length || 0 }})
						</button>
					</div>
				</form>
			}
		</div>
	</yuno-edit-container>

	<ng-template #iconTemplate let-id="id">
		<div
			[ngClass]="{
				'border-solid bg-yuno-blue-hover': id === service.form.value.icon,
				'border-dashed': id !== service.form.value.icon
			}"
			class="border-1 pointer-events-auto m-1 flex h-14 w-14 cursor-pointer flex-col items-center justify-center rounded border border-gray-300 p-2 transition-colors hover:bg-yuno-blue-hover"
			(click)="setIcon(id)">
			<svg
				viewBox="0 0 24 24"
				fill="none"
				class="h-6 w-6"
				stroke-width="1.5"
				stroke="currentColor">
				@switch (id) {
					@default {
						<use href="#ui-plane" />
					}
					@case ('plane') {
						<use href="#ui-plane" />
					}
					@case ('rocket') {
						<use href="#ui-rocket" />
					}
					@case ('globe') {
						<use href="#ui-globe" />
					}
					@case ('search') {
						<use href="#ui-search" />
					}
					@case ('three-dots') {
						<use href="#ui-dots" />
					}
					@case ('marker') {
						<use href="#ui-marker" />
					}
					@case ('info') {
						<use href="#ui-info" />
					}
					@case ('question') {
						<use href="#ui-question" />
					}
					@case ('summary') {
						<use href="#ui-summary" />
					}
					@case ('links') {
						<use href="#ui-links" />
					}
					@case ('documents') {
						<use href="#ui-documents" />
					}
					@case ('notification') {
						<use href="#ui-notification" />
					}
					@case ('settings') {
						<use href="#ui-settings" />
					}
					@case ('photo') {
						<use href="#ui-photo" />
					}
					@case ('image') {
						<use href="#ui-image" />
					}
					@case ('layers') {
						<use href="#ui-layers" />
					}
					@case ('book') {
						<use href="#ui-book" />
					}
					@case ('talk') {
						<use href="#ui-talk" />
					}
				}
			</svg>
		</div>
	</ng-template>
</ng-container>
