import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostListener,
	Input,
	Output,
	TemplateRef,
	TrackByFunction,
	ViewChild,
	inject
} from '@angular/core';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTable, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { cloneDeep } from 'lodash';

import {
	AdminSearchBarComponent,
	CustomSortingFunction,
	TableCopyPasteEvent,
	TableDataInput,
	TableRow
} from '@yuno/admin/ui';
import { AngularPipesModule } from '@yuno/angular/pipes';

import {
	TableButtonOutput,
	TableChanged,
	TableColumnDisplay,
	TablePageOptions,
	TableSortData
} from './types';

export interface TableSelectionOutputNew {
	selection: TableRow[];
	row: TableRow;
	checked: boolean;
}

@Component({
	selector: 'yuno-admin-table',
	imports: [
		CommonModule,
		AdminSearchBarComponent,
		MatTableModule,
		MatSortModule,
		MatPaginatorModule,
		MatSelectModule,
		MatCheckboxModule,
		DragDropModule,
		OverlayModule,
		AngularPipesModule
	],
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoAdminTableComponent implements AfterViewInit {
	private readonly cdr = inject(ChangeDetectorRef);

	@ViewChild('table') table: MatTable<TableRow[]>;
	@ViewChild(MatSort) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild('buttonCell', { read: TemplateRef, static: false })
	buttonCell: TemplateRef<unknown>;

	private _columns: TableColumnDisplay[] = [{ key: 'id' }, { key: 'name' }];
	private _data: TableRow[] = [];
	private _selectData: TableRow[] = [];
	private _activeRow: TableRow;
	private _customSortEnabled = false;
	private _customSorting: CustomSortingFunction;

	resizeTimeOut: number;
	dataSource = new MatTableDataSource(this.data);
	displayedColumns: string[];
	selection = new SelectionModel<TableRow>(true, []);
	hover = -1;
	btnHover = false;

	mobileActiveButtons: unknown | null = null;

	@Input() itemKey = '_id';
	@Input() defaultColumn = 'id';
	@Input() image = false;
	@Input() fixedLayout = false;
	@Input() tableHeader = true;

	@Input() language = 'nl';
	@Input() draggable = false;
	@Input() filterable = false;
	@Input() selectable = false;
	@Input() sortable = false;
	@Input() pagination = false;
	@Input() childColumns: TableColumnDisplay[] = [{ key: 'id' }, { key: 'name' }];
	@Input() pageOptions: TablePageOptions = {
		pageSizeOptions: [5, 10, 25, 100],
		pageSize: 10,
		hidePageSize: false
	};

	/* eslint-disable @typescript-eslint/no-explicit-any */
	@Input() buttons: TemplateRef<any>;

	// A template to show before the mobile ellipsis button
	/* eslint-disable @typescript-eslint/no-explicit-any */
	@Input() preMobileButtons: TemplateRef<any>;
	/**
	 * The width of the buttons column, defined in REM
	 */
	@Input() buttonsWidth = 13.75;
	@Input() buttonEllipsis = false;
	@Input() disableAutoCloseButtons = false;

	/* eslint-disable @typescript-eslint/no-explicit-any */
	@Input() customTemplate: TemplateRef<any>;

	@Input()
	/**
	 * The columns that will be displayed in the table
	 * When using sticky | stickyEnd we also need to define the width in REM
	 *
	 * Width/MaxWidth are defined in REM
	 */
	set columns(value: TableColumnDisplay[]) {
		this._columns = value;
		this.setDisplayedColumns(value);
	}

	get columns(): TableColumnDisplay[] {
		return this._columns;
	}

	@Input() set customSorting(sorter: CustomSortingFunction) {
		if (!sorter) {
			return;
		}

		this._customSortEnabled = true;
		this._customSorting = sorter;
		this.setCustomSorting();
	}

	get customSorting(): CustomSortingFunction {
		return this._customSorting;
	}

	@Input()
	set data(value: TableDataInput) {
		if (!value) {
			return;
		}

		if (!Array.isArray(value)) {
			console.warn('Table data is not a array!');
			return;
		}

		this._data = value;
		this.dataSource = new MatTableDataSource(value);
		this._customSortEnabled && this.setCustomSorting();

		value.length >= 1 && this.setTable();

		this.selectDataParse();
	}

	get data(): TableRow[] {
		return this._data;
	}

	@Input()
	set selectData(value: TableRow[] | null) {
		if (!value) {
			return;
		}

		if (value && value.length >= 1) {
			this._selectData = value;
			this.selectDataParse();
		}
	}

	get selectData(): TableRow[] {
		return this._selectData;
	}

	@Input()
	set activeRow(value: TableRow | null) {
		if (!value) {
			return;
		}
		this._activeRow = value;
	}

	get activeRow(): TableRow {
		return this._activeRow;
	}

	getIsMobile(): boolean {
		return window.innerWidth < 768;
	}

	@Output() sorted = new EventEmitter<TableSortData>();
	@Output() clicked = new EventEmitter<TableRow>();
	@Output() changed = new EventEmitter<TableChanged>();
	@Output() masterToggled = new EventEmitter<boolean>();
	@Output() outputFilter = new EventEmitter<string>();

	@Output() dropped = new EventEmitter<{ data: TableRow; index: number }>();
	@Output() selected = new EventEmitter<TableSelectionOutputNew>();
	@Output() selectAll = new EventEmitter<TableSelectionOutputNew>();
	@Output() outputBtn = new EventEmitter<TableButtonOutput>();
	@Output() eventBtn = new EventEmitter<TableButtonOutput>();
	@Output() eventPaste = new EventEmitter<TableCopyPasteEvent>();

	@HostListener('window:resize', ['$event'])
	onResize(): void {
		clearTimeout(this.resizeTimeOut);
		this.resizeTimeOut = window.setTimeout(() => {
			this.setDisplayedColumns(this.columns);
		}, 100);
	}

	setDisplayedColumns(value: TableColumnDisplay[]): void {
		const mobile = window.innerWidth < 768;

		const arr = value
			.filter(column => {
				if (!mobile) {
					return true;
				}
				return column.key === this.defaultColumn || column.mobile;
			})
			.map(col => {
				let val = col.key;
				if (col?.parent) {
					val = col.parent + '.' + col.key;
				}
				return val;
			});

		this.displayedColumns = this.selectable ? ['select', ...arr] : [...arr];
		if (this.buttons) {
			this.displayedColumns = [...this.displayedColumns, 'buttons'];
		}

		this.cdr.detectChanges();
	}

	trackByFunction: TrackByFunction<TableRow> = (index, component) => {
		return component[this.itemKey];
	};

	ngAfterViewInit(): void {
		this.setTable();
	}

	selectDataParse(): void {
		this.selection.clear();
		if (!this._selectData) {
			return;
		}

		for (const item of this._selectData) {
			const id = item[this.itemKey] as string;
			const index = this.dataSource.data.findIndex(x => x[this.itemKey] === id);
			index >= 0 && this.selection.select(this.dataSource.data[index]);
		}

		this.cdr.detectChanges();
	}

	setTable(): void {
		if (!this.customSorting) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			this.dataSource.sortingDataAccessor = (item: any, property: string) => {
				const col = this.columns.find(col => col.child && col.key.match(property));

				if (col) {
					const value = col.child as string;
					return item[property][value];
				}

				return this.getNestedValue(item, property);
			};

			this.dataSource.sort = this.sort;
		}

		this.pagination && (this.dataSource.paginator = this.paginator);
		this.onResize();

		this.table?.updateStickyColumnStyles();
		this.cdr.detectChanges();
	}

	/**
	 * Convert dotnotation to a string value
	 * @param obj
	 * @param path
	 */
	getNestedValue(obj: unknown, path: string): string | undefined {
		const keys = path.split('.'); // Split the path into parts
		let result: any = obj; // Start with the top-level object

		// Traverse the object using the path parts
		for (const key of keys) {
			// If the key doesn't exist, return undefined
			if (result === undefined) {
				return undefined;
			}
			result = result[key]; // Move to the next nested level
		}

		return JSON.stringify(result); // Return the final result
	}

	setCustomSorting(): void {
		this.dataSource.sortingDataAccessor = this.customSorting;
		this.dataSource.sort = this.sort;
	}

	getHeader(col: TableColumnDisplay): string {
		const string = col.label || col.key;
		if (string.length < 1) {
			return string;
		}

		return string[0].toUpperCase() + string.slice(1);
	}

	getDataCell(element: TableRow, col: TableColumnDisplay): string {
		const key = col['key'] as string;
		if (col?.parent) {
			const keyVal = element[col.parent] as TableRow;
			return keyVal[key] as string;
		}
		if (col?.child && element[key]) {
			const keyVal = element[key] as TableRow;
			return keyVal[col.child] as string;
		}
		return element[key] as string;
	}

	sortData(event: TableSortData): void {
		this.sorted.emit(event);
	}

	applyFilter(str: string): void {
		this.dataSource.filter = str.trim().toLowerCase();
		this.outputFilter.emit(this.dataSource.filter);
		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

	onCheckbox(data: TableRow, event: MouseEvent, key: string): void {
		event.stopPropagation();
		const index = this._data.findIndex(x => x === data);
		const checked = (event.target as HTMLInputElement).checked;
		this.changed.emit({ item: data, checked: checked, index, key });
	}

	onDropdown(data: TableRow, event: Event, element: string): void {
		const drop: TableRow = { ...data };
		const index = this._data.findIndex(x => x === data);
		drop[element] = event;
		this.dropped.emit({ data: drop, index });
	}

	onSelect(row: TableRow, event: MatCheckboxChange): void {
		// make sure the data is selected correctly
		// according to the table selected data
		if (event.checked) {
			this.selection.select(row);
		} else {
			this.selection.deselect(row);
		}

		// we will always return a copy of the selected data
		const selection = Object.assign([], this.selection.selected).filter(
			item => item !== undefined
		);

		this.selected.emit({
			selection,
			row,
			checked: event.checked
		});
	}

	onClick(row: TableRow): void {
		// When the button column is hovered we disable the main
		// click event
		if (!this.btnHover) {
			this.clicked.emit(row);
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	drop(event: CdkDragDrop<TableRow[], any, any>): void {
		const newData = event.container.data.slice();
		moveItemInArray(newData, event.previousIndex, event.currentIndex);
		this.dataSource.data = cloneDeep(newData);
	}

	onMasterToggle(event: MouseEvent): void {
		event.stopPropagation();
		const checked = (event.target as HTMLInputElement).checked;
		this.masterToggled.emit(checked);
	}

	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected(): boolean {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}

	checkSelectAll(str?: 'true' | 'indeterminate' | 'false'): boolean {
		return str === 'indeterminate';
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	onSelectAll(): void {
		if (this.isAllSelected()) {
			this.selection.clear();
			this.selectAll.emit({
				selection: this.selection.selected,
				row: {},
				checked: true
			});
			return;
		}

		this.selection.select(...this.dataSource.data);
		this.selectAll.emit({
			selection: this.selection.selected,
			row: {},
			checked: false
		});
	}

	buttonMouseMove(bool = true): void {
		this.btnHover = bool;
	}

	disableEllipsisButton(): void {
		this.mobileActiveButtons = null;
		this.btnHover = false;
	}

	closeButton(): void {
		this.mobileActiveButtons = null;
	}

	handleEllipsisClick() {
		if (this.disableAutoCloseButtons) {
			return;
		}
		this.mobileActiveButtons = null;
	}
}
