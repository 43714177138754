import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
	AbstractControl,
	FormControl,
	ReactiveFormsModule,
	UntypedFormControl
} from '@angular/forms';
import { tap } from 'rxjs';

import { HtmlEditorImageLibraryComponent } from '@yuno/admin/features/html-form';
import { AnnotationComponent, CodeEditorComponent } from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import {
	YunoFormsDividerComponent,
	YunoFormsSpanComponent,
	YunoFormsTextComponent,
	YunoFormsTitleComponent
} from '@yuno/angular/forms/components';

import { TextEditorsService } from '../text-editors.service';
import { RenderStringPipe } from './renderstring.pipe';

@Component({
	selector: 'yuno-admin-custom-text-editor',
	imports: [
		NgClass,
		AsyncPipe,
		ReactiveFormsModule,
		YunoFormsDividerComponent,
		YunoFormsTitleComponent,
		YunoFormsTextComponent,
		YunoFormsSpanComponent,
		CodeEditorComponent,
		HtmlEditorImageLibraryComponent,
		RenderStringPipe,
		AnnotationComponent
	],
	templateUrl: './custom-text-editor.component.html',
	styleUrls: ['./custom-text-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomTextEditorComponent extends AppDataComponent {
	readonly editService = inject(TextEditorsService);

	codeEditorType: 'visual' | 'code' | 'data' = 'code';

	customData = '{ "properties": {} }';
	customDataJson: unknown = JSON.parse(this.customData);

	contentControl: UntypedFormControl;
	dataControl: UntypedFormControl;

	service$ = this.editService.getActiveService().pipe(
		tap(service => {
			if (service) {
				const content = service.component?.get('content');
				const custom = service.component?.get('customData');
				this.contentControl = new UntypedFormControl(content?.value || '');
				this.dataControl = new UntypedFormControl(custom?.value || '{ "properties": {} }');
			}
		})
	);

	patchCodeContent(content: AbstractControl | null, contentData: string): void {
		const control = content as FormControl<string>;
		if (control) {
			control.setValue(contentData);
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	patchCustomData(customData: AbstractControl | null, event: any) {
		this.customDataJson = JSON.parse(event);
		const control = customData as FormControl<unknown>;
		if (control) {
			control.setValue(this.customDataJson);
		}
	}
}
