import { Injectable, inject, signal } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { io } from 'socket.io-client';

import { ENVIRONMENT } from '@yuno/admin/core';
import { MessageService } from '@yuno/angular/notifications';
import { CustomTilingLog, ProjectTilesets } from '@yuno/api/interface';

@Injectable({
	providedIn: 'root'
})
export class TilesetsSocketsService {
	private readonly environment = inject(ENVIRONMENT);
	private readonly message = inject(MessageService);
	private socket = io(this.environment['yuno-tileserver'] + '/custom-tileset');

	$tilesetData = signal<{ [key: string]: CustomTilingLog }>({});

	subscriptions: { [key: string]: Subscription } = {};

	// Method to join custom tilesets and listen for logs
	joinCustomTilesets(tilesets: ProjectTilesets['custom']): void {
		for (const customTileset of tilesets) {
			if (!customTileset.id || this.subscriptions[customTileset.id]) {
				return;
			}

			this.subscriptions[customTileset.id] = this.showLog(customTileset.id).subscribe(
				data => {
					if (data.id !== customTileset.id) {
						return;
					}

					if (data.step === 0 || data.step === 4) {
						this.subscriptions[customTileset.id as string].unsubscribe();
						delete this.subscriptions[customTileset.id as string];

						this.leaveRoom(data);
					}

					this.$tilesetData.update(value => {
						return {
							...value,
							[data.id]: data
						};
					});
				}
			);
		}
	}

	leaveRoom(data: CustomTilingLog): void {
		this.socket.emit('leaveRoom', data.id);

		if (data.step === 0) {
			this.message.sendToast(
				`<div class="text-base font-semibold">${data.title}</div><span class="text-sm">Failed tiling!</span>`,
				'error'
			);
		}

		if (data.step === 4) {
			this.message.sendToast(
				`<div class="text-base font-semibold">${data.title}</div><span class="text-sm">Succesfully tiled!</span>`,
				'success'
			);
		}

		setTimeout(() => {
			const tilesets = this.$tilesetData();
			delete tilesets[data.id];

			this.$tilesetData.set(JSON.parse(JSON.stringify(tilesets)));
		}, 2500);
	}

	// Method to show logs for a specific tileset ID
	showLog(tilesetId: string): Observable<CustomTilingLog> {
		return new Observable<CustomTilingLog>(observer => {
			// Emit an event to join the room on the server
			this.socket.emit('joinRoom', tilesetId);

			// Listen for logs from the server
			const logHandler = (data: { data: CustomTilingLog }) => {
				observer.next(data.data);
			};

			this.socket.on('custom-tileset-log', logHandler);

			// Cleanup on unsubscription
			return () => {
				// this.socket.off('custom-tileset-log', logHandler);
			};
		});
	}
}
