@if (data$ | async; as data) {
	@if (data.states) {
		<yuno-edit-container
			[popup]="isPopup"
			[col]="4"
			[pos]="1"
			[span]="4"
			[tabs]="tabs"
			(outputTab)="onSwitchTab($event.key)">
			<ng-container buttons>
				<button
					yuno-admin-save-button
					(click)="onSave()"
					[disabled]="!$isChanged()"></button>
				<button yuno-admin-close-button (click)="onClose()"></button>
			</ng-container>

			<div class="flex h-full flex-col gap-2">
				<div edit-container-content class="!h-auto" [hasTabs]="true">
					@if (service.form) {
						<form [formGroup]="service.form" class="grid grid-cols-2 gap-4">
							@if (!minimalAppRole(userRoles.EDITOR)) {
								<yuno-user-rights-message
									class="col-span-2"></yuno-user-rights-message>
							}
							<div class="col-span-1 h-full overflow-y-auto px-2">
								@if (tabValue === 'Settings') {
									<div class="flex flex-col gap-8">
										<yuno-forms-text
											class="flex-1"
											formControlName="id"
											label="Name"
											placeholder="Name of the Panorama"></yuno-forms-text>
										<section class="grid grid-cols-2 gap-4">
											<yuno-forms-toggle
												formControlName="public"
												label="Public"></yuno-forms-toggle>
											<yuno-forms-toggle
												formControlName="selected"
												label="Visible in internal version"></yuno-forms-toggle>
										</section>
										<yuno-forms-textarea
											class="flex-1"
											formControlName="alt"
											label="Alt Text"
											placeholder="Description of what you can see in the panorama"></yuno-forms-textarea>
									</div>
								}
								@if (tabValue === 'Map Options') {
									<ng-container formGroupName="map">
										<div class="flex flex-col gap-8">
											<yuno-forms-text
												formControlName="display"
												label="Display (hover text)"
												placeholder="Display name"></yuno-forms-text>
											<section class="mb-8 grid grid-cols-2 gap-4">
												<yuno-forms-select
													formControlName="icon"
													[selectValues]="service.iconValues"
													label="Icon"></yuno-forms-select>
												<yuno-forms-number
													label="Icon rotation"
													formControlName="rotation">
												</yuno-forms-number>
												<yuno-forms-number
													label="Min Zoom"
													formControlName="minZoom">
												</yuno-forms-number>
												<yuno-forms-number
													label="Max Zoom"
													formControlName="maxZoom">
												</yuno-forms-number>
												<label class="yuno-form col-span-2">
													<span class="select-none">Coordinates</span>
													<div
														class="grid grid-cols-2 gap-4"
														formArrayName="coordinates">
														@for (
															coord of service.mapCoords.controls;
															track coord;
															let i = $index
														) {
															<yuno-forms-number
																[step]="0.001"
																[formControlName]="i">
															</yuno-forms-number>
														}
													</div>
												</label>
											</section>
										</div>
									</ng-container>
								}
								@if (tabValue === 'Server Options') {
									<ng-container formGroupName="url">
										<section class="mb-8 grid grid-cols-2 gap-4">
											<yuno-forms-text
												formControlName="preview"
												label="Display"
												placeholder="display name"></yuno-forms-text>
											<yuno-forms-text
												formControlName="project"
												label="Project"
												placeholder="project name"></yuno-forms-text>
											<yuno-forms-text
												formControlName="pano"
												label="Panorama"
												placeholder="panorama name"></yuno-forms-text>
											<yuno-forms-text
												formControlName="client"
												label="Client"
												placeholder="client name"></yuno-forms-text>
											<yuno-forms-text
												formControlName="prefix"
												label="Prefix"
												placeholder="{{
													environment['yuno-cdn']
												}}/panoramas/clients/"></yuno-forms-text>
										</section>
									</ng-container>
								}
								@if (tabValue === 'Panorama Options') {
									<div formGroupName="location" class="flex flex-col gap-8">
										<yuno-forms-number
											label="Height"
											placeholder="0"
											formControlName="height">
										</yuno-forms-number>
										<label class="yuno-form">
											<span class="select-none">Coordinates</span>
											<div
												class="grid grid-cols-2 gap-4"
												formArrayName="coordinates">
												@for (
													coord of service.locCoords.controls;
													track coord;
													let i = $index
												) {
													<yuno-forms-number
														[step]="0.001"
														[formControlName]="i">
													</yuno-forms-number>
												}
											</div>
										</label>
									</div>
									<yuno-forms-divider></yuno-forms-divider>
									<section
										formGroupName="view"
										class="mb-8 grid grid-cols-3 gap-4">
										<yuno-forms-number
											[step]="0.001"
											label="Field of View"
											placeholder="0"
											formControlName="fov">
										</yuno-forms-number>
										<yuno-forms-number
											[step]="0.001"
											label="Pitch"
											placeholder="0"
											formControlName="pitch">
										</yuno-forms-number>
										<yuno-forms-number
											[step]="0.001"
											label="Yaw"
											placeholder="0"
											formControlName="yaw">
										</yuno-forms-number>
									</section>
									<yuno-forms-divider></yuno-forms-divider>
									<div formGroupName="limitView" class="flex flex-col gap-8">
										<yuno-forms-toggle
											formControlName="active"
											label="Limit View"></yuno-forms-toggle>
										@if (service.limit.value.active) {
											<yuno-forms-select
												formControlName="limitview"
												[selectValues]="service.limitValues"
												label="Limit view type"></yuno-forms-select>
											<section class="mb-8 grid grid-cols-2 gap-4">
												<yuno-forms-number
													label="Horizontal Lookat (min)"
													placeholder="-90"
													formControlName="hlookatmin">
												</yuno-forms-number>
												<yuno-forms-number
													label="Horizontal Lookat (max)"
													placeholder="90"
													formControlName="hlookatmax">
												</yuno-forms-number>
												<yuno-forms-number
													label="Vertical Lookat (min)"
													placeholder="-90"
													formControlName="vlookatmin">
												</yuno-forms-number>
												<yuno-forms-number
													label="Vertical Lookat (max)"
													placeholder="90"
													formControlName="vlookatmax">
												</yuno-forms-number>
											</section>
										}
									</div>
								}
								@if (tabValue === 'Text Options') {
									<div
										formGroupName="textfield"
										class="flex flex-col justify-between gap-4">
										<yuno-forms-title>Default Textfield</yuno-forms-title>
										<yuno-forms-select
											formControlName="default"
											[display]="service.textfieldDisplay"
											[selectValues]="service.textfieldValues"
											placeholder="select textfield..."
											label="Textfield"></yuno-forms-select>
										<yuno-forms-divider></yuno-forms-divider>
										<ng-container formArrayName="states">
											<yuno-forms-title>Textfield by States</yuno-forms-title>
											@for (
												state of service.states.controls;
												track state;
												let i = $index
											) {
												<div
													class="relative flex flex-col gap-2 rounded-md border-2 border-gray-300 bg-gray-100 p-4"
													[formGroupName]="i">
													<yuno-forms-select
														formControlName="state"
														[display]="service.stateDisplay"
														[selectValues]="service.stateValues"
														placeholder="select state..."
														label="state"></yuno-forms-select>
													<yuno-forms-select
														formControlName="text"
														[display]="service.textfieldDisplay"
														[selectValues]="service.textfieldValues"
														placeholder="select textfield..."
														label="textfield"></yuno-forms-select>
													<div class="flex justify-end">
														<button
															yuno-admin-button
															color="danger"
															(click)="service.removeState(i)">
															Remove
														</button>
													</div>
												</div>
											}
											<button
												yuno-admin-button
												color="primary"
												(click)="service.addState()"
												[add]="true">
												Add State
											</button>
										</ng-container>
									</div>
								}
							</div>
							<div class="col-span-1">
								<div class="relative">
									<yuno-admin-pano-map></yuno-admin-pano-map>
								</div>
							</div>
						</form>
					}
				</div>
				<div edit-container-content class="flex-1">
					<yuno-admin-pano-viewer
						[hideBtn]="!minimalAppRole(userRoles.EDITOR)"
						(view)="onUpdateView($event)"
						(pano)="onChangePano($event)"></yuno-admin-pano-viewer>
				</div>
			</div>
		</yuno-edit-container>
	}
}
