import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AnnotationComponent, YunoAdminButtonsModule } from '@yuno/admin/ui';
import { YunoFormsImageComponent } from '@yuno/angular/forms/admin-components';
import { YunoFormsSpanComponent } from '@yuno/angular/forms/components';
import { OpenImageEvent } from '@yuno/api/interface';

import { EventsDefaultFormItemComponent } from '../_default/default-form-item.component';

@Component({
	selector: 'yuno-admin-open-image',
	imports: [
		ReactiveFormsModule,
		YunoFormsSpanComponent,
		YunoAdminButtonsModule,
		AnnotationComponent,
		YunoFormsImageComponent
	],
	templateUrl: './open-image.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenImageComponent extends EventsDefaultFormItemComponent implements OnInit {
	ngOnInit(): void {
		this.onValueChanged((this.form.value.options as OpenImageEvent['options']).src);
	}

	setCustomProperties() {
		const src = this.form.get('options.src');
		src?.setValue('{{properties.customProperties.image}}');
		this.customProperty = true;
	}
}
