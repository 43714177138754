import { FormBuilder, FormGroup } from '@angular/forms';

export const createFormJsonControls = (
	form: FormGroup,
	formBuilder: FormBuilder,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	json: any
) => {
	const formControls = Object.keys(form.controls);
	formControls.forEach(control => {
		form.removeControl(control);
	});

	for (const control in json) {
		form.addControl(control, formBuilder.control(''));
	}
	form.patchValue(json);
};
