import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, exhaustMap, map, of, switchMap, take, tap, withLatestFrom } from 'rxjs';

import { appFeature } from '@yuno/admin/features/apps';
import { GraphQLService } from '@yuno/angular-graphql';
import { MessageService } from '@yuno/angular/notifications';

import {
	DELETE_TEXTFIELD,
	DeleteTextfieldMutation,
	GET_TEXTFIELDS,
	GET_TEXTFIELD_BY_ID,
	SAVE_ALLTEXTFIELDS,
	SAVE_TEXTFIELD,
	SaveAllTextfieldsMutation,
	SaveTextfieldMutation,
	TextfieldsQuery
} from '../../../utils';
import { textfieldsActions } from './textfield.actions';
import { textfieldsFeature } from './textfield.state';

export const loadTextfields = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(textfieldsActions.load, textfieldsActions.reload),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([, appId]) =>
				graphql
					.query<TextfieldsQuery>({
						query: GET_TEXTFIELDS,
						variables: {
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data.textfields) {
								throw new Error('no textfields found');
							}
							return textfieldsActions.loadSuccess({
								data: data.data.textfields
							});
						}),
						take(1),
						catchError(error => of(textfieldsActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const loadFilteredTextfields = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(textfieldsActions.loadFiltered),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([data, appId]) =>
				graphql
					.query<TextfieldsQuery>({
						query: GET_TEXTFIELDS,
						variables: {
							appId: appId,
							filter: data.filter
						}
					})
					.pipe(
						map(data => {
							if (!data.data.textfields) {
								throw new Error('no textfields found');
							}
							return textfieldsActions.loadSuccess({
								data: data.data.textfields
							});
						}),
						take(1),
						catchError(error => of(textfieldsActions.loadFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const selectTextfields = createEffect(
	(
		actions$ = inject(Actions),
		message = inject(MessageService),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(textfieldsActions.select),
			exhaustMap(textfield =>
				graphql
					.query<TextfieldsQuery>({
						query: GET_TEXTFIELD_BY_ID,
						variables: {
							_id: textfield._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data.selectedTextfield) {
								message.sendToast(`Error selecting Textfield!`, 'error');
								throw new Error('no textfield with that id found');
							}

							return textfieldsActions.selectSuccess({
								data: data.data.selectedTextfield
							});
						}),
						take(1),
						catchError(error => of(textfieldsActions.selectFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const updateSelectedTextfield = createEffect(
	(actions$ = inject(Actions), store = inject(Store), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(textfieldsActions.updateSelect),
			withLatestFrom(store.pipe(select(textfieldsFeature.selectSelected))),
			map(([data, currentState]) => {
				if (!data.data) {
					message.sendToast(`Error updating Textfield!`, 'error');
					throw new Error('no data found');
				}

				if (currentState?._id && data.data._id !== currentState?._id) {
					throw new Error('Trying to update Textfield without same _id');
				}

				return textfieldsActions.updateSelectSuccess({
					data: data.data
				});
			}),
			catchError(error => of(textfieldsActions.updateSelectFailure({ error })))
		),
	{ functional: true }
);

export const updateTextfield = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(textfieldsActions.updateTextfield),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([object, appId]) =>
				graphql
					.mutate<SaveTextfieldMutation>({
						mutation: SAVE_TEXTFIELD,
						variables: {
							textfield: object.data,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.saveTextfield) {
								throw new Error('error saving textfield to the database');
							}
							const visibleInClientCms = data.data.saveTextfield.public;
							const useableAsPreset = data.data.saveTextfield.preset;

							return textfieldsActions.updateTextfieldSuccess({
								visible: visibleInClientCms,
								preset: useableAsPreset,
								index: object.index
							});
						}),
						take(1),
						catchError(error => of(textfieldsActions.updateTextfieldFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const updateAllTextfields = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(textfieldsActions.updateAllTextfields),
			withLatestFrom(store.pipe(select(appFeature.selectAppId))),
			switchMap(([event, appId]) =>
				graphql
					.mutate<SaveAllTextfieldsMutation>({
						mutation: SAVE_ALLTEXTFIELDS,
						variables: {
							appId: appId,
							bool: event.bool,
							filter: event.filter
						}
					})
					.pipe(
						map(textfield => {
							return textfieldsActions.updateAllTextfieldsSuccess({
								bool: event.bool,
								filter: textfield.data?.updateAllTextfields || ''
							});
						}),
						take(1),
						catchError(error =>
							of(textfieldsActions.updateAllTextfieldsFailure({ error }))
						)
					)
			)
		),
	{ functional: true }
);

export const saveTextfield = createEffect(
	(
		actions$ = inject(Actions),
		store = inject(Store),
		router = inject(Router),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(textfieldsActions.save),
			withLatestFrom(
				store.pipe(select(textfieldsFeature.selectSelected)),
				store.pipe(select(appFeature.selectAppId))
			),
			switchMap(([, selected, appId]) =>
				graphql
					.mutate<SaveTextfieldMutation>({
						mutation: SAVE_TEXTFIELD,
						variables: {
							textfield: selected,
							appId: appId
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.saveTextfield) {
								throw new Error('error saving textfield to the database');
							}

							if (!selected?._id && data.data.saveTextfield) {
								const arr = router.url.split('/');
								arr.pop();

								const urlTree = router.createUrlTree([
									...arr,
									'edit',
									data.data.saveTextfield._id
								]);

								router.navigateByUrl(urlTree);
							}

							return textfieldsActions.saveSuccess({ data: data.data.saveTextfield });
						}),
						take(1),
						catchError(error => of(textfieldsActions.saveFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const deleteTextfield = createEffect(
	(actions$ = inject(Actions), store = inject(Store), graphql = inject(GraphQLService)) =>
		actions$.pipe(
			ofType(textfieldsActions.delete),
			switchMap(data =>
				graphql
					.mutate<DeleteTextfieldMutation>({
						mutation: DELETE_TEXTFIELD,
						variables: {
							_id: data._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data?.deleteTextfield) {
								throw new Error('error deleting Textfield from the database');
							}
							store.dispatch(textfieldsActions.reload());
							return textfieldsActions.deleteSuccess({
								_id: data.data.deleteTextfield
							});
						}),
						take(1),
						catchError(error => {
							return of(textfieldsActions.deleteFailure({ error }));
						})
					)
			)
		),
	{ functional: true }
);

export const setPreset = createEffect(
	(
		actions$ = inject(Actions),
		message = inject(MessageService),
		graphql = inject(GraphQLService)
	) =>
		actions$.pipe(
			ofType(textfieldsActions.setPreset),
			exhaustMap(textfield =>
				graphql
					.query<TextfieldsQuery>({
						query: GET_TEXTFIELD_BY_ID,
						variables: {
							_id: textfield._id
						}
					})
					.pipe(
						map(data => {
							if (!data.data.selectedTextfield) {
								message.sendToast(`Error selecting Textfield!`, 'error');
								throw new Error('no textfield with that id found');
							}
							const components = data.data.selectedTextfield.components || [];
							return textfieldsActions.setTemplate({ data: components });
						}),
						take(1),
						catchError(error => of(textfieldsActions.selectFailure({ error })))
					)
			)
		),
	{ functional: true }
);

export const updateTextfieldSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(textfieldsActions.updateTextfieldSuccess),
			tap(() => {
				message.sendToast(`Textfield successfully updated!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const updateTextfieldFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(textfieldsActions.updateTextfieldFailure),
			tap(() => {
				message.sendToast(`Error updating Textfield!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const updateAllTextfieldsSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(textfieldsActions.updateAllTextfieldsSuccess),
			tap(() => {
				message.sendToast(`Textfields successfully updated!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const updateAllTextfieldsFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(textfieldsActions.updateAllTextfieldsFailure),
			tap(() => {
				message.sendToast(`Error updating Textfields!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const saveTextfieldSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(textfieldsActions.saveSuccess),
			tap(() => {
				message.sendToast(`Textfield successfully saved!`, 'success');
			}),
			map(data => textfieldsActions.updateSelect({ data: data.data }))
		),
	{ dispatch: false, functional: true }
);

export const saveTextfieldFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(textfieldsActions.saveFailure),
			tap(() => {
				message.sendToast(`Error saving Textfield!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteTextfieldSuccess = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(textfieldsActions.deleteSuccess),
			tap(() => {
				message.sendToast(`Textfield successfully deleted!`, 'success');
			})
		),
	{ dispatch: false, functional: true }
);

export const deleteTextfieldFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(textfieldsActions.deleteFailure),
			tap(() => {
				message.sendToast(`Error deleting Textfield!`, 'error');
			})
		),
	{ dispatch: false, functional: true }
);
