import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	HostBinding,
	OnDestroy,
	OnInit,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import {
	ImageSourceSpecification,
	RasterDEMSourceSpecification,
	RasterSourceSpecification,
	VectorSourceSpecification
} from 'maplibre-gl';
import { combineLatest, debounceTime, filter, startWith, take, tap } from 'rxjs';

import { FormStartingService } from '@yuno/admin/core';
import { DatasetsFacade } from '@yuno/admin/features/datasets';
import { SourcesCustomFacade, SourcesFacade } from '@yuno/admin/features/sources/data-access';
import {
	EditContainerContentDirective,
	UserRightsMessageComponent,
	YunoAdminCloseButtonComponent,
	YunoAdminCodeEditorModule,
	YunoAdminSaveButtonComponent,
	YunoEditContainerModule
} from '@yuno/admin/ui';
import { AppDataComponent, redirectTo } from '@yuno/admin/utils';
import {
	YunoFormsNumberComponent,
	YunoFormsSelectComponent,
	YunoFormsTextComponent,
	YunoFormsTitleComponent,
	YunoFormsToggleComponent
} from '@yuno/angular/forms/components';
import { Layer, isCustomSource, isSource } from '@yuno/api/interface';
import { LoadersModule } from '@yuno/project-atlas/ui';

import { LayersFacade } from '../../data-access';
import { MapViewerComponent } from './layer-editor-map/map-viewer.component';
import { LayerEditorService } from './layer-editor.service';

@Component({
	imports: [
		ReactiveFormsModule,
		UserRightsMessageComponent,
		MapViewerComponent,
		YunoEditContainerModule,
		YunoAdminCodeEditorModule,
		YunoFormsTitleComponent,
		YunoFormsTextComponent,
		YunoFormsNumberComponent,
		YunoFormsSelectComponent,
		YunoFormsToggleComponent,
		AsyncPipe,
		LoadersModule,
		YunoAdminSaveButtonComponent,
		YunoAdminCloseButtonComponent,
		EditContainerContentDirective
	],
	providers: [LayerEditorService, FormStartingService],
	selector: 'yuno-admin-layer-editor',
	templateUrl: './layer-editor.component.html',
	styleUrls: ['./layer-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayerEditorComponent extends AppDataComponent implements OnInit, OnDestroy {
	@HostBinding('class') private readonly className = 'editor-component-container';
	@HostBinding('class.fixed') isPopup = false;

	private readonly layerFacade = inject(LayersFacade);
	private readonly sourceFacade = inject(SourcesFacade);
	private readonly customSourceFacade = inject(SourcesCustomFacade);
	private readonly datasetFacade = inject(DatasetsFacade);
	private readonly destroyRef = inject(DestroyRef);

	readonly service = inject(LayerEditorService);

	private readonly formService = inject(FormStartingService);
	$isChanged = this.formService.$isChanged;

	originalData: Partial<Layer>;
	url: string;

	readonly = !this.minimalAppRole(this.userRoles.EDITOR);
	data$ = combineLatest({
		layer: this.layerFacade.selectedLayer$.pipe(
			filter(() => !this.originalData),
			tap(data => {
				this.formService.startup(
					this.service.form,
					data,
					this.originalData,
					this.route,
					this.router,
					async () => {
						if (data) {
							this.originalData = data;

							this.editContainerBreadcrumbsService.addRoute(
								'data',
								this.originalData?.id
							);

							/*
							 * Filter, Layout and Paint controls are empty formGroups and Arrays
							 * controls will be added depending on the json
							 * */
							if (this.originalData?.filter) {
								this.filterControl.patchValue(
									JSON.stringify(this.originalData.filter)
								);
								this.service.updateFormControls('filter', this.originalData.filter);
							}

							if (this.originalData?.layout) {
								this.layoutControl.patchValue(
									JSON.stringify(this.originalData.layout)
								);
								this.service.updateFormControls('layout', this.originalData.layout);
							}

							if (this.originalData?.paint) {
								this.paintControl.patchValue(
									JSON.stringify(this.originalData.paint)
								);
								this.service.updateFormControls('paint', this.originalData.paint);
							}
						}
					}
				);
			})
		),
		source: this.layerFacade.selectedSource$.pipe(
			startWith(undefined),
			tap(data => {
				if (data && isSource(data)) {
					const sourceData = data?.sourceData as
						| VectorSourceSpecification
						| RasterSourceSpecification
						| RasterDEMSourceSpecification
						| ImageSourceSpecification;

					if (sourceData) {
						if (sourceData?.url) {
							this.sourceFacade.getSourceLayers(sourceData.url);
						} else {
							this.sourceFacade.clearSourceLayers();
						}
					}
				}

				if (data && isCustomSource(data)) {
					if (data.source?.options?.url) {
						this.sourceFacade.getSourceLayers(data.source?.options?.url);
					} else {
						this.sourceFacade.clearSourceLayers();
					}
				}
			})
		),
		sources: combineLatest(
			this.sourceFacade.sources$,
			this.customSourceFacade.customSources$
		).pipe(
			debounceTime(100),
			tap(([sources, customSources]) => {
				if (
					(sources && sources.length >= 1) ||
					(customSources && customSources.length >= 1)
				) {
					const combined = [
						...sources.map(object => object.id),
						...customSources.map(object => object.source?.name)
					];
					this.service.sources = combined as string[];
				}
			})
		),
		sourceLayers: this.sourceFacade.sourceLayers$.pipe(
			tap(data => {
				this.service.sourceLayers = data as string[];
			})
		),
		parent: this.datasetFacade.parent$
	});

	// For the Monaco Editor we use a Stringified control
	filterControl = new UntypedFormControl('');
	layoutControl = new UntypedFormControl('');
	paintControl = new UntypedFormControl('');

	ngOnInit(): void {
		this.sourceFacade.get();
		this.customSourceFacade.get();
		this.service.createFormGroup();

		this.onChanges();
		this.onRouting();

		/* 	Detects current url and select dataset from url	*/
		this.route.paramMap.pipe(take(1)).subscribe(data => {
			this.layerFacade.select(data.get('id'));
		});
	}

	onChanges(): void {
		this.service.form.valueChanges
			.pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: () => {
					this.layerFacade.updateSelect(
						this.service.form.getRawValue() as Partial<Layer>
					);

					if (this.isPopup) {
						this.datasetFacade.getParentRoute(this.allRouteParams);
					}
				},
				complete: () => {
					this.layerFacade.clearSelect();
				}
			});

		this.service.source.valueChanges
			.pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: src => {
					this.layerFacade.selectSource(src);
				},
				complete: () => {
					this.layerFacade.clearSource();
				}
			});
	}

	onRouting(): void {
		this.route.data.pipe(take(1)).subscribe(data => {
			this.isPopup = data['popup'] || false;
		});
	}

	onSave() {
		this.layerFacade.save();
	}

	/* 	navigates back to the Layer page */
	onClose(): void {
		redirectTo(this.route, this.router, []);
	}

	updateControlForm(event: string, key: 'filter' | 'layout' | 'paint'): void {
		this.service.updateFormControls(key, JSON.parse(event));
	}

	ngOnDestroy(): void {
		this.editContainerBreadcrumbsService.removeRouteName(this.originalData?.id);
	}
}
