<ng-container *ngIf="data$ | async as data">
	<yuno-admin-events-forms
		*ngIf="data.toggle"
		[group]="service.markerEvents"
		[array]="active === 'onClick' ? service.onClickEvents : service.onMouseMoveEvents"
		[arrayName]="active">
	</yuno-admin-events-forms>

	@if (isPopup) {
		<div
			class="pointer-events-auto absolute left-0 top-0 z-[210] h-screen w-screen bg-yuno-gray-500/70 backdrop-blur-xs">
			<yuno-edit-container-breadcrumbs />
		</div>
	}

	<div
		#container
		[@slideIn]="slideInToggle"
		[ngClass]="{ 'z-[250] m-4 mt-12': isPopup }"
		class="absolute bottom-0 right-0 top-0 z-100 col-span-4 col-start-2 m-2 flex w-full flex-col overflow-hidden rounded-lg bg-gray-700 p-2 shadow-lg md:w-[480px] md:min-w-[480px]">
		<div class="mb-2 flex flex-row justify-end gap-2">
			<button yuno-admin-save-button (click)="onSave()" [disabled]="!$isChanged()"></button>
			<button yuno-admin-button color="primary" (click)="onReset()">Reset</button>
			<button yuno-admin-close-button (click)="onClose()"></button>
		</div>

		<div class="flex h-full flex-col gap-2 overflow-y-auto rounded-md bg-gray-100 p-4">
			<yuno-user-rights-message *ngIf="disabled"></yuno-user-rights-message>

			<!-- Toggle Time, outside of form -->
			<div class="mt-2 flex" *ngIf="currentRoute === 'map'">
				<label
					class="yuno-form toggle !flex-row-reverse gap-4"
					[ngClass]="{ 'pointer-events-none': disabled }">
					<span class="select-none">
						Change location of Marker on Map, using drag-and-drop
					</span>
					<input
						class="toggle"
						type="checkbox"
						[disabled]="disabled"
						[(ngModel)]="toggleLocation"
						(ngModelChange)="toggleDraggable($event)" />
				</label>
			</div>

			<form *ngIf="service.form" [formGroup]="service.form">
				<div class="flex flex-col gap-8">
					<ng-container formGroupName="properties">
						<yuno-forms-text
							formControlName="id"
							label="Marker name"
							placeholder="marker name"></yuno-forms-text>
					</ng-container>
					<yuno-forms-toggle formControlName="public" label="Public"></yuno-forms-toggle>
				</div>
				<yuno-forms-divider></yuno-forms-divider>
				<div class="flex flex-col gap-8" formGroupName="properties">
					<section formGroupName="display">
						<ng-container *ngFor="let lang of languages">
							<yuno-forms-text
								*ngIf="lang === language"
								[formControlName]="lang"
								placeholder="display name"
								label="Display"></yuno-forms-text>
						</ng-container>
					</section>
					<section class="flex gap-4">
						<yuno-forms-number
							formControlName="minZoom"
							label="Min Zoom"></yuno-forms-number>
						<yuno-forms-number
							formControlName="maxZoom"
							label="Max Zoom"></yuno-forms-number>
					</section>
				</div>
				<yuno-forms-divider></yuno-forms-divider>
				<ng-container *ngIf="currentRoute === 'list'">
					<div class="flex flex-col gap-4" formGroupName="geometry">
						<yuno-forms-title>Coordinates</yuno-forms-title>
						<section class="flex gap-4" formArrayName="coordinates">
							<ng-container
								*ngFor="let coord of service.coordinates.controls; let i = index">
								<yuno-forms-number
									[step]="0.001"
									[formControlName]="i"></yuno-forms-number>
							</ng-container>
						</section>
					</div>
					<yuno-forms-divider></yuno-forms-divider>
				</ng-container>
				<div class="flex flex-col gap-4" formGroupName="style">
					<yuno-forms-title>Style</yuno-forms-title>
					<section class="grid grid-cols-2 gap-4">
						<yuno-forms-select
							class="col-span-2 flex-1"
							formControlName="class"
							[selectValues]="service.icon"
							label="Style"></yuno-forms-select>
						<yuno-forms-select
							class="flex-1"
							formControlName="alignment"
							[selectValues]="service.alignment"
							label="Alignment"></yuno-forms-select>
						<yuno-forms-select
							class="flex-1"
							formControlName="eventStyle"
							[selectValues]="service.eventTypes"
							[display]="service.eventTypesDisplay"
							label="Event Style"></yuno-forms-select>
						<yuno-forms-select
							class="flex-1"
							formControlName="visibility"
							[selectValues]="['none', 'visible']"
							label="Visibility"></yuno-forms-select>
						<yuno-forms-number
							class="flex-1"
							formControlName="zIndex"
							label="z-Index"></yuno-forms-number>
						<yuno-forms-color
							class="flex-1"
							[colorDirection]="'top'"
							formControlName="color"
							label="Text Color"></yuno-forms-color>
						<yuno-forms-color
							class="flex-1"
							[colorDirection]="'top'"
							formControlName="backgroundColor"
							label="Background Color"></yuno-forms-color>
					</section>
				</div>
				<yuno-forms-divider></yuno-forms-divider>
				<div class="mb-8 flex gap-4" *ngIf="!readonly">
					<button yuno-admin-button (click)="onToggleEvents('onClick')">
						Click Events ({{ service.onClickEvents.length || 0 }})
					</button>
					<button yuno-admin-button (click)="onToggleEvents('onMouseMove')">
						Hover Events ({{ service.onMouseMoveEvents.length || 0 }})
					</button>
				</div>
			</form>
		</div>
	</div>
</ng-container>
