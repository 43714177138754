import { Injectable, signal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { redirectTo } from '@yuno/admin/utils/redirect';

@Injectable()
export class FormStartingService {
	$isChanged = signal<boolean>(false);
	isTracking = false;

	/**
	 *
	 * Startup form controls in a way we can reuse
	 *
	 * @param form the formgroup containing the formdata
	 * @param data the database data
	 * @param originalData the originalData saved inside the component
	 * @param route the route (ActivatedRoute) of the component
	 * @param router the router (Router) of the component
	 * @param fun a ASYNC function when you want to run more than the default form functions. This runs AFTER patching formData!
	 * @param customRoute string[] redirect when url includes 'create' else defaults to ['edit', `${data._id}`]
	 */
	async startup(
		form: FormGroup,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		data: any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		originalData: any,
		route: ActivatedRoute,
		router: Router,
		fun?: () => Promise<void>,
		customRoute?: string[]
	): Promise<void> {
		if (!data || (data && originalData)) {
			if (data?._id && router.url.includes('create')) {
				const editRoute = customRoute || ['edit', `${data._id}`];
				redirectTo(route, router, editRoute);
			}

			return;
		}

		form.patchValue(data);

		// Runs the custom function
		if (fun) {
			await fun();
		}

		this.trackChanges(form);
	}

	/**
	 * Monitors the provided form and updates the `isChanged` state.
	 *
	 * @param form the formgroup containging the formdata
	 */
	trackChanges(form: FormGroup): void {
		if (!form || this.isTracking) {
			return;
		}

		this.isTracking = true;

		form.valueChanges.subscribe(() => {
			this.$isChanged.set(true);
		});
	}
}
