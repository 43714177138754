@if (title?.title && title?.title | languageSelect: language) {
	<div
		class="mb-2 flex flex-row items-center text-lg font-semibold"
		[ngStyle]="{ color: title?.color || textHeadingColor || 'inherit' }">
		@if (title?.icon) {
			<yuno-svg
				class="mt-1 h-4 w-6"
				src="https://cdn.projectatlas.app/content/static/icons/{{ title?.icon }}.svg"
				[fill]="title?.color || textHeadingColor || 'inherit'"
				[parentScale]="true"
				[ngStyle]="{
					display: 'block',
					'width.px': 20,
					'height.px': 20,
					'marginRight.px': 8
				}">
			</yuno-svg>
		}
		<span
			class="first-page-item"
			[innerHtml]="title?.title | languageSelect: language | safeHtml"></span>
	</div>
}
@if (content) {
	<div
		class="mb-2 md:mb-4"
		[innerHtml]="content | languageSelect: language | safeHtmlIframe"></div>
}
<ng-content></ng-content>

@if (imageButtons?.length) {
	<div class="ui-image-button-container">
		@for (item of imageButtons; track item) {
			@if (item.item && item.link) {
				@if (absoluteUrl(item.link)) {
					<a
						[href]="item.link"
						rel="noopener"
						target="_blank"
						[ngClass]="{ 'col-span-2': item.item.wide ?? true }">
						<yuno-button-card
							[data]="item.item"
							[wide]="true"
							[size]="item.item.size"
							[language]="language" />
					</a>
				} @else {
					<a
						[routerLink]="item.link"
						[relativeTo]="route.parent"
						[ngClass]="{ 'col-span-2': item.item.wide ?? true }">
						<yuno-button-card
							[data]="item.item"
							[wide]="true"
							[size]="item.item.size"
							[language]="language" />
					</a>
				}
			}
		}
	</div>
}

@for (link of links; track link) {
	@switch (link.type) {
		@case ('privacy-client') {
			<span class="inline cursor-pointer hover:opacity-70" (click)="openPrivacy(true)">
				<yuno-textfield-link
					class="pointer-events-none"
					[content]="link.text"
					[language]="language"
					[color]="link.color || textLinkColor"
					[disableInjection]="true" />
			</span>
		}
		@case ('privacy') {
			<span class="inline cursor-pointer hover:opacity-70" (click)="openPrivacy(false)">
				<yuno-textfield-link
					class="pointer-events-none"
					[content]="link.text"
					[language]="language"
					[color]="link.color || textLinkColor"
					[disableInjection]="true" />
			</span>
		}
		@case ('page') {
			@if (link.pageUrl?.id) {
				<yuno-textfield-link
					[content]="link.text"
					[language]="language"
					[page]="link.pageUrl?.id!"
					[color]="link.color || textLinkColor"
					[disableInjection]="true" />
			}
		}
		@default {
			@if (link.url | languageSelect: language) {
				<yuno-textfield-link
					[content]="link.text"
					[url]="link.url | languageSelect: language"
					[language]="language"
					[color]="link.color || textLinkColor"
					[disableInjection]="true" />
			}
		}
	}
}
