import { Injectable, signal } from '@angular/core';
import { Environment } from 'nunjucks';

import {
	addLanguageFilterToTemplate,
	nunjuckLanguageFilterFunction,
	nunjuckLanguageFilterName
} from '@yuno/shared/helpers';

@Injectable({
	providedIn: 'root'
})
export class NunjucksService {
	private env: Environment;
	$language = signal<string>('nl');

	constructor() {
		// Initialize the Nunjucks environment
		this.env = new Environment(null, { autoescape: false });

		this.env.addFilter(nunjuckLanguageFilterName, (str: string) => {
			return nunjuckLanguageFilterFunction(str, this.$language());
		});
	}

	setLanguage(lang: string): void {
		this.$language.set(lang);
	}

	/*
	 * Method to render a string using Nunjucks
	 */

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	render(template: string, context: any = {}): string {
		return this.env.render(template, context);
	}

	/*
	 * Method to render a string using Nunjucks
	 */

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	renderString(template: string, context: any = {}): string {
		return this.env.renderString(addLanguageFilterToTemplate(template), context);
	}
}
