import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	HostBinding,
	OnDestroy,
	OnInit,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { combineLatest, debounceTime, filter, take, tap } from 'rxjs';

import { FormStartingService } from '@yuno/admin/core';
import { DatasetsFacade } from '@yuno/admin/features/datasets';
import {
	AnnotationComponent,
	EditContainerContentDirective,
	UserRightsMessageComponent,
	YunoAdminButtonsModule,
	YunoEditContainerModule
} from '@yuno/admin/ui';
import { AppDataComponent, getIdFromRouter, redirectTo } from '@yuno/admin/utils';
import { DrawMapService } from '@yuno/angular/draw-map';
import {
	YunoFormsCodeEditorComponent,
	YunoFormsColorComponent,
	YunoFormsDividerComponent,
	YunoFormsNumberComponent,
	YunoFormsTextComponent
} from '@yuno/angular/forms/components';
import { Fence } from '@yuno/api/interface';
import { waitFor } from '@yuno/libs/shared/helpers';

import { FencesFacade } from '../../data-access';
import { FenceMapComponent } from './fence-map/fence-map.component';
import { FencesMapService } from './fence-map/map.service';
import { FencesEditorService } from './fences-editor.service';

const debounceFormControl = 300;

@Component({
	selector: 'yuno-admin-fences-editor',
	imports: [
		ReactiveFormsModule,
		YunoFormsDividerComponent,
		YunoFormsTextComponent,
		YunoFormsCodeEditorComponent,
		YunoFormsColorComponent,
		YunoFormsNumberComponent,
		YunoEditContainerModule,
		YunoAdminButtonsModule,
		UserRightsMessageComponent,
		YunoFormsCodeEditorComponent,
		AnnotationComponent,
		FenceMapComponent,
		AsyncPipe,
		EditContainerContentDirective
	],
	providers: [FormStartingService],
	templateUrl: './fences-editor.component.html',
	styleUrls: ['./fences-editor.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FencesEditorComponent extends AppDataComponent implements OnInit, OnDestroy {
	@HostBinding('class') private readonly className = 'editor-component-container';
	@HostBinding('class.fixed') isPopup = false;

	private readonly destroyRef = inject(DestroyRef);
	private readonly fenceFacade = inject(FencesFacade);
	private readonly datasetFacade = inject(DatasetsFacade);
	private readonly mapService = inject(FencesMapService);
	readonly service = inject(FencesEditorService);
	private readonly drawService = inject(DrawMapService);

	private readonly formService = inject(FormStartingService);
	$isChanged = this.formService.$isChanged;

	originalData: Partial<Fence>;
	readonly = !this.minimalAppRole(this.userRoles.EDITOR);

	data$ = combineLatest({
		fence: this.fenceFacade.selectedFences$.pipe(
			filter(() => !this.originalData),
			tap(data => {
				this.formService.startup(
					this.service.form,
					data,
					this.originalData,
					this.route,
					this.router,
					async () => {
						if (data) {
							this.originalData = data;

							this.editContainerBreadcrumbsService.addRoute('data', data.id);

							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							this.service.form.patchValue(this.originalData as any);

							if (this.originalData?.polygon) {
								await this.service.updatePolygon(this.originalData.polygon);
							}
						}
					}
				);
			})
		),
		parent: this.datasetFacade.parent$
	});

	async ngOnInit(): Promise<void> {
		this.service.createFormGroup();
		this.onChanges();
		this.onRouting();

		this.fenceFacade.select(await getIdFromRouter(this.route));
	}

	onChanges(): void {
		this.service.form.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(debounceFormControl))
			.subscribe(val => {
				val?.bearing && this.mapService.updateBearing(val.bearing);
				val?.pitch && this.mapService.updatePitch(val.pitch);

				val?.style?.color && this.drawService.updateDrawColor(val.style.color);
				val?.style?.opacity && this.drawService.updateDrawOpacity(val.style.opacity);

				this.fenceFacade.updateSelect(val as Partial<Fence>);
				if (this.isPopup) {
					this.datasetFacade.getParentRoute(this.allRouteParams);
				}
			});
	}

	onRouting(): void {
		this.route.data.pipe(take(1)).subscribe(data => {
			this.isPopup = data['popup'] || false;
		});
	}

	async onSave(): Promise<void> {
		// Update the polygon inside the Form
		await this.service.updatePolygon(this.service.polygon.getRawValue());

		// Wait for the polygon to be updated
		// inside the Store. Should be after the formValueChanges
		await waitFor(debounceFormControl + 100);

		// Now save the data
		this.fenceFacade.save();
	}

	onClose(): void {
		redirectTo(this.route, this.router);
	}

	async polyChanges(event: string): Promise<void> {
		if (!this.originalData || JSON.stringify(this.originalData?.polygon, null, 4) === event) {
			return;
		}
		await this.service.updatePolygon(JSON.parse(event) as [number, number][]);
	}

	async ngOnDestroy(): Promise<void> {
		this.editContainerBreadcrumbsService.removeRouteName(this.originalData.id);

		await this.service.updatePolygon([]);
		this.fenceFacade.clearSelect();
	}
}
