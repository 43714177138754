import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	HostBinding,
	HostListener,
	Input,
	ViewChild,
	inject
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute } from '@angular/router';

import { AuthFacade } from '@yuno/angular-auth';
import { YunoUserRoles, YunoUserRolesEnum } from '@yuno/api/interface';

export type YunoAdminButtonColors =
	| 'primary'
	| 'secondary'
	| 'secondary-dark'
	| 'warning'
	| 'danger'
	| 'success'
	| 'muted'
	| 'disabled';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'button[yuno-admin-button-default-comp]',
	// eslint-disable-next-line @angular-eslint/no-host-metadata-property
	host: {
		'[attr.disabled]': 'disabled || null'
	},
	standalone: true,
	template: '',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultButtonsComponent {
	@HostBinding('class') classes = 'w-fit';
	@HostBinding('class.hidden') hidden = true;
	@HostBinding('class.disabled') @Input() disabled = false;

	@HostListener('click', ['$event']) disableClick(e: Event) {
		if (this.hidden) {
			e.preventDefault();
		}
	}

	readonly route = inject(ActivatedRoute);
	readonly auth = inject(AuthFacade);

	readonly userRoles = YunoUserRolesEnum;

	get appRole(): YunoUserRolesEnum {
		return this.route.snapshot.data['appRole'] as YunoUserRolesEnum;
	}

	minimalAppRole(minimal: YunoUserRoles): boolean {
		return this.auth.userHasMinimalAppRole(minimal, this.appRole);
	}

	maximumAppRole(maximum: YunoUserRoles): boolean {
		return this.auth.userHasMaximumAppRole(maximum, this.appRole);
	}
}

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'yuno-admin-shared-button',
	template: '',
	standalone: false
})
class YunoAdminButtonSharedComponent {
	@HostBinding('class.disabled') @Input() disabled = false;

	_color?: YunoAdminButtonColors = 'primary';
	@HostBinding('class') @Input() set color(color: YunoAdminButtonColors | undefined) {
		this._color = color || 'primary';
	}

	get color(): YunoAdminButtonColors | undefined {
		return this._color;
	}
}

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'button[yuno-admin-button], span[yuno-admin-button]',
	imports: [CommonModule, MatButtonModule],
	template: `
		<span
			#button
			class="button-container block transition-colors duration-200 ease-in-out"
			[ngClass]="{
				'rounded-full': round,
				'rounded-md': !round
			}">
			@if (add) {
				<svg
					xmlns="http://www.w3.org/2000/svg"
					class="-mt-1 mr-2 inline-block h-4 w-4"
					viewBox="0 0 16 16">
					<circle cx="8" cy="8" r="8" style="fill: #3a4558" />
					<path
						d="M12.3 6.9H9.1V3.7c0-.4-.3-.7-.7-.7h-.8c-.4 0-.7.3-.7.7v3.2H3.7c-.4 0-.7.3-.7.7v.7c0 .4.3.7.7.7h3.2v3.2c0 .4.3.7.7.7h.7c.4 0 .7-.3.7-.7V9.1h3.2c.4 0 .7-.3.7-.7v-.8c.1-.4-.2-.7-.6-.7z"
						style="fill: #e9ecee" />
				</svg>
			}
			<ng-content></ng-content>
		</span>
	`,
	styleUrls: ['./button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoAdminButtonComponent extends YunoAdminButtonSharedComponent {
	@ViewChild('button', { read: ElementRef }) matButton: ElementRef;
	@Input() add = false;
	@Input() round = false;
}

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'button[yuno-admin-button-round], span[yuno-admin-button-round]',
	imports: [CommonModule, MatButtonModule],
	template: `
		<span class="button-container block transition-colors duration-200 ease-in-out sm:px-5">
			<ng-content></ng-content>
		</span>
	`,
	styleUrls: ['./button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoAdminButtonRoundComponent extends YunoAdminButtonSharedComponent {
	@HostBinding('class.round') round = true;
}

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'button[yuno-admin-button-table], span[yuno-admin-button-table]',
	imports: [CommonModule, MatButtonModule],
	template: `
		<span #button class="table-btn-text text-sm">
			@if (add) {
				<svg
					xmlns="http://www.w3.org/2000/svg"
					class="mr-1.5 inline-block h-4 w-4"
					viewBox="0 0 16 16">
					<circle cx="8" cy="8" r="8" style="fill: #3a4558" />
					<path
						d="M12.3 6.9H9.1V3.7c0-.4-.3-.7-.7-.7h-.8c-.4 0-.7.3-.7.7v3.2H3.7c-.4 0-.7.3-.7.7v.7c0 .4.3.7.7.7h3.2v3.2c0 .4.3.7.7.7h.7c.4 0 .7-.3.7-.7V9.1h3.2c.4 0 .7-.3.7-.7v-.8c.1-.4-.2-.7-.6-.7z"
						style="fill: #e9ecee" />
				</svg>
			}
			<ng-content></ng-content>
		</span>
	`,
	styleUrls: ['./button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoAdminButtonTableComponent extends YunoAdminButtonSharedComponent {
	@ViewChild('button', { read: ElementRef }) matButton: ElementRef;
	@HostBinding('attr.data-type') table = 'table';

	@Input() add = false;
}
