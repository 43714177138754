import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	forwardRef,
	inject
} from '@angular/core';
import {
	ControlValueAccessor,
	FormsModule,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule
} from '@angular/forms';
import { tap } from 'rxjs';

import {
	FileLibraryEditContainerComponent,
	FileLibraryService
} from '@yuno/admin/features/file-library';
import { YunoAdminButtonTableComponent } from '@yuno/admin/ui';
import {
	YunoFormsDefaultComponent,
	YunoFormsValidatorMessagesComponent,
	YunoLanguageIconsComponent
} from '@yuno/angular/forms/components';
import { CdnFile } from '@yuno/api/interface';

import { YunoFormsLabelRequired } from '../../pipes/label-required/label-required.pipe';

@Component({
	selector: 'yuno-forms-image',
	template: `
		<ng-container *ngIf="{ file: active$ | async, close: close$ | async } as data">
			<label class="yuno-form">
				<span
					class="select-none"
					*ngIf="label"
					[innerHtml]="label | labelRequired: required">
				</span>
				@if (formValue === 'object') {
					<div class="input-div browse truncate" type="text">
						{{ getFormControl().value?.data?.fileName }}
					</div>
				} @else {
					<input
						class="browse truncate"
						type="text"
						[id]="ngControl.name"
						[name]="ngControl.name"
						[placeholder]="placeholder"
						[formControl]="getFormControl()"
						(change)="onChanged($event)" />
				}
				<yuno-language-icons
					[language]="language"
					[padding]="!!label"></yuno-language-icons>

				<div
					class="absolute font-normal"
					[ngClass]="{
						'right-10': language,
						'right-2': !language,
						'top-[27px]': label,
						'top-[3px]': !label
					}">
					<button yuno-admin-button-table (click)="browseImages()">Browse</button>
				</div>
				<yuno-validator-messages [control]="ngControl.control">
					<ng-content></ng-content>
				</yuno-validator-messages>
			</label>
		</ng-container>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		NgIf,
		NgClass,
		AsyncPipe,
		FormsModule,
		ReactiveFormsModule,
		YunoFormsLabelRequired,
		YunoFormsValidatorMessagesComponent,
		YunoAdminButtonTableComponent,
		YunoLanguageIconsComponent
	]
})
export class YunoFormsImageComponent extends YunoFormsDefaultComponent {
	@Input() formValue: 'url' | 'object' = 'url';
	@Input() language?: string;
	@Output() changes = new EventEmitter<string>();

	private readonly service = inject(FileLibraryService);
	private overlay = inject(Overlay);
	private overlayRef?: OverlayRef;
	private browse = false;

	active$ = this.service.activeFile$.pipe(
		tap(file => {
			if (this.browse) {
				if (this.formValue === 'url') {
					this.ngControl.control?.setValue(file.data?.url);
				}

				if (this.formValue === 'object') {
					this.ngControl.control?.setValue(file);
				}
			}
		})
	);

	close$ = this.service.close$.pipe(
		tap(() => {
			if (this.browse) {
				this.closeOverlay();
			}
		})
	);

	onChanged(event: Event) {
		// Get the value of the selected option
		const selectedValue = (event.target as HTMLInputElement).value;

		// Outputs the changes
		this.changes.emit(selectedValue);
	}

	browseImages() {
		this.overlayRef = this.overlay.create({
			positionStrategy: this.overlay.position().global(),
			scrollStrategy: this.overlay.scrollStrategies.reposition(),
			hasBackdrop: false,
			panelClass: 'z-1000'
		});

		const imageEditor = new ComponentPortal(FileLibraryEditContainerComponent);
		const t = this.overlayRef?.attach(imageEditor);
		t?.changeDetectorRef?.detectChanges();
		this.browse = true;
	}

	closeOverlay(): void {
		this.overlayRef?.dispose();
		this.overlayRef = undefined;
		this.browse = false;
	}
}

@Component({
	selector: 'yuno-forms-template-image',
	template: `
		<ng-container *ngIf="{ file: active$ | async, close: close$ | async } as data">
			<label class="yuno-form">
				<span
					class="select-none"
					*ngIf="label"
					[innerHtml]="label | labelRequired: required">
				</span>
				@if (formValue === 'object') {
					<div class="input-div browse truncate" type="text">
						{{ value?.data?.fileName }}
					</div>
				} @else {
					<input
						class="browse truncate"
						type="text"
						[ngModel]="value"
						(ngModelChange)="valueChange($event)"
						[placeholder]="placeholder"
						[required]="required" />
				}
				<div class="absolute right-2 top-[27px] font-normal">
					<button yuno-admin-button-table (click)="browseImages()">Browse</button>
				</div>
			</label>
		</ng-container>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgIf, FormsModule, AsyncPipe, YunoFormsLabelRequired, YunoAdminButtonTableComponent],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => YunoFormsImageTemplateComponent),
			multi: true
		}
	]
})
export class YunoFormsImageTemplateComponent implements ControlValueAccessor {
	private readonly service = inject(FileLibraryService);
	private overlay = inject(Overlay);
	private overlayRef?: OverlayRef;
	private browse = false;

	@Input() formValue: 'url' | 'object' = 'url';

	@Input() label?: string;
	@Input() placeholder? = '';
	@Input() required: boolean = false;

	// value: string | CdnFile | undefined = '';
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value: any = '';

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_onTouch = (_: unknown) => {};
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	_onChange = (_: unknown) => {};

	active$ = this.service.activeFile$.pipe(
		tap(file => {
			if (this.browse) {
				if (this.formValue === 'url') {
					this.valueChange(file.data?.url);
				}

				if (this.formValue === 'object') {
					this.valueChange(file);
				}
			}
		})
	);

	close$ = this.service.close$.pipe(
		tap(() => {
			if (this.browse) {
				this.closeOverlay();
			}
		})
	);

	private _isDisabled: boolean;
	public get isDisabled(): boolean {
		return this._isDisabled;
	}

	browseImages(): void {
		this.overlayRef = this.overlay.create({
			positionStrategy: this.overlay.position().global(),
			scrollStrategy: this.overlay.scrollStrategies.reposition(),
			hasBackdrop: false,
			panelClass: 'z-1000'
		});

		const imageEditor = new ComponentPortal(FileLibraryEditContainerComponent);
		const t = this.overlayRef?.attach(imageEditor);
		t?.changeDetectorRef?.detectChanges();
		this.browse = true;
	}

	closeOverlay(): void {
		this.overlayRef?.dispose();
		this.overlayRef = undefined;
		this.browse = false;
	}

	valueChange(value: string | CdnFile | undefined): void {
		this.value = value;
		this._onChange(this.value);
	}

	writeValue(value: string | CdnFile | undefined): void {
		this.value = value;
	}

	registerOnChange(fn: (_: unknown) => void): void {
		this._onChange = fn;
	}

	registerOnTouched(fn: (_: unknown) => void): void {
		this._onTouch = fn;
	}
}
