import { gql } from 'apollo-angular';

import { Config } from '@yuno/api/interface';

export const GET_CONFIG_BY_APPID = gql`
	query loadConfig($appId: String!) {
		config(appId: $appId) {
			_id
			title
			customTitle
			language
			languages
			help {
				textfields {
					desktop
					mobile
				}
				active
			}
			interface {
				mapOnly
				controlZoom
				fullscreenButton
				fullscreenPopup
				embedFullscreenPopup
				navbarType
				embedType
				buttonStyle
			}
			colors {
				navbar {
					background
					font
					active
					activeFont
					underline
				}
				embedNavbar {
					background
					font
					active
					activeFont
					underline
				}
				states {
					background
					font
				}
				button
				buttonFont
				panoramas
				panoramasClusterCount
				measure
				additional
				textHeading
				textLink
			}
			textHeading {
				h1 {
					font
					size
					color
					weight
				}
				h2 {
					font
					size
					color
					weight
				}
				h3 {
					font
					size
					color
					weight
				}
				h4 {
					font
					size
					color
					weight
				}
				h5 {
					font
					size
					color
					weight
				}
				h6 {
					font
					size
					color
					weight
				}
				paragraph {
					font
					size
					color
					weight
				}
			}
			fontHeader
			fontParagraph
		}
	}
`;

export type ConfigQuery = {
	config: Partial<Config>;
};
