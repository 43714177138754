@if (data$ | async; as data) {
	@if (data.toggle && service.component && service.events) {
		<yuno-admin-events-forms
			[group]="service.component"
			[array]="service.events"
			[arrayName]="service.eventType">
		</yuno-admin-events-forms>
	}
	@if (data.page) {
		<yuno-edit-container [col]="4" [pos]="1" [span]="4">
			<ng-container actions>
				<button yuno-admin-add-button (click)="service.onAddItem()">Content block</button>
				<button yuno-admin-add-muted-button (click)="service.onBrowseLibrary()">
					From Library
				</button>
			</ng-container>
			<ng-container buttons>
				<button yuno-admin-save-button (click)="service.onSave()"></button>
				<button
					yuno-admin-close-button
					(click)="
						!service.disableClose && redirectTo(route, router);
						!service.disableClose && service.onClose()
					"></button>
			</ng-container>
			<div class="flex flex-col gap-2 md:grid md:h-full md:grid-cols-2">
				<div edit-container-content>
					@if (service.form) {
						<form [formGroup]="service.form">
							@if (!minimalAppRole(userRoles.EDITOR)) {
								<yuno-user-rights-message class="mb-4"></yuno-user-rights-message>
							}
							<div class="mb-4 flex w-full flex-col gap-4">
								<yuno-forms-text
									formControlName="name"
									label="Page name"
									placeholder="Add page name"
									class="flex-1" />
								<yuno-forms-text
									formControlName="id"
									label="Page link"
									placeholder="Add page link"
									class="flex-1">
									Use only lowercase letters. Do not use spaces, diacritics (such
									as 'ä', 'ë'), or special characters, except for '-' and '_'.
								</yuno-forms-text>
							</div>
							<div class="mb-4 flex w-full justify-end gap-4">
								<yuno-forms-select
									class="w-full"
									formControlName="type"
									[placeholder]="'none'"
									[selectValues]="service.pagesEditorService.layoutSelectValues"
									[display]="service.pagesEditorService.layoutSelectDisplay"
									label="Layout"
									(changes)="service.onDisplayChange($event)" />
								<div class="mt-6 border-r-2 border-gray-200"></div>
								<label class="yuno-form">
									<span class="select-none"> Preview</span>
									<div
										class="pointer-events-auto flex h-[52px] flex-row gap-2 rounded-full border border-gray-300 bg-gray-50 p-1 font-semibold">
										<div
											(click)="service.view = 'desktop'"
											class="flex cursor-pointer select-none flex-row items-center justify-center rounded-full px-4 py-5 text-sm transition-colors ease-in-out hover:bg-gray-300"
											[ngClass]="{
												'pointer-events-none bg-yuno-blue text-white':
													service.view === 'desktop'
											}">
											<div class="flex flex-row gap-2">Desktop</div>
										</div>
										<div
											(click)="service.view = 'mobile'"
											class="flex cursor-pointer select-none flex-row items-center justify-center rounded-full px-4 py-5 text-sm transition-colors ease-in-out hover:bg-gray-300"
											[ngClass]="{
												'pointer-events-none bg-yuno-blue text-white':
													service.view === 'mobile'
											}">
											<div class="flex flex-row gap-2">Mobile</div>
										</div>
									</div>
								</label>
							</div>
							<div class="flex flex-col gap-4">
								@if (service.form.value.type === 'default') {
									<div class="mb-4 flex w-full justify-end gap-4">
										<span class="text-sm"
											>When using the "One column" layout, Desktop and Mobile
											order are synchronized</span
										>
									</div>
								}
							</div>
							@if (
								service.form.value.type === 'iframe' ||
								service.form.value.type === 'iframe-split'
							) {
								<yuno-forms-divider></yuno-forms-divider>
								<div class="mb-4 flex w-full gap-4">
									<yuno-forms-text
										formControlName="iframeSrc"
										label="iFrame url"
										placeholder="Add iFrame url"
										class="flex-1" />
									@if (service.form.value.type === 'iframe-split') {
										<ng-container formGroupName="iframeOptions">
											<yuno-forms-color
												[colorDirection]="'left'"
												formControlName="backgroundColor"
												label="iFrame background color"
												placeholder="Add background color" />
										</ng-container>
										<yuno-forms-divider></yuno-forms-divider>
									}
								</div>
							}

							@if (data.page.type !== 'iframe') {
								<div class="flex h-full w-full flex-row justify-center">
									<div
										class="align-center flex w-full flex-col gap-2"
										[ngClass]="{
											'max-w-md': service.view === 'mobile',
											'max-w-3xl': data.page.type === 'default',
											'max-w-6xl': data.page.type === 'side-by-side'
										}">
										<label class="yuno-form">
											<span class="select-none">Page blocks</span></label
										>
										@if (service.header.value.public) {
											<yuno-card-draggable
												class="cursor-pointer"
												[white]="true"
												(click)="
													service.onEditHeader(service.header);
													detectChanges(100)
												"
												[draggable]="false">
												<ng-container buttons>
													<button
														yuno-admin-button
														color="danger"
														(click)="service.onDisableHeader()">
														Remove
													</button>
												</ng-container>
												<ng-container popup>
													<span class="text-sm font-bold"
														>Page Header</span
													>
												</ng-container>
												<ng-container content>
													<!-- LOGO TOP-CENTER ONLY -->
													@if (
														service.logo.value.active &&
														service.logo.value.position === 'top-center'
													) {
														<div class="centered">
															<img
																class="m-auto h-auto"
																[src]="service.logo.value.src"
																[ngStyle]="{
																	'maxHeight.px':
																		service.logo.value.height,
																	'maxWidth.px':
																		service.logo.value.width
																}"
																loading="lazy" />
														</div>
													}
													@if (
														service.logo.value.active ||
															service.header.value.title?.projectTitle
															| languageSelect: language
													) {
														<div
															class="relative flex w-full flex-col items-start justify-start"
															[ngClass]="{
																'sm:flex-row-reverse sm:items-end':
																	service.logo.value.position ===
																	'right'
															}">
															<!-- LOGO LEFT/RIGHT -->
															@if (
																service.logo.value.active &&
																(service.logo.value.position ===
																	'left' ||
																	service.logo.value.position ===
																		'right')
															) {
																<div
																	class="flex flex-row"
																	[ngClass]="{
																		'w-full justify-end sm:w-auto':
																			service.logo.value
																				.position ===
																			'right'
																	}">
																	<img
																		alt="logo left/right"
																		class="h-auto w-auto"
																		[src]="
																			service.logo.value.src
																		"
																		[ngStyle]="{
																			'maxHeight.px':
																				service.logo.value
																					.height,
																			'maxWidth.px':
																				service.logo.value
																					.width
																		}"
																		loading="lazy" />
																</div>
															}
															<div class="flex flex-1 flex-col">
																<h3
																	class="text-lg font-semibold text-yuno-gray-400">
																	{{
																		service.header.value.title
																			?.description
																			| languageSelect
																				: data.language
																	}}
																</h3>
																@if (
																	service.header.value.logo
																		?.position !== 'project'
																) {
																	<h1
																		class="text-[1.75rem] font-semibold"
																		[ngStyle]="{
																			color: service.header
																				.value.title?.color
																		}">
																		{{
																			service.header.value
																				.title?.projectTitle
																				| languageSelect
																					: data.language
																		}}
																	</h1>
																}
																<!-- LOGO PROJECT -->
																@if (
																	service.logo.value.active &&
																	service.logo.value.position ===
																		'project'
																) {
																	<div>
																		<img
																			alt="logo project"
																			class="h-auto w-auto"
																			[src]="
																				service.logo.value
																					.src
																			"
																			[ngStyle]="{
																				'maxHeight.px':
																					service.logo
																						.value
																						.height,
																				'maxWidth.px':
																					service.logo
																						.value.width
																			}"
																			loading="lazy" />
																	</div>
																}
															</div>
														</div>
													}
													<!-- BANNER -->
													@if (
														service.banner.value.active &&
														!service.banner.value.imageBanner?.reactive
													) {
														<section
															class="no-select h-52 min-h-52 w-full overflow-hidden rounded">
															<div
																class="relative flex h-full w-full flex-col items-center justify-center">
																@if (
																	service.banner.value.imageBanner
																		?.split?.active
																) {
																	<div
																		class="split-screen-banner absolute -right-4 top-0 z-10 flex h-full w-1/2 flex-col items-start justify-center px-8"
																		[ngStyle]="{
																			backgroundColor:
																				service.banner.value
																					.imageBanner
																					?.split
																					?.backgroundColor ||
																				'transparent',
																			color:
																				service.banner.value
																					.imageBanner
																					?.split
																					?.fontColor ||
																				'#fff'
																		}">
																		{{
																			service.banner.value
																				.imageBanner?.split
																				?.description
																				| languageSelect
																					: language
																		}}
																	</div>
																}
																@if (
																	service.banner.value.imageBanner
																		?.mobileSrc ||
																	service.banner.value.imageBanner
																		?.src
																) {
																	<picture>
																		@if (
																			service.banner.value
																				.imageBanner?.src
																		) {
																			<source
																				media="(min-width: 640px)"
																				[srcset]="
																					service.banner
																						.value
																						.imageBanner
																						?.src
																				" />
																		}
																		<img
																			alt="image banner"
																			class="absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 object-cover"
																			[src]="
																				service.banner.value
																					.imageBanner
																					?.mobileSrc ||
																				service.banner.value
																					.imageBanner
																					?.src
																			" />
																	</picture>
																}
															</div>
														</section>
													}
													<!-- REACTIVE BANNER -->
													@if (
														((service.banner.value.active &&
															service.banner.value.imageBanner
																?.mobileSrc) ||
															service.banner.value.imageBanner
																?.src) &&
														service.banner.value.imageBanner?.reactive
													) {
														<section
															class="no-select relative flex w-full rounded">
															<picture class="flex-1">
																@if (
																	service.banner.value.imageBanner
																		?.src
																) {
																	<source
																		media="(min-width: 640px)"
																		[srcset]="
																			service.banner.value
																				.imageBanner?.src
																		" />
																}
																<img
																	alt="image banner"
																	class="relative left-1/2 top-1/2 h-auto w-full -translate-x-1/2 -translate-y-1/2 object-cover"
																	[src]="
																		service.banner.value
																			.imageBanner
																			?.mobileSrc ||
																		service.banner.value
																			.imageBanner?.src
																	" />
															</picture>
														</section>
													}
												</ng-container>
											</yuno-card-draggable>
										}
										<yuno-textfield-injector
											[render]="false"
											[data]="data.page"
											[buttonColor]="data.config?.colors?.buttonFont"
											[buttonBgColor]="data.config?.colors?.button"
											[language]="data.language"
											(componentsOut)="service.injectComponents($event)">
										</yuno-textfield-injector>
										@if (
											service.returnComponents(service.injectorComponents);
											as dropComponents
										) {
											@if (service.view === 'desktop') {
												<div
													cdkDropListGroup
													class="grid gap-2"
													[ngClass]="{
														'grid-cols-1': data.page.type === 'default',
														'grid-cols-2':
															data.page.type === 'side-by-side'
													}">
													<div
														class="flex flex-col gap-2"
														cdkDropList
														#leftComp="cdkDropList"
														[cdkDropListConnectedTo]="rightComp"
														(cdkDropListDropped)="
															service.drop(
																$event,
																dropComponents.right,
																dropComponents.left,
																'left'
															)
														">
														@for (
															comp of dropComponents.left;
															track service.trackByComponentId(
																i,
																comp
															);
															let i = $index
														) {
															<yuno-admin-pages-dynamic
																cdkDrag
																[active]="
																	service.editor.index === i &&
																	service.editor.side === 'left'
																"
																(clicked)="
																	service.onEditComponent(
																		comp.key,
																		comp.data,
																		'left',
																		i
																	);
																	detectChanges(100)
																"
																(removed)="
																	service.onRemoveComponent(
																		comp.page?.order || i,
																		'left'
																	)
																"
																(duplicate)="
																	service.onDuplicateComponent(
																		$event,
																		'left',
																		i
																	)
																"
																[item]="comp" />
														}
													</div>
													<div
														class="flex flex-col gap-2"
														cdkDropList
														#rightComp="cdkDropList"
														[cdkDropListData]="dropComponents.right"
														(cdkDropListDropped)="
															service.drop(
																$event,
																dropComponents.left,
																dropComponents.right,
																'right'
															)
														"
														[cdkDropListConnectedTo]="leftComp">
														@for (
															comp of dropComponents.right;
															track service.trackByComponentId(
																i,
																comp
															);
															let i = $index
														) {
															<yuno-admin-pages-dynamic
																cdkDrag
																[active]="
																	service.editor.index === i &&
																	service.editor.side === 'right'
																"
																(clicked)="
																	service.onEditComponent(
																		comp.key,
																		comp.data,
																		'right',
																		i
																	);
																	detectChanges(100)
																"
																(removed)="
																	service.onRemoveComponent(
																		comp.page!.order!,
																		'right'
																	)
																"
																(duplicate)="
																	service.onDuplicateComponent(
																		$event,
																		'right',
																		i
																	)
																"
																[item]="comp" />
														}
													</div>
												</div>
											}
											@if (service.view === 'mobile') {
												@if (data.page.type === 'side-by-side') {
													<div
														class="flex flex-col gap-2"
														cdkDropList
														[cdkDropListData]="dropComponents.mobile"
														(cdkDropListDropped)="
															service.dropMobile($event)
														">
														@for (
															comp of dropComponents.mobile;
															track service.trackByComponentId(
																i,
																comp
															);
															let i = $index
														) {
															<yuno-admin-pages-dynamic
																cdkDrag
																(clicked)="
																	service.onEditMobileComponent(
																		comp.key,
																		comp.data,
																		i
																	);
																	detectChanges(100)
																"
																(removed)="
																	service.onRemoveComponent(
																		i,
																		'left'
																	)
																"
																(duplicate)="
																	service.onDuplicateComponent(
																		$event,
																		'left',
																		i
																	)
																"
																[item]="comp" />
														}
													</div>
												}
												@if (data.page.type === 'default') {
													<div
														class="flex flex-col gap-2"
														cdkDropList
														[cdkDropListData]="
															dropComponents.mobileOrderDefault
														"
														(cdkDropListDropped)="
															service.drop(
																$event,
																dropComponents.right,
																dropComponents.left,
																'left'
															)
														">
														@for (
															comp of dropComponents.mobileOrderDefault;
															track service.trackByComponentId(
																i,
																comp
															);
															let i = $index
														) {
															<yuno-admin-pages-dynamic
																cdkDrag
																(clicked)="
																	service.onEditComponent(
																		comp.key,
																		comp.data,
																		'left',
																		i
																	);
																	detectChanges(100)
																"
																(removed)="
																	service.onRemoveComponent(
																		comp.page?.order || i,
																		'left'
																	)
																"
																(duplicate)="
																	service.onDuplicateComponent(
																		$event,
																		'left',
																		i
																	)
																"
																[item]="comp" />
														}
													</div>
												}
											}
										}
									</div>
								</div>
							}
						</form>
					}
				</div>
				@if (minimalAppRole(userRoles.EDITOR)) {
					<div edit-container-content>
						@if (
							service.editor.type === undefined &&
							!service.addItems &&
							!service.library
						) {
							<span>
								Select an item to start editing or add a new or existing item using
								the buttons in the upper left corner.
							</span>
						}
						@if (service.addItems) {
							<yuno-admin-textfield-pages-add
								[editor]="'page'"
								(selected)="service.onAddComponent($event.item.key)" />
						}
						@if (service.library) {
							<yuno-admin-browse-items
								[editor]="'page'"
								(selected)="
									service.onAddComponent($event.item.key, $event.component)
								">
							</yuno-admin-browse-items>
						}
						@if (service.editor.type !== undefined) {
							<yuno-admin-page-editor-dynamic [editor]="service.editor" />
						}
					</div>
				}
			</div>
		</yuno-edit-container>
	}
}
