<button
	class="yuno-button-card group"
	[ngClass]="{
		'w-full': buttonWide,
		'w-1/2': !buttonWide,
		'h-30': buttonSize === 'small',
		'h-40': buttonSize === 'large'
	}">
	<div
		class="relative w-full overflow-hidden"
		[ngClass]="{
			'h-20': buttonSize === 'small',
			'h-30': buttonSize === 'large'
		}">
		@if (buttonImage) {
			<img
				class="absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 transform-gpu object-cover filter transition-all duration-300 ease-in-out group-hover:scale-105 group-hover:brightness-90"
				[src]="buttonImage"
				[alt]="button?.title" />
		}

		@if (!buttonImage && (button?.file || button?.image) && generateUrl(); as url) {
			<img
				class="absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 transform-gpu object-cover filter transition-all duration-300 ease-in-out group-hover:scale-105 group-hover:brightness-90"
				[src]="url"
				[alt]="button?.title" />
		}
	</div>
	@if (button?.title) {
		<div
			class="h-10 w-full truncate bg-white px-4 text-left text-base font-semibold leading-10 text-yuno-slate-500 md:px-4"
			[innerHTML]="button?.title | languageSelect: language | safeHtml"></div>
	}
</button>
