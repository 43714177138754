import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	Output,
	forwardRef,
	inject
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor-v2';

import { YunoSharedUiButtonComponent } from '@yuno/shared/ui';

import { YunoFormsDefaultTemplateComponent } from '../_default/default.component';

@Component({
	selector: 'yuno-forms-code-editor',
	imports: [YunoSharedUiButtonComponent, MonacoEditorModule, FormsModule, ReactiveFormsModule],
	template: `
		<div class="grid h-full flex-1 grid-cols-1">
			<label class="yuno-form">
				@if (label) {
					<span class="select-none" [innerHtml]="label"> </span>
				}
			</label>

			<ngx-monaco-editor
				[style.height.px]="height"
				[options]="editorOptions"
				[ngModel]="codeValue"
				(ngModelChange)="codeValueChange.emit($event)"
				(onInit)="onMonacoInit($event)"></ngx-monaco-editor>

			<ng-content></ng-content>

			@if (!disableFormat) {
				<div class="flex w-full">
					<div class="ml-auto mr-0">
						<button yuno-shared-ui-button color="secondary" (click)="onFormat()">
							Format
						</button>
					</div>
				</div>
			}
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoFormsCodeEditorComponent {
	readonly cdr = inject(ChangeDetectorRef);

	private _language: 'json' | 'html' | 'xml' | 'plaintext' = 'json';
	private _readOnly = false;

	@Input() label?: string;
	@Input() height = 300;

	private _codeValue: string;
	@Input() set codeValue(val: string) {
		// if it is an empty array or object or something with a safe margin still replace it
		// if (this._codeValue && this._codeValue.length > 6) {
		// 	return;
		// }
		this._codeValue = val;
	}

	get codeValue(): string {
		return this._codeValue;
	}

	@Input() disableFormat = false;
	@Output() codeValueChange = new EventEmitter<string>();

	@Input()
	set language(lang: 'json' | 'html' | 'xml' | 'plaintext') {
		this._language = lang;
		if (this.editorOptions && this.editorOptions['language']) {
			this.editorOptions['language'] = this._language;
		}
	}

	get language() {
		return this._language;
	}

	@Input()
	set readOnly(bool: boolean) {
		this._readOnly = bool;
		if (this.editorOptions) {
			this.editorOptions['readOnly'] = this._readOnly;
		}
	}

	get readOnly() {
		return this._readOnly;
	}

	editorOptions: NgxMonacoEditorConfig['defaultOptions'] = {
		theme: 'vs-dark',
		height: '100%',
		language: this._language,
		scrollBeyondLastLine: false,
		lineNumbers: 'off',
		contextmenu: false,
		snippetSuggestions: 'none',
		codeLens: false,
		automaticLayout: true,
		minimap: {
			enabled: false
		},
		readOnly: this._readOnly
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	editor: any;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onMonacoInit(editor: any): void {
		console.log(editor);
		this.editor = editor;
		setTimeout(() => {
			this.onFormat();
		}, 250);
	}

	onFormat(): void {
		this.editor && this.editor.getAction('editor.action.formatDocument').run();
		this.cdr.markForCheck();
	}
}

@Component({
	selector: 'yuno-forms-code-template-editor',
	imports: [YunoSharedUiButtonComponent, MonacoEditorModule, FormsModule],
	template: `
		<div class="grid h-full flex-1 grid-cols-1">
			<label class="yuno-form">
				@if (label) {
					<span class="select-none" [innerHtml]="label"> </span>
				}
			</label>

			<ngx-monaco-editor
				[style.height.px]="height"
				[options]="editorOptions"
				[ngModel]="value"
				(ngModelChange)="valueChange($event)"
				(onInit)="onMonacoInit($event)"></ngx-monaco-editor>

			<ng-content></ng-content>

			@if (!disableFormat) {
				<div class="flex w-full">
					<div class="ml-auto mr-0">
						<button yuno-shared-ui-button color="secondary" (click)="onFormat()">
							Format
						</button>
					</div>
				</div>
			}
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => YunoFormsCodeEditorTemplateComponent),
			multi: true
		}
	]
})
export class YunoFormsCodeEditorTemplateComponent extends YunoFormsDefaultTemplateComponent {
	readonly cdr = inject(ChangeDetectorRef);

	private _language: 'json' | 'html' | 'xml' | 'plaintext' = 'json';
	private _readOnly = false;

	@Input() height = 300;
	@Input() disableFormat = false;

	@Input()
	set language(lang: 'json' | 'html' | 'xml' | 'plaintext') {
		this._language = lang;
		if (this.editorOptions && this.editorOptions['language']) {
			this.editorOptions['language'] = this._language;
		}
	}

	get language() {
		return this._language;
	}

	@Input()
	set readOnly(bool: boolean) {
		this._readOnly = bool;
		if (this.editorOptions) {
			this.editorOptions['readOnly'] = this._readOnly;
		}
	}

	get readOnly() {
		return this._readOnly;
	}

	editorOptions: NgxMonacoEditorConfig['defaultOptions'] = {
		theme: 'vs-dark',
		height: '100%',
		language: this._language,
		scrollBeyondLastLine: false,
		lineNumbers: 'off',
		contextmenu: false,
		snippetSuggestions: 'none',
		codeLens: false,
		automaticLayout: true,
		minimap: {
			enabled: false
		},
		readOnly: this._readOnly
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	editor: any;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onMonacoInit(editor: any): void {
		this.editor = editor;
		setTimeout(() => {
			this.onFormat();
		}, 250);
	}

	onFormat(): void {
		this.editor && this.editor.getAction('editor.action.formatDocument').run();
		this.cdr.markForCheck();
	}
}
