import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Dataset } from '@yuno/api/interface';

export const datasetsActions = createActionGroup({
	source: 'Datasets',
	events: {
		load: emptyProps(),
		loadSuccess: props<{ data: Partial<Dataset>[] }>(),
		loadFailure: props<{ error: Error }>(),
		reload: emptyProps(),

		select: props<{ _id: string }>(),
		clearSelect: emptyProps(),
		selectSuccess: props<{ data: Partial<Dataset> }>(),
		selectFailure: props<{ error: Error }>(),

		save: emptyProps(),
		saveSuccess: emptyProps(),
		saveFailure: props<{ error: Error }>(),

		duplicate: props<{ _id: string }>(),
		duplicateSuccess: props<{ data: Partial<Dataset> }>(),
		duplicateFailure: props<{ error: Error }>(),

		delete: props<{ _id: string }>(),
		deleteSuccess: props<{ _id: string }>(),
		deleteFailure: props<{ error: Error }>(),

		updateSelect: props<{ data: Partial<Dataset> }>(),
		updateSelectSuccess: props<{ data: Partial<Dataset> }>(),
		updateSelectFailure: props<{ error: Error }>(),

		saveFromTemplate: props<{ id: string }>(),
		saveFromTemplateSuccess: props<{ data: Partial<Dataset[]> }>(),

		getParentRoutes: props<{ ids: string[] }>(),
		getParentRouteSuccess: props<{ data: string[] }>()
	}
});
