import { gql } from 'apollo-angular';

import { Dataset } from '@yuno/api/interface';

export const SAVE_DATASET = gql`
	mutation saveDataset($dataset: DatasetInput!, $appId: String!) {
		saveDataset(dataset: $dataset, appId: $appId) {
			_id
			id
			data {
				legend {
					_id
					id
				}
				fences {
					fence {
						_id
						id
					}
					events {
						type
						options
					}
				}
				layers {
					layer {
						_id
						id
					}
					events {
						onClick {
							type
							options
						}
						onMouseMove {
							type
							options
						}
						onMouseLeave {
							type
							options
						}
					}
				}
				markers {
					marker {
						_id
						properties {
							id
						}
					}
				}
				markerCategories {
					category {
						_id
						id
					}
				}
				panoramas {
					closeTextfield
					panorama {
						_id
						id
					}
					states {
						state {
							_id
							state
						}
					}
					visibility
				}
				objects {
					data
					type
				}
				participates {
					participate
				}
				photoCategories {
					category {
						_id
						id
					}
				}
				shapes {
					canMove
					canScale
					canRotate
					fence {
						_id
						id
					}
				}
			}
			states {
				state {
					_id
					state
				}
				fences {
					fence
					active
				}
				layers {
					layer
					visibility
				}
				markers {
					marker
					visibility
				}
				markerCategories {
					markerCategory {
						_id
						id
					}
					visibility
				}
				panoramas {
					panorama {
						_id
						id
					}
					visibility
				}
				photoCategories {
					category {
						_id
						id
					}
					visibility
				}
			}
		}
	}
`;

export const DUPLICATE_DATASET = gql`
	mutation duplicateDatasets($_id: String!, $appId: String!) {
		duplicateDataset(_id: $_id, appId: $appId) {
			_id
			id
			data {
				fences {
					fence {
						_id
					}
				}
				layers {
					layer {
						_id
					}
				}
				markers {
					marker {
						_id
					}
				}
				markerCategories {
					category {
						_id
					}
				}
				panoramas {
					panorama {
						_id
					}
				}
				objects {
					data
				}
				participates {
					participate
				}
				photoCategories {
					category {
						_id
					}
				}
				shapes {
					fence {
						_id
					}
				}
			}
			states {
				state {
					_id
				}
			}
		}
	}
`;

export const SAVE_DATASET_FROM_TEMPLATE = gql`
	mutation saveDatasetFromTemplate($_id: String!, $appId: String!) {
		saveDatasetFromTemplate(_id: $_id, appId: $appId) {
			_id
		}
	}
`;

export type SaveDatasetMutation = {
	saveDataset: Partial<Dataset>;
};

export type DuplicateDatasetMutation = {
	duplicateDataset: Partial<Dataset>;
};

export type SaveDatasetFromTemplateMutation = {
	saveDatasetFromTemplate: Partial<Dataset[]>;
};
