import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

import { ENVIRONMENT } from '@yuno/admin/core';
import { TableRow, YunoAdminButtonsModule, YunoAdminTableComponent } from '@yuno/admin/ui';
import { AuthFacade } from '@yuno/angular-auth';
import { App, YunoUserRoles, YunoUserRolesEnum } from '@yuno/api/interface';

import { AppFacade, AppsDataAccessModule } from '../../data-access';
import { AppsData } from '../../utils/interface';

@Component({
	selector: 'yuno-client-view',
	imports: [
		RouterModule,
		YunoAdminTableComponent,
		AppsDataAccessModule,
		YunoAdminButtonsModule,
		AsyncPipe
	],
	templateUrl: './client-view.component.html',
	styleUrls: ['./client-view.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientViewComponent implements OnInit {
	readonly route = inject(ActivatedRoute);
	readonly router = inject(Router);
	private appFacade = inject(AppFacade);
	private authFacade = inject(AuthFacade);
	private readonly environment = inject(ENVIRONMENT);

	data: AppsData;

	apps$ = this.appFacade.app$;
	user$ = this.authFacade.user$;

	ngOnInit(): void {
		this.convertAppData(this.route.snapshot.data as AppsData);
	}

	convertAppData(data: AppsData) {
		this.data = {
			apps: data.apps.map(data => {
				return {
					...data,
					clientName: data.client?.id
				};
			})
		};
	}

	onSelectApp(e?: TableRow) {
		const app = e as App;
		if (!app) {
			return;
		}
		if (!this.minimalReaderRole(app.currentUserRole)) {
			return;
		}

		this.appFacade.validateApp(app._id as string);
		this.router.navigate(['../', app._id as string, 'dashboard'], { relativeTo: this.route });
	}

	openApp(app: Partial<App>, intern?: boolean) {
		if (intern && !this.atlasInternalRole(app?.currentUserRole)) {
			return;
		}

		const url = intern ? this.getProjectUrlInternal(app) : this.getProjectUrl(app);
		window.open(url, '_blank');
	}

	getProjectUrl(project: Partial<App>): string {
		if (!project.client?.url?.[0] || !project?.url?.[0]) {
			throw new Error('No client or url found');
		}

		const client = project.client.url[0];
		const url = project.url[0];
		return `https://${client}.${this.environment['yuno-main']}/${url}`;
	}

	getProjectUrlInternal(project: Partial<App>): string {
		if (!project.client?.url?.[0] || !project?.url?.[0]) {
			throw new Error('No client or url found');
		}

		const client = project.client.url[0];
		const url = project.url[0];
		return `https://${client}.${this.environment['yuno-main']}/${url}/intern`;
	}

	minimalReaderRole(role?: YunoUserRoles): boolean {
		if (!role) {
			return false;
		}

		return this.authFacade.userHasMinimalAppRole(YunoUserRolesEnum.READER, role);
	}

	atlasInternalRole(role?: YunoUserRoles): boolean {
		if (!role) {
			return false;
		}

		return this.authFacade.userHasMinimalAppRole(YunoUserRolesEnum.ATLASINTERNAL, role);
	}
}
