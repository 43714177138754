import {
	AbstractControl,
	FormGroup,
	UntypedFormGroup,
	ValidationErrors,
	ValidatorFn
} from '@angular/forms';

export class CustomValidators {
	static mustMatch(controlName: string, matchingControlName: string): ValidatorFn {
		return (group: AbstractControl): ValidationErrors | null => {
			const control = (group as UntypedFormGroup).controls[controlName];
			const matchingControl = (group as UntypedFormGroup).controls[matchingControlName];

			if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
				return null;
			}

			// set error on matchingControl if validation fails
			if (control.value !== matchingControl.value) {
				matchingControl.setErrors({ mustMatch: true });
			} else {
				matchingControl.setErrors(null);
			}

			return null;
		};
	}

	static noSpacesLowerCase(): ValidatorFn {
		// RegEx code
		// spaces /\s/g
		// upppercase /[A-Z]/
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control.value) {
				return null;
			}

			let whitespace = false;
			let uppercase = false;

			if (control.value.match(/\s/g)) {
				control.setErrors({ whitespace: true });
				whitespace = true;
			}

			if (/[A-Z]/.test(control.value)) {
				control.setErrors({ uppercase: true });
				uppercase = true;
			}

			if (whitespace || uppercase) {
				return { noSpacesLowerCase: { whitespace, uppercase } };
			}

			control.setErrors(null);
			return null;
		};
	}

	static webSlugValidator(
		options: { uppercase?: boolean; template?: boolean } = {}
	): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const value = control.value;

			// Base character set
			const letterPattern = options.uppercase ? 'a-zA-Z' : 'a-z';

			// Optional [TEMPLATE] prefix
			const templatePrefix = options.template ? '(?:\\[TEMPLATE\\] )?' : '';

			// Build the full regex
			const validSlugPattern = new RegExp(
				`^${templatePrefix}[${letterPattern}0-9]+(?:[-_][${letterPattern}0-9]+)*$`
			);

			const isValid = validSlugPattern.test(value);
			if (isValid) {
				return null;
			}

			// Choose appropriate error message
			const errorMessage = options.uppercase
				? "Use only lower and uppercase letters. Do not use spaces, diacritics (such as 'ä', 'ë', 'ß'), or special characters, except for '-' and '_'."
				: "Use only lowercase letters. Do not use spaces, diacritics (such as 'ä', 'ë', 'ß'), or special characters, except for '-' and '_'.";

			return { invalidSlug: errorMessage };
		};
	}
}

export const isFormValid = (form: FormGroup): boolean => {
	form.markAllAsTouched();
	return form.valid;
};
