import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	inject
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { combineLatest, map } from 'rxjs';

import {
	AnnotationComponent,
	TableColumnDisplay,
	YunoAdminSaveButtonComponent
} from '@yuno/admin/ui';
import { YunoFormsSpanComponent, YunoFormsTextComponent } from '@yuno/angular/forms/components';
import { Textfield, TextfieldComponents } from '@yuno/api/interface';

import { TemplatesFacade } from '../data-access';
import { TemplateItemComponent } from './template-item.component';
import { TemplateTableComponent } from './template-table.component';

@Component({
	imports: [
		CommonModule,
		TemplateItemComponent,
		TemplateTableComponent,
		ReactiveFormsModule,
		YunoFormsTextComponent,
		AnnotationComponent,
		YunoFormsSpanComponent,
		YunoAdminSaveButtonComponent
	],
	selector: 'yuno-admin-templates',
	templateUrl: './choose-template.component.html',
	styleUrls: ['./choose-template.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChooseTemplateComponent {
	private _backdrop = true;
	private _selector = 'textfield';
	private _presets: Partial<Textfield>[] | undefined = [];

	private readonly templateFacade = inject(TemplatesFacade);

	confirmLayers = false;
	selectedLayerIds: string[] = [];
	layerColumns: TableColumnDisplay[] = [{ key: 'id', label: 'Layer' }];

	@Input()
	set backdrop(bool: boolean) {
		this._backdrop = bool;
	}

	get backdrop(): boolean {
		return this._backdrop;
	}

	@Input()
	set selector(str: string) {
		this._selector = str;
		this.templateFacade.get(str);
	}

	get selector(): string {
		return this._selector;
	}

	@Input()
	set presets(arr: Partial<Textfield>[] | undefined) {
		this._presets = arr;
	}

	get presets(): Partial<Textfield>[] | undefined {
		return this._presets;
	}

	@Output() textfieldTemplates = new EventEmitter<TextfieldComponents[]>();
	@Output() layerTemplates = new EventEmitter<{ layerIds: string[]; layerName: string }>();
	@Output() categoryTemplate = new EventEmitter<string | undefined>();
	@Output() datasetTemplate = new EventEmitter<string | undefined>();
	@Output() presetTemplate = new EventEmitter<string>();

	@Output() bgClicked = new EventEmitter<void>();

	data$ = combineLatest({
		templates: this.templateFacade.templates$.pipe(
			map(data => {
				return data.map(template => {
					const newTemp = Object.assign({}, template);
					if (this.selector === 'layer') {
						const layerOptions = template.options as {
							layers: string[];
							layerType: string;
						};
						if (layerOptions.layerType) {
							newTemp.id = `${layerOptions.layerType} - ${template.id}`;
						}
					}
					return newTemp;
				});
			})
		)
	});

	form: FormGroup = new FormGroup({
		name: new FormControl<string>('', {
			nonNullable: true,
			validators: [Validators.required, Validators.minLength(2)]
		})
	});
	layerName: string;

	applyFilter(str: string): void {
		if (str && str.length >= 1) {
			this.templateFacade.filter(this.selector, str.toLowerCase());
		} else {
			this.templateFacade.get(this.selector);
		}
	}

	onSelect(c: unknown, id?: string) {
		if (this._selector === 'textfield') {
			const components = c as TextfieldComponents[];
			this.textfieldTemplates.emit(components);
		}
		if (this._selector === 'layer') {
			const layerIds =
				(
					c as {
						layers: string[];
						layerType: string;
					}
				).layers || [];
			if (layerIds.length > 1) {
				this.confirmLayers = true;
				this.selectedLayerIds = layerIds;
			} else {
				this.layerName = '';
				this.layerTemplates.emit({ layerIds, layerName: this.layerName });
			}
		}
		if (this._selector === 'participate' || this._selector === 'place-marker') {
			const category = c as { category: string; appId?: string };
			// this.categoryTemplate.emit({ category: category.category, appId: category?.appId });
			if (category.category) {
				this.categoryTemplate.emit(id);
			} else {
				this.categoryTemplate.emit(undefined);
			}
		}
		if (this._selector === 'dataset') {
			const datasetIds = (c as { datasets: string[] }).datasets || [];
			if (datasetIds.length >= 1) {
				this.datasetTemplate.emit(id);
			} else {
				this.datasetTemplate.emit(undefined);
			}
		}
	}

	onSelectLayers() {
		this.form.markAllAsTouched();
		if (this.form.valid) {
			this.layerTemplates.emit({
				layerIds: this.selectedLayerIds,
				layerName: this.layerName
			});
		}
	}

	onSelectPreset(id: string | undefined) {
		if (!id) {
			return;
		}
		this.presetTemplate.emit(id);
	}

	onBackgroundClick() {
		this.bgClicked.emit();
	}
}
