import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { appActions } from '@yuno/admin/features/apps';
import { Status } from '@yuno/admin/utils';
import { Participated, ParticipatedListView } from '@yuno/api/interface';

import { participationsActions } from './participations.actions';

export interface ParticipationsState {
	data: Partial<Participated>[];
	loaded: Status;
	selected: Participated | undefined;
}

export const initialStateParticipations: ParticipationsState = {
	data: [],
	loaded: Status.PENDING,
	selected: undefined
};

const reducer = createReducer(
	initialStateParticipations,
	on(appActions.reset, () => initialStateParticipations),
	on(participationsActions.reset, () => ({
		...initialStateParticipations
	})),

	on(participationsActions.load, state => ({
		...state,
		status: Status.VALIDATING
	})),

	on(participationsActions.loadSuccess, (state, action) => ({
		...state,
		data: action.data,
		loaded: Status.SUCCESS
	})),

	on(participationsActions.loadFailure, state => ({
		...state,
		data: [],
		loaded: Status.FAILED
	})),

	on(participationsActions.selectSuccess, (state, action) => ({
		...state,
		selected: action.data
	})),
	on(participationsActions.setOnHoldSuccess, (state, action) => ({
		...state,
		selected: action.data
	})),
	on(participationsActions.sendAnswerOnlySuccess, (state, action) => ({
		...state,
		selected: action.data
	})),
	on(participationsActions.setPublishedSuccess, (state, action) => ({
		...state,
		selected: action.data
	})),
	on(participationsActions.setArchivedSuccess, (state, action) => ({
		...state,
		selected: action.data
	})),
	on(participationsActions.onUpdateAnswerSuccess, (state, action) => ({
		...state,
		selected: action.data
	})),
	on(participationsActions.onUpdateReactionSuccess, (state, action) => ({
		...state,
		selected: action.data
	})),

	on(participationsActions.clearSelect, state => ({
		...state,
		selected: undefined
	})),

	on(participationsActions.updateSelectSuccess, (state, { data }) => ({
		...state,
		selected: {
			...state.selected,
			...data
		} as Participated
	}))
);

export const participationsFeature = createFeature({
	name: 'participation-participations',
	reducer,
	extraSelectors: ({ selectData }) => ({
		getCount: (status: Participated['state'] | 'all') =>
			createSelector(selectData, (data): number => {
				if (status === 'all') {
					const archived = data.filter(data => data?.state === 'archived').length || 0;
					const unverified =
						data.filter(data => data?.state === 'unverified').length || 0;

					return data.length - archived - unverified;
				}

				return data.filter(data => data?.state === status).length;
			}),
		getFiltered: (status: Participated['state'] | 'all') =>
			createSelector(selectData, data => {
				return (
					data
						// Convert the modified date if necessary and assign a proper date.
						.map(item => {
							let modified = item.status?.new?.modified;
							if (!(modified instanceof Date) && modified) {
								modified = new Date(modified);
							}
							return {
								...item,
								date: modified || new Date()
							};
						})
						// Sort in descending order by date (most recent first).
						.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
						// Assign the preserved original index.
						.map((item, index) => ({
							...item,
							index
						}))
						// Filter based on the status.
						.filter(item => {
							if (status === 'all') {
								return item.state !== 'archived' && item.state !== 'unverified';
							}
							return item.state === status;
						})
						.reverse() as ParticipatedListView[]
				);
			})
	})
});
