@if (data$ | async; as data) {
	<yuno-edit-container [popup]="isPopup" [col]="4" [pos]="1" [span]="4">
		<ng-container buttons>
			<button yuno-admin-save-button (click)="onSave()" [disabled]="!$isChanged()"></button>
			<button yuno-admin-close-button (click)="onClose()"></button>
		</ng-container>

		<div class="flex flex-col gap-2 md:grid md:h-full md:grid-cols-2 xl:grid-cols-3">
			<div edit-container-content>
				@if (service.form) {
					<form [formGroup]="service.form" class="flex flex-col gap-16">
						@if (!minimalAppRole(userRoles.EDITOR)) {
							<yuno-user-rights-message></yuno-user-rights-message>
						}
						<div class="flex flex-col gap-8">
							<section class="flex gap-4">
								<yuno-forms-text
									class="flex-1"
									formControlName="id"
									label="Layer name"
									placeholder="layer name">
									Use only lower and uppercase letters. Do not use spaces,
									diacritics (such as 'ä', 'ë', 'ß'), or special characters,
									except for '-' and '_'.
								</yuno-forms-text>
								<yuno-forms-toggle formControlName="public" label="Public" />
							</section>
						</div>

						<div class="flex flex-col gap-8">
							<yuno-forms-select
								class="flex-1"
								formControlName="type"
								[selectValues]="service.selectTypes"
								label="Layer Type" />
							<section class="grid grid-cols-5 gap-4">
								<yuno-forms-number
									class="col-span-1"
									formControlName="order"
									placeholder="display name"
									label="Order" />
								<yuno-forms-text
									class="col-span-2"
									formControlName="before"
									label="Before"
									placeholder="before layer" />
								<yuno-forms-text
									class="col-span-2"
									formControlName="after"
									label="After"
									placeholder="after layer" />
							</section>
						</div>

						<div class="flex flex-col gap-4">
							<yuno-forms-title>Style</yuno-forms-title>
							<yuno-forms-select
								class="flex-1"
								formControlName="source"
								[selectValues]="service.sources"
								label="Source" />
							@if (service.sourceLayers && service.sourceLayers.length >= 1) {
								<yuno-forms-select
									class="flex-1"
									formControlName="sourceLayer"
									[selectValues]="service.sourceLayers"
									label="Source Layers" />
							}
							<section class="grid grid-cols-2 gap-4">
								<yuno-forms-number
									formControlName="minzoom"
									placeholder="1"
									label="Min Zoom" />

								<yuno-forms-number
									formControlName="maxzoom"
									placeholder="22"
									label="Max Zoom" />
							</section>
						</div>
					</form>
				}
				<div class="mt-16 flex flex-col gap-4">
					<section>
						<p class="text-sm">
							For more information about Layer styling, take a look at the
							<a
								class="text-yuno-blue"
								href="https://maplibre.org/maplibre-style-spec/layers/"
								target="_blank"
								rel="noopener">
								MapLibre Documentation
							</a>
						</p>
						<h4>Filter Properties</h4>
						<yuno-code-editor
							[readOnly]="readonly"
							[control]="filterControl"
							(changed)="updateControlForm($event, 'filter')" />
					</section>
					<section>
						<h4>Layout Properties</h4>
						<yuno-code-editor
							[readOnly]="readonly"
							[control]="layoutControl"
							(changed)="updateControlForm($event, 'layout')" />
					</section>
					<section>
						<h4>Paint Properties</h4>
						<yuno-code-editor
							[readOnly]="readonly"
							[control]="paintControl"
							(changed)="updateControlForm($event, 'paint')" />
					</section>
				</div>
			</div>
			<div edit-container-content class="map-container xl:col-span-2">
				<yuno-admin-layer-map-viewer />
			</div>
		</div>
	</yuno-edit-container>
}
