import { ActivatedRoute, Router } from "@angular/router";


/**
 * When only providing the Route and Router
 * it will redirect to the Parent Route
 *
 * if proving an id and targerRoute it will redirect to the targetRoute with the id
 * when only providing a id it will redirect to the current route with the id appended
 *
 * main route:
 * internal/5fd8dc79921cab0cbb25ee2c/fences
 *
 * examples:
 * redirectTo(this.route, this.router, ['edit', `${data._id}`]);
 * will redirect to:
 * internal/5fd8dc79921cab0cbb25ee2c/fences/edit/data._id
 *
 * redirectTo(this.route, this.router, ['edit', `${data._id}`], ['internal', 'markers']);
 * will redirect to:
 * internal/markers/edit/data._id
 *
 *
 * @param route
 * @param router
 * @param id
 * @param targetRoute
 */
export const redirectTo = (
	route: ActivatedRoute,
	router: Router,
	id?: string[],
	targetRoute?: string[]
): void => {
	const routerLink = route?.parent?.snapshot.pathFromRoot
		.map(s => s.url)
		.reduce((a, e) => {
			if (a.length + e.length !== route?.parent?.snapshot.pathFromRoot.length) {
				return a.concat(e);
			}
			return a;
		})
		.map(s => s.path);

	let newRoute: string[] = [];
	if (targetRoute && targetRoute.length >= 1) {
		newRoute = [...(targetRoute as string[])];
	}

	if (!targetRoute || targetRoute.length < 1) {
		newRoute = [...(routerLink as string[])];
	}

	if (id && id.length >= 1) {
		newRoute = [...newRoute, ...(id as string[])];
	}

	router.navigate(newRoute);
};
