import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	inject
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { startWith, tap } from 'rxjs';

import { AppFacade } from '@yuno/admin/features/apps';
import { HtmlEditorImageLibraryComponent } from '@yuno/admin/features/html-form';
import { SpritesheetModule } from '@yuno/admin/features/spritesheets/feature/module/spritesheet.module';
import { AnnotationComponent } from '@yuno/admin/ui';
import {
	YunoFormsSelectComponent,
	YunoFormsSpanComponent,
	YunoFormsTextComponent,
	YunoFormsTitleComponent,
	YunoFormsToggleComponent
} from '@yuno/angular/forms/components';
import { AngularSvgLoaderModule } from '@yuno/angular/svg-loader';
import { LanguageAll } from '@yuno/api/interface';

import { ParticipationNotificationFacade } from '../../../../../data-access';
import { ParticipationModelEditorsDefaultComponent } from '../default.component';

@Component({
	selector: 'yuno-admin-participation-model-general',
	imports: [
		YunoFormsTitleComponent,
		YunoFormsTextComponent,
		YunoFormsSpanComponent,
		YunoFormsSelectComponent,
		YunoFormsToggleComponent,
		SpritesheetModule,
		ReactiveFormsModule,
		AngularSvgLoaderModule,
		HtmlEditorImageLibraryComponent,
		AsyncPipe,
		AnnotationComponent
	],
	templateUrl: './general.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParticipationModelEditorGeneralComponent
	extends ParticipationModelEditorsDefaultComponent
	implements OnInit, OnDestroy
{
	private cdr = inject(ChangeDetectorRef);
	private notificationFacade = inject(ParticipationNotificationFacade);
	private appFacade = inject(AppFacade);
	protected readonly languages = LanguageAll;

	data$ = this.notificationFacade.data$.pipe(
		tap(() => {
			this.cdr.detectChanges();
		})
	);
	language$ = this.appFacade.language$.pipe(startWith('nl'));

	ngOnInit() {
		this.languageSelector();
	}

	ngOnDestroy() {
		this.destroyLanguageSelector();
	}
}
