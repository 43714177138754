import { DEFAULT_DIALOG_CONFIG } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	OnDestroy,
	OnInit,
	inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { debounceTime, startWith, tap } from 'rxjs';

import { AppFacade } from '@yuno/admin/features/apps';
import { TextfieldFacade } from '@yuno/admin/features/textfield-pages';
import {
	UserRightsMessageComponent,
	YunoAdminCardComponent,
	YunoAdminNavbarButtonsComponent,
	YunoAdminSaveButtonComponent
} from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';
import { App, Config } from '@yuno/api/interface';

import {
	AppAtlasHelpSettingsComponent,
	AppAtlasLanguageSettingsComponent,
	AppAtlasNavbarColorSettingsComponent,
	AppAtlasTextHeadingSettingsComponent,
	AppAtlasUiSettingsComponent,
	AppConfigurationAtlasSettingsComponent
} from '../settings';
import { InternalViewService } from './internal-view.service';

@Component({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		YunoAdminCardComponent,
		UserRightsMessageComponent,
		YunoAdminNavbarButtonsComponent,
		YunoAdminSaveButtonComponent,
		AppConfigurationAtlasSettingsComponent,
		AppAtlasHelpSettingsComponent,
		AppAtlasLanguageSettingsComponent,
		AppAtlasUiSettingsComponent,
		AppAtlasNavbarColorSettingsComponent,
		AppAtlasTextHeadingSettingsComponent
	],
	providers: [
		InternalViewService,
		{ provide: DEFAULT_DIALOG_CONFIG, useValue: { hasBackdrop: true } }
	],
	selector: 'yuno-admin-app-configuration',
	templateUrl: './internal-view.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppConfigurationComponent extends AppDataComponent implements OnInit, OnDestroy {
	readonly service = inject(InternalViewService);
	private readonly appFacade = inject(AppFacade);
	private readonly textfieldFacade = inject(TextfieldFacade);
	private readonly destroyRef = inject(DestroyRef);

	textfields$ = this.textfieldFacade.allTextfields$.pipe(
		startWith([]),
		tap(data => {
			if (!data) {
				return;
			}
			this.service.textfieldsSelect = data.map(object => object._id) as string[];
			this.service.textfieldsDisplay = data.map(object => object.id) as string[];
		})
	);

	ngOnInit(): void {
		this.textfieldFacade.get();
		this.onChanges();

		const app = this.appFacade.$app();
		const config = this.appFacade.$config();

		if (!app || !config) {
			return;
		}

		this.service.appForm.patchValue(app);
		// @ts-ignore partial language error
		this.service.configForm.patchValue(config);
		const languages = config.languages;
		if (languages && languages.length > 0) {
			for (const lang of languages) {
				this.service.addLanguage(lang);
			}
		}
		if (!languages || languages.length <= 0) {
			this.service.addLanguage(config.language);
		}

		this.parseUrls();
		this.appFacade.getAppUsers();

		!this.isMinimalEditor && this.disableForms();
	}

	onChanges(): void {
		this.service.appForm.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(300))
			.subscribe(() => this.appFacade.updateApp(this.service.appForm.getRawValue() as App));

		this.service.configForm.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(300))
			.subscribe(config => {
				this.appFacade.updateSettings(this.service.configForm.getRawValue() as Config);
				if (config?.interface?.buttonStyle) {
					const btn = config.interface.buttonStyle;
					const classes = ['rounded', 'squared'];

					// Remove all classes
					document.body.classList.remove(...classes);

					// Add the appropriate class based on the button clicked
					if (btn === 'square') {
						document.body.classList.add('squared');
					} else if (btn === 'rounded') {
						document.body.classList.add('rounded');
					}
				}
			});

		this.service.languages.valueChanges
			.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(300))
			.subscribe(languages => {
				if (languages.length > 0 && languages[0]) {
					const language = this.service.configForm.get('language');
					if (language) {
						language.setValue(languages[0]);
					}
				}
			});
	}

	disableForms(): void {
		this.service.appForm.disable();
		this.service.configForm.disable();
	}

	parseUrls(): void {
		const urls: string[] = this.route.snapshot.data['app'].url;
		for (const url of urls) {
			this.service.addUrl(url);
		}
	}

	onSave() {
		this.appFacade.saveSettings();
	}

	ngOnDestroy() {
		const classes = ['rounded', 'squared'];
		document.body.classList.remove(...classes);
	}
}
