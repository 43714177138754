@if (selectedStyleEditor) {
	<yuno-admin-participation-style-editor
		class="absolute z-[250]"
		[form]="service.form"
		(closePage)="closeStyleEditor()"></yuno-admin-participation-style-editor>
}

@if (stylingRules) {
	<yuno-admin-category-styling-rules
		class="absolute z-[250]"
		(closePage)="openRulesEditor(false)">
	</yuno-admin-category-styling-rules>
}

@if (data$ | async; as data) {
	<yuno-edit-container
		[popup]="isPopup"
		[col]="6"
		[pos]="2"
		[span]="4"
		[tabs]="service.tabs"
		(outputTab)="onSwitchTab($event.key)">
		<ng-container buttons>
			<button yuno-admin-save-button (click)="onSave()" [disabled]="!$isChanged()"></button>
			<button yuno-admin-close-button (click)="onClose()"></button>
		</ng-container>

		<div edit-container-content [hasTabs]="true">
			@if (service.tabValue === 'Settings') {
				<yuno-admin-participation-model-general [form]="service.form" />
			}
			@if (service.tabValue === 'Reaction') {
				<yuno-admin-participation-model-reaction [form]="service.form" />
			}
			@if (service.tabValue === 'Users') {
				<yuno-admin-participation-model-users [users]="data.users" [form]="service.form" />
			}
			@if (service.tabValue === 'Style') {
				<div class="flex flex-col gap-16">
					<yuno-admin-participation-style-list
						[form]="service.form"
						(openStyleEditor)="openStyleEditor($event)"
						(openRulesEditor)="openRulesEditor($event)" />
					<yuno-admin-participation-model-style [form]="service.form" />
				</div>
			}
			@if (service.tabValue === 'Steps') {
				<yuno-admin-participation-model-steps [form]="service.form" />
			}
			@if (service.tabValue === 'Viewer') {
				<yuno-admin-participation-model-viewer [form]="service.form" />
			}
			@if (service.tabValue === 'CRM') {
				<yuno-admin-participation-crm [form]="service.form" />
			}
		</div>
	</yuno-edit-container>
}
